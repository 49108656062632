import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from '../ConsumerApi.dto';
import { googleMapsNavigationUrl } from '../helpers/PlaceHelper';

import { ArrowBackIcon } from '../icons';
import { LocationCurrentIcon } from '../icons/location/LocationCurrentIcon';
import findOnMapSelectedImage from '../images/find-on-map-selected.svg';
import currentLocationImage from '../images/current-location.svg';
import { mapTilerBaseUrl } from '../Constants';
import { useRecoilValue } from 'recoil';
import { lastLocationState } from '../atoms';
import maplibregl, { Marker, Map } from 'maplibre-gl';
import { LatLng } from '../model';

type PlaceMapProps = {
  place: ConsumerApi.PlaceBasicDto
  onBack: () => void
}

export const PlaceMap: React.FC<PlaceMapProps> = (props) => {

  const { t } = useTranslation();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const markerCurrentLocationRef = useRef<Marker>();
  const mapRef = useRef<Map>();
  const [currentLocation, setCurrentLocation] = useState<LatLng>();
  const lastLocation = useRecoilValue(lastLocationState);
  const mapGeolocationWatcher = useRef<number>();

  const goToCurrentLocation = useCallback(() => {
    if (mapRef.current && currentLocation) {
      mapRef.current.panTo(currentLocation.toMapLibre());
    }
  }, [currentLocation]);

  const removeGeolocationWatcher = useCallback(() => {
    if (mapGeolocationWatcher.current !== undefined && markerCurrentLocationRef.current) {
      navigator.geolocation.clearWatch(mapGeolocationWatcher.current);
      markerCurrentLocationRef.current.remove();
      markerCurrentLocationRef.current = undefined;
      mapGeolocationWatcher.current = undefined;
    }
    setCurrentLocation(undefined);
  }, []);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current && props.place.longitude && props.place.latitude) {
      mapRef.current = new maplibregl.Map({
        container: mapContainerRef.current, // container id
        style: `${mapTilerBaseUrl}?key=${process.env.REACT_APP_MAPTILER_KEY || "PQqhWYuzCE3UImKAKhVn"}`, // style URL
        center: [props.place.longitude, props.place.latitude], // starting position
        zoom: 16, // starting zoom
        attributionControl: false
      });

      mapRef.current.addControl(new maplibregl.AttributionControl({
        compact: false
      }));

      const el = document.createElement('img');
      (el as HTMLImageElement).src = findOnMapSelectedImage;
      new maplibregl.Marker(el)
        .setLngLat([props.place.longitude, props.place.latitude])
        .addTo(mapRef.current as Map);

      if (lastLocation && lastLocation.locationType === ConsumerApi.RecentLocationType.Current) {
        setCurrentLocation(lastLocation.location);

        if (mapGeolocationWatcher.current === undefined) {
          mapGeolocationWatcher.current = navigator.geolocation
            .watchPosition((position: GeolocationPosition) => {
              const coords = position.coords;
              if (markerCurrentLocationRef.current) {
                markerCurrentLocationRef.current.setLngLat(
                  [coords.longitude, coords.latitude]);
              } else {
                const el = document.createElement('img');
                (el as HTMLImageElement).src = currentLocationImage;
                const marker = new maplibregl.Marker(el)
                  .setLngLat([coords.longitude, coords.latitude])
                  .addTo(mapRef.current as Map);
                markerCurrentLocationRef.current = marker;
              }
            }, undefined, {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            });
        }
      } else {
        removeGeolocationWatcher();
      }
    }

    return () => {
      removeGeolocationWatcher();
    }
  }, [lastLocation, props.place, removeGeolocationWatcher]);

  return (
    <div className="fixed inset-0 z-60 bg-white overflow-y-auto mx-auto max-w-md">
      <div ref={mapContainerRef} className="h-full" />

      <div className="fixed top-0 left-0 right-0 z-30 bg-white">
        <div className="w-full mx-auto max-w-md border-b border-gray-lighther">
          <div className="flex items-center h-[56px] w-full bg-white">
            <div onClick={props.onBack} className="flex-none cursor-pointer">
              <ArrowBackIcon />
            </div>
            <div className="flex-auto text-center style-heading truncate">
              {props.place.name}
            </div>
          </div>
        </div>
      </div>

      {currentLocation ?
        <div
          onClick={goToCurrentLocation}
          className="absolute h-10 rounded-full bg-white w-10 max-w-md z-30 flex justify-center items-center cursor-pointer"
          style={{bottom: "30px", right: "20px"}}>
          <LocationCurrentIcon />
        </div>
      : null}

    </div>
  )
}