import React from "react";

export const MenuInlineIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.375 9.75C4.13439 9.75 4.75 9.13439 4.75 8.375C4.75 7.61561 4.13439 7 3.375 7C2.61561 7 2 7.61561 2 8.375C2 9.13439 2.61561 9.75 3.375 9.75Z" fill="currentColor"/>
      <path d="M7.5 9.75C8.25939 9.75 8.875 9.13439 8.875 8.375C8.875 7.61561 8.25939 7 7.5 7C6.74061 7 6.125 7.61561 6.125 8.375C6.125 9.13439 6.74061 9.75 7.5 9.75Z" fill="currentColor"/>
      <path d="M13 8.375C13 9.13439 12.3844 9.75 11.625 9.75C10.8656 9.75 10.25 9.13439 10.25 8.375C10.25 7.61561 10.8656 7 11.625 7C12.3844 7 13 7.61561 13 8.375Z" fill="currentColor"/>
    </svg>

  );
}


