import { atom, selector } from "recoil";

const localStorageKey = "pathBeforeLogin";

const _pathBeforeLoginState = atom<string | undefined>({
  key: '_pathBeforeLoginState',
  default: localStorage.getItem(localStorageKey) ?? undefined
});

export const pathBeforeLoginState = selector<string | undefined>({
  key: 'pathBeforeLoginState',
  get: ({ get }) => get(_pathBeforeLoginState),
  set: ({ set }, path) => {
    if (path) {
      localStorage.setItem(localStorageKey, path as string)
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_pathBeforeLoginState, path)
  }
});