import React from "react";

export const CheckOnCircleIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    >
    <path
      fill="currentColor"
      d="M15.937 7.743l-.874-.486-4.14 7.451-3.089-2.78-.668.744 4.022 3.62 4.75-8.55z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 1C5.701 1 1 5.701 1 11.5S5.701 22 11.5 22 22 17.299 22 11.5 17.299 1 11.5 1zM2 11.5a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z"
      clipRule="evenodd"
    ></path>
    </svg>
  );
}