import React from "react";

export const WomensShoes = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2526 16.818C5.63864 16.818 3.64408 14.0824 3.56097 13.9647L3 13.1752L3.92803 12.9051C3.99036 12.8843 5.69405 12.2887 5.69405 9.14448C5.70097 1.79644 10.2788 1 12.2526 1C14.2194 1 18.8042 1.79644 18.8042 9.14448C18.8042 12.351 20.5563 12.8981 20.5702 12.9051L21.4982 13.1752L20.9442 13.9647C20.8611 14.0824 18.8665 16.818 12.2526 16.818ZM5.27851 13.7015C6.18576 14.3733 8.25651 15.4329 12.2526 15.4329C16.2486 15.4329 18.3194 14.3664 19.2266 13.7015C18.4233 13.0713 17.419 11.7554 17.419 9.14448C17.419 3.26466 14.1848 2.38512 12.2526 2.38512C10.3203 2.38512 7.08609 3.26466 7.08609 9.14448C7.08609 11.7554 6.07495 13.0713 5.27851 13.7015Z" fill="currentColor"/>
      <path d="M20.7502 23.2519C20.7502 23.2519 20.9234 20.2254 17.0312 19.6367C14.8427 19.3043 14.0601 17.5521 14.0601 15.3636H10.4311C10.4311 15.4121 10.4311 15.4537 10.4311 15.5022C10.3965 17.6214 9.60699 19.3112 7.46006 19.6367C3.56788 20.2254 3.74102 23.2519 3.74102 23.2519H20.7502Z" fill="currentColor"/>
      <path d="M8.38812 7.50311C8.17343 8.29955 8.06262 9.18603 8.06262 10.1141C8.06262 13.6669 9.70398 16.5548 12.2526 16.5548C14.8012 16.5548 16.4495 13.6738 16.4495 10.1141C16.4495 9.90629 16.4426 9.70545 16.4356 9.5046" fill="currentColor"/>
      <path d="M17.6407 10.6265C12.7374 10.6265 6.56667 7.25377 6.29657 7.10834L6.96835 5.89636C7.03068 5.93099 13.5546 9.46996 18.1116 9.23449L18.1878 10.6196C18.0008 10.6196 17.8207 10.6265 17.6407 10.6265Z" fill="currentColor"/>
    </svg>
  )
}
