import { localeState } from "../atoms";
import { ISOTimeToLocalTime } from "../helpers/StringHelper";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { ClockIcon } from "../icons";


type PlaceOpeningHoursProps = {
  openingHours?: ConsumerApi.OpeningHours
}

export const PlaceOpeningHours: React.FC<PlaceOpeningHoursProps> = (props) => {

  const { t } = useTranslation();
  const locale = useRecoilValue(localeState);
  const openingHours = props.openingHours;

  const openingHour = (title: string, isOpeningHoursSettled: boolean, openingHour?: ConsumerApi.OpeningHour) => {
    const format = (hour?: number, minute?: number) => {
      return ISOTimeToLocalTime(DateTime.now().set({ hour: hour || 0, minute: minute || 0 }).toISO(), locale)
    }
    return (
      <div className="flex mb-[10px]">
        <div className="flex-auto text-body">
          {title}
        </div>
        {isOpeningHoursSettled &&
          <div className="text-body text-right">
            {openingHour ?
              <span>
                {format(openingHour.openAtHour, openingHour.openAtMinute)}&nbsp;-&nbsp;
                {format(openingHour.closeAtHour, openingHour.closeAtMinute)}
              </span>
            : t("Closed")}
          </div>
        }
      </div>
    )
  }

  const isOpeningHoursSettled = !!openingHours?.weekly.monday ||
    !!openingHours?.weekly.tuesday ||
    !!openingHours?.weekly.wednesday ||
    !!openingHours?.weekly.thursday ||
    !!openingHours?.weekly.friday ||
    !!openingHours?.weekly.saturday ||
    !!openingHours?.weekly.sunday;

  return (
    <div className="flex flex-col pt-[15px] pb-[5px] not-first:border-t border-gray-lighther">
      <div className="flex mb-[10px] items-center">
        <div className="flex justify-center pr-[10px]">
          <ClockIcon />
        </div>
        <div className="text-title-bold flex-auto">
          {t("Opening Hours")}
        </div>
      </div>
      {openingHour(t("Monday"), isOpeningHoursSettled, openingHours?.weekly.monday)}
      {openingHour(t("Tuesday"), isOpeningHoursSettled, openingHours?.weekly.tuesday)}
      {openingHour(t("Wednesday"), isOpeningHoursSettled, openingHours?.weekly.wednesday)}
      {openingHour(t("Thursday"), isOpeningHoursSettled, openingHours?.weekly.thursday)}
      {openingHour(t("Friday"), isOpeningHoursSettled, openingHours?.weekly.friday)}
      {openingHour(t("Saturday"), isOpeningHoursSettled, openingHours?.weekly.saturday)}
      {openingHour(t("Sunday"), isOpeningHoursSettled, openingHours?.weekly.sunday)}
    </div>
  )
}