import { TFunction } from "react-i18next";
import { DateTime } from "luxon";
import { ConsumerApi } from "../ConsumerApi.dto";
import { Filter } from "./Filter";

export function copyFiltersUrlToQueryFilterDto(queryFilterDto: ConsumerApi.QueryFilterDto, filtersUrl?: string, categoryAbsoluteSlug?: string): ConsumerApi.QueryFilterDto {

  const _queryFilterDto = new ConsumerApi.QueryFilterDto();

  if (filtersUrl) {
    const filters = filtersUrl.split(";").map(f => {
      const keyValue = f.split("=");
      return [keyValue[0], keyValue[1].split(",").map(v => decodeURIComponent(v))];
    }).reduce((o, e) => {
      o[e[0] as string] = e[1];
      return o;
    }, {} as any) as {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: string[]};

    Object.keys(filters).forEach((featureType) => {
      const values = filters[featureType as ConsumerApi.FeatureType];
      switch (featureType) {
        case ConsumerApi.FeatureType.BarSpeciality:
          _queryFilterDto.barSpecialitySlugs = values;
          break;
        case ConsumerApi.FeatureType.BarType:
          _queryFilterDto.barTypeSlugs = values;
          break;
        case ConsumerApi.FeatureType.Cuisine:
          _queryFilterDto.cuisineSlugs = values;
          break;
        case ConsumerApi.FeatureType.MealType:
          _queryFilterDto.mealTypeSlugs = values;
          break;
        case ConsumerApi.FeatureType.Parking:
          _queryFilterDto.parking = values as ConsumerApi.Parking[];
          break;
        case ConsumerApi.FeatureType.PaymentOptions:
          _queryFilterDto.paymentOptions = values as ConsumerApi.PaymentOption[];
          break;
        case ConsumerApi.FeatureType.PriceMainDish:
          _queryFilterDto.priceMainDishes = values as ConsumerApi.PriceMainDish[];
          break;
        case ConsumerApi.FeatureType.SubCategories:
          if (categoryAbsoluteSlug) {
            // Prepend the parent absolute slug to the subCategory slug
            const _values = values.map(subCategory => `${categoryAbsoluteSlug}.${subCategory}`);
            _queryFilterDto.categoryAbsoluteSlugs = [...(_queryFilterDto.categoryAbsoluteSlugs || []), ..._values];
          } else {
            throw `It's not possible to use Subcategories filters when the categoryAbsoluteSlug is undefined`
          }
          break;
        case ConsumerApi.FeatureType.SubCategoriesAndBrands:
          _queryFilterDto.brandNames = values;
          break;
        case ConsumerApi.FeatureType.EatingSuitabilities:
          _queryFilterDto.eatingSuitabilitySlugs = values;
          break;
        case ConsumerApi.FeatureType.TakeAway:
          _queryFilterDto.takeAwaySlugs = values;
          break;
        case ConsumerApi.FeatureType.WearPriceProfile:
          _queryFilterDto.wearPriceProfileSlugs = values;
          break;
        case "Information":
          values.forEach(v => {
            switch (v as ConsumerApi.FeatureType) {
              case ConsumerApi.FeatureType.WheelChairAccessible:
                _queryFilterDto.wheelChairAccessible = true;
                break;
              case ConsumerApi.FeatureType.SaveFoodAndMoney:
                _queryFilterDto.saveFoodAndMoney = true;
                break;
              case ConsumerApi.FeatureType.OutdoorSeating:
                _queryFilterDto.outdoorSeating = true;
                break;
              case ConsumerApi.FeatureType.Wifi:
                _queryFilterDto.wifi = true;
                break;
            }
          });
          break;
        case "Term":
          _queryFilterDto.term = filters.Term[0];
          break;
        case "OpeningHours":
          // TODO
          break;
      }
    });
  }

  return {...queryFilterDto, ..._queryFilterDto};
}

export function convertResponseToFilters(response: ConsumerApi.FindAvailableFilterResponse, queryFilterDto: ConsumerApi.QueryFilterDto, t: TFunction<"translation">): {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined} {

  const filters: {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined} = {
    [ConsumerApi.FeatureType.BarSpeciality]: undefined,
    [ConsumerApi.FeatureType.BarType]: undefined,
    [ConsumerApi.FeatureType.Cuisine]: undefined,
    [ConsumerApi.FeatureType.SaveFoodAndMoney]: undefined,
    [ConsumerApi.FeatureType.MealType]: undefined,
    [ConsumerApi.FeatureType.Menu]: undefined,
    [ConsumerApi.FeatureType.OutdoorSeating]: undefined,
    [ConsumerApi.FeatureType.Parking]: undefined,
    [ConsumerApi.FeatureType.PaymentOptions]: undefined,
    [ConsumerApi.FeatureType.PriceMainDish]: undefined,
    [ConsumerApi.FeatureType.SubCategoriesAndBrands]: undefined,
    [ConsumerApi.FeatureType.SubCategories]: undefined,
    [ConsumerApi.FeatureType.EatingSuitabilities]: undefined,
    [ConsumerApi.FeatureType.TakeAway]: undefined,
    [ConsumerApi.FeatureType.WearPriceProfile]: undefined,
    [ConsumerApi.FeatureType.WheelChairAccessible]: undefined,
    [ConsumerApi.FeatureType.Wifi]: undefined,
    [ConsumerApi.FeatureType.WebShop]: undefined,
    [ConsumerApi.FeatureType.Filtering]: undefined,
    Information: undefined,
    OpeningHours: undefined,
    Term: undefined
  }

  const reduceFeatureTypes = (categories: ConsumerApi.CategoryFacetTreeDto[]): ConsumerApi.FeatureType[] =>
    categories.reduce<ConsumerApi.FeatureType[]>((featureTypes, c) => {
      featureTypes = [
        ...featureTypes,
        ...c.children ? reduceFeatureTypes(c.children).filter(ft => !featureTypes.some(_ft => _ft === ft)) : []
      ];

      return [
        ...featureTypes,
        ...(c.count && c.count > 0 && c.featureTypes) ?
          c.featureTypes.filter(ft => !featureTypes.some(_ft => _ft === ft)) :
          []
      ];
    }, [])

  const featureTypes = reduceFeatureTypes(response.categories);

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.SubCategoriesAndBrands)) {
    filters[ConsumerApi.FeatureType.SubCategoriesAndBrands] = response.brands
      .filter(b => b.count && b.count > 0)
      .map(brand =>
        new Filter({
          data: brand.name,
          label: brand.name,
          featureType: ConsumerApi.FeatureType.SubCategoriesAndBrands,
          selected: queryFilterDto.brandNames?.some(name => name === brand.name)
        }))
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.SubCategories || ft === ConsumerApi.FeatureType.SubCategoriesAndBrands)) {

    const findSelectedCategory = (categories: ConsumerApi.CategoryFacetTreeDto[]): ConsumerApi.CategoryFacetTreeDto | undefined => {
      let category = categories.find(c => c.type === ConsumerApi.CategoryFacetTreeType.Selected);
      if (!category) {
        for (let c of categories) {
          if (c.children) {
            category = findSelectedCategory(c.children);
          }
        }
      }
      return category;
    }

    const selectedCategory = findSelectedCategory(response.categories);

    if (!selectedCategory) {
      throw `A selected category is required ${JSON.stringify(response.categories)}`;
    }

    filters[ConsumerApi.FeatureType.SubCategories] = (selectedCategory.children || [])
      .filter(c => c.count && c.count > 0)
      .map(category =>
        new Filter({
          data: category.absoluteSlug,
          label: category.name,
          featureType: ConsumerApi.FeatureType.SubCategories,
          selected: queryFilterDto.categoryAbsoluteSlugs?.some(slug => slug === category.absoluteSlug)
        }))
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.WearPriceProfile)) {
    filters[ConsumerApi.FeatureType.WearPriceProfile] = response.wearPriceProfiles
      .filter(wpp => wpp.count && wpp.count > 0)
      .map(wpp =>
        new Filter({
            data: wpp.slug,
            label: wpp.label,
            featureType: ConsumerApi.FeatureType.WearPriceProfile,
            selected: queryFilterDto.wearPriceProfileSlugs?.some(slug => slug === wpp.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.Cuisine)) {
    filters[ConsumerApi.FeatureType.Cuisine] = response.cuisines
      .filter(c => c.count && c.count > 0)
      .map(c =>
        new Filter({
            data: c.slug,
            label: c.label,
            featureType: ConsumerApi.FeatureType.Cuisine,
            selected: queryFilterDto.cuisineSlugs?.some(slug => slug === c.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.MealType)) {
    filters[ConsumerApi.FeatureType.MealType] = response.mealType
      .filter(mt => mt.count && mt.count > 0 && mt.slug !== "not_serving_food" && mt.slug !== "save_food_and_money")
      .map(mt =>
        new Filter({
            data: mt.slug,
            label: mt.label,
            featureType: ConsumerApi.FeatureType.MealType,
            selected: queryFilterDto.mealTypeSlugs?.some(slug => slug === mt.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.PriceMainDish)) {
    filters[ConsumerApi.FeatureType.PriceMainDish] = response.priceMainDishes
      .filter(pmd => pmd.count && pmd.count > 0)
      .map(pmd =>
        new Filter({
            data: pmd.slug,
            label: pmd.title,
            featureType: ConsumerApi.FeatureType.PriceMainDish,
            selected: queryFilterDto.priceMainDishes?.some(slug => slug === pmd.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.TakeAway)) {
    filters[ConsumerApi.FeatureType.TakeAway] = response.takeAways
      .filter(ta => ta.count && ta.count > 0 && ta.slug !== "not_available")
      .map(ta =>
        new Filter({
            data: ta.slug,
            label: ta.label,
            featureType: ConsumerApi.FeatureType.TakeAway,
            selected: queryFilterDto.takeAwaySlugs?.some(slug => slug === ta.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.BarType)) {
    filters[ConsumerApi.FeatureType.BarType] = response.barTypes
      .filter(bt => bt.count && bt.count > 0)
      .map(bt =>
        new Filter({
            data: bt.slug,
            label: bt.title,
            featureType: ConsumerApi.FeatureType.BarType,
            selected: queryFilterDto.barTypeSlugs?.some(slug => slug === bt.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.BarSpeciality)) {
    filters[ConsumerApi.FeatureType.BarSpeciality] = response.barSpecialities
      .filter(bs => bs.count && bs.count > 0)
      .map(bs =>
        new Filter({
            data: bs.slug,
            label: bs.title,
            featureType: ConsumerApi.FeatureType.BarSpeciality,
            selected: queryFilterDto.barSpecialitySlugs?.some(slug => slug === bs.slug)}));
  }

  filters["Information"] = [];

  if (featureTypes.some(et => et === ConsumerApi.FeatureType.EatingSuitabilities)) {
    filters["Information"] = [...filters["Information"], ...response.eatingSuitabilities
      .filter(es => es.count && es.count > 0)
      .map(es =>
        new Filter({
            data: es.slug,
            label: es.label,
            featureType: ConsumerApi.FeatureType.EatingSuitabilities,
            selected: queryFilterDto.eatingSuitabilitySlugs?.some(slug => slug === es.slug)}))];
  }

  if (featureTypes.some(et => et === ConsumerApi.FeatureType.Wifi)) {
    filters["Information"].push(
      new Filter({
          data: ConsumerApi.FeatureType.Wifi,
          label: t("Free WiFi"),
          featureType: "Information",
          selected: queryFilterDto.wifi ? true : false}));
  }

  if (featureTypes.some(et => et === ConsumerApi.FeatureType.SaveFoodAndMoney)) {
    filters["Information"].push(
      new Filter({
          data: ConsumerApi.FeatureType.SaveFoodAndMoney,
          label: t("Save Food & Money"),
          featureType: "Information",
          selected: queryFilterDto.saveFoodAndMoney ? true : false}));
  }

  if (featureTypes.some(et => et === ConsumerApi.FeatureType.OutdoorSeating)) {
    filters["Information"].push(
      new Filter({
          data: ConsumerApi.FeatureType.OutdoorSeating,
          label: t("Outdoor Seating"),
          featureType: "Information",
          selected: queryFilterDto.outdoorSeating ? true : false}));
  }

  if (featureTypes.some(et => et === ConsumerApi.FeatureType.WheelChairAccessible)) {
    filters["Information"].push(
      new Filter({
          data: ConsumerApi.FeatureType.WheelChairAccessible,
          label: t("Wheelchair Accessible"),
          featureType: "Information",
          selected: queryFilterDto.wheelChairAccessible ? true : false}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.PaymentOptions)) {
    filters[ConsumerApi.FeatureType.PaymentOptions] = response.paymentOptions
      .filter(po => po.count && po.count > 0)
      .map(po =>
        new Filter({
            data: po.slug,
            label: po.label,
            featureType: ConsumerApi.FeatureType.PaymentOptions,
            selected: queryFilterDto.paymentOptions?.some(slug => slug === po.slug)}));
  }

  if (featureTypes.some(ft => ft === ConsumerApi.FeatureType.Parking)) {
    filters[ConsumerApi.FeatureType.Parking] = response.parkings
      .filter(p => p.count && p.count > 0)
      .map(p =>
        new Filter({
            data: p.slug,
            label: p.label,
            featureType: ConsumerApi.FeatureType.Parking,
            selected: queryFilterDto.parking?.some(slug => slug === p.slug)}));
  }

  filters["OpeningHours"] = [];

  let openNowSelected = false;
  let openAtSelected = false;
  const currentTime = DateTime.now();
  if (queryFilterDto.openAt) {
    // TODO: openAtTime hour should be located according to the Geolocation of the search
    if (currentTime.hour === queryFilterDto.openAt.hour && currentTime.minute === queryFilterDto.openAt.minute) {
      openNowSelected = true;
    } else {
      openAtSelected = true;
    }
  }

  filters["OpeningHours"].push(new Filter({
    data: "now",
    label: t("Open Now"),
    featureType: "OpeningHours",
    selected: openNowSelected}));

  filters["OpeningHours"].push(new Filter({
    data: "at",
    label: t("Open At"),
    featureType: "OpeningHours",
    selected: openAtSelected}));

  return filters;
}