import { referBusinessLinkUsedState } from "./referBusinessLinkUsed";
import { atom, selector } from "recoil";

const localStorageKey = "referBusinessToken";

const _referBusinessTokenState = atom<string | undefined>({
  key: '_referBusinessTokenState',
  default: localStorage.getItem(localStorageKey) ?? undefined
});

export const referBusinessTokenState = selector<string | undefined>({
  key: 'referBusinessTokenState',
  get: ({ get }) => get(_referBusinessTokenState),
  set: ({ set }, token) => {
    if (token) {
      localStorage.setItem(localStorageKey, token as string)
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_referBusinessTokenState, token);
    if (!token) {
      set(referBusinessLinkUsedState, undefined);
    }
  }
});