import React, { useMemo } from 'react';
import { ConsumerApi } from '../ConsumerApi.dto';

import { Filter } from './Filter';

type FiltersSelectedProps = {
  selectedFilters: Filter[]
  onRemove: (filter: Filter) => void
}

export const FiltersSelected: React.FC<FiltersSelectedProps> = (props) => {

  const selectedFilters = useMemo(() =>
    props.selectedFilters?.filter(f => f.data as ConsumerApi.FeatureType !== ConsumerApi.FeatureType.SaveFoodAndMoney), [props.selectedFilters]);

  return (
    selectedFilters && selectedFilters.length > 0 ?
      <div className="flex overflow-x-scroll w-full pb-[5px]">
        {selectedFilters.map((f, i) =>
          <div
            key={i}
            className="flex items-center bg-white border rounded border-gray-lighther px-[10px] py-[5px] mr-[10px] mb-[10px]">
            <div className="whitespace-nowrap text-body">
              {f.label}
            </div>
            <div onClick={() => props.onRemove(f)} className="cursor-pointer text-red pl-[5px]">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="6" stroke="#FF0505"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 5L5 5.70711L6.41421 7.12132L5 8.53553L5.70711 9.24264L7.12132 7.82843L8.53553 9.24264L9.24264 8.53553L7.82843 7.12132L9.24264 5.70711L8.53553 5L7.12132 6.41421L5.70711 5Z" fill="currentColor"/>
              </svg>
            </div>
          </div>)
        }
      </div>
    : null
  )
}