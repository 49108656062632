import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { allowedCountryCodes, defaultCountryCode } from '../Constants';

type InputPhoneProps = {
  autoFocus?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  maxLength?: number,
  value?: string,
  disabled?: boolean,
  label?: string,
  error?: string,
  onChange?: (value: string) => void,
  onFocus?: () => void
  onBlur?: () => void
}

export const InputPhone: React.FC<InputPhoneProps> = (props) => {

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  }, [props])

  const onBlur = useCallback(() => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  }, [props])

  return (
    <div className="flex flex-col">
      {props.label ?
        <div className="text-title-medium mb-[10px]">
          {props.label}
        </div>
      : null}
      <div
        className={`flex items-center w-full h-[50px] pl-[10px] pr-[20px] border ${isFocused ? "border-blue-light" : props.error ? "border-red" : "border-gray-light"} ${props.disabled ? "bg-near-white" : ""}`}>
        <PhoneInput
          autoComplete="tel"
          autoFocus={props.autoFocus}
          international={false}
          placeholder={props.placeholder}
          value={props.value || ""}
          defaultCountry={defaultCountryCode}
          countries={allowedCountryCodes}
          addInternationalOption={false}
          className={`w-full h-full ${props.disabled ? "bg-near-white" : ""}`}
          disabled={props.disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={props.readOnly}
          maxLength={props.maxLength}
          onChange={props.onChange || ((v) => {})} />
      </div>
      {props.error ?
        <div className="text-red text-title mt-[10px]">
          {props.error}
        </div>
      : null}
    </div>
  )
}