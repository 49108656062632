import { apiServiceState, loadingState, localeState, showSidebarState } from '../atoms';
import { Header } from '../header';
import { MenuIcon } from '../icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ISODateTimeToLocalDateTime } from '../helpers/StringHelper';
import { DateTime } from 'luxon';
import { Button } from '../button';
import { GetLotteryTickets } from './GetLotteryTickets';
import { ConsumerApi } from '../ConsumerApi.dto';
import fireworks from '../images/fireworks.svg';

export const Lottery: React.FC = () => {

  const { t } = useTranslation();
  const locale = useRecoilValue(localeState);
  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);
  const [activeLottery, setActiveLottery] = useState<ConsumerApi.ActiveLotteryDto>();
  const [previousLottery, setPreviousLottery] = useState<ConsumerApi.PreviousLotteryDto>();

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const [imageIndex, setImageIndex] = useState<number>(0);

  const [showGetTickets, setShowGetTickets] = useState(false);

  const onAcquiredTickets = useCallback((totalTicketsInLottery: number) => {
    setShowGetTickets(false);
    setActiveLottery(lottery => ({
      ...(lottery as ConsumerApi.ActiveLotteryDto),
      yourTicketCount: totalTicketsInLottery
    }))
  }, []);

  useEffect(() => {
    setLoading(true);
    const request = new ConsumerApi.LotteryGetRequest();
    apiService.get(request)
      .then((response) => {
        setActiveLottery(response.activeLottery);
        setPreviousLottery(response.relevantPreviousLottery);
      })
      .finally(() => setLoading(false));
  }, [apiService])


  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30 mx-auto max-w-md bg-white">
        <Header
          leftIcon={<MenuIcon />}
          onClickLeftIcon={() => setShowSidebar(true)}
          title={t("Lottery")} />
      </div>
      <div className="h-full overflow-y-scroll pt-[60px]">
         {activeLottery ?
          <>
            <div className="bg-white w-full mx-auto max-w-md relative">
              <Splide
                onMoved={(splide, index, prev, dest) => setImageIndex(index)}
                options={{arrows: false, pagination: false}}>
                {activeLottery.imageUrls.map((img, i) =>
                  <SplideSlide key={i}>
                    <img
                      className="w-full object-cover"
                      alt="Place"
                      style={{height: "375px"}}
                      src={img} />
                  </SplideSlide>
                )}
              </Splide>
              <div className="flex justify-center absolute w-full" style={{height: "2px", bottom: "15px"}}>
                {activeLottery.imageUrls.map((_, i) =>
                  <div
                    key={i}
                    className="h-full w-5 mr-1 bg-white z-20"
                    style={{
                      marginRight: i < activeLottery.imageUrls.length - 1 ? "5px" : "0",
                      opacity: i === imageIndex ? "1" : "0.5"
                    }}></div>
                )}
              </div>
            </div>
            <div className="px-[12px] pb-[25px]">
              <div className="flex text-body py-[13px]">
                {t("Starts")}: {ISODateTimeToLocalDateTime(DateTime.fromISO(activeLottery.startAt as string).toString(), locale)}&nbsp;&nbsp;|&nbsp;&nbsp;{t("Ends")}: {ISODateTimeToLocalDateTime(DateTime.fromISO(activeLottery.stopAt as string).toString(), locale)}
              </div>
              <div className="font-bold text-[24px]">
                {activeLottery.title}
              </div>
              <div className="text-body mt-[15px]">
                {activeLottery.description}
              </div>
              {activeLottery.yourTicketCount && activeLottery.yourTicketCount > 0 ?
              <div className="mt-[15px] style-subtitle text-center">
                <Trans>Congratulations!<br></br>You are now participating in the Lottery.</Trans>              
              </div>
              : null}
              <div className="mt-[15px]">
                <div className="style-subtitle text-center">
                  {t("My Tickets In This Lottery")}
                </div>
                <div className="font-bold mt-[5px] text-[32px] text-center">
                  {activeLottery.yourTicketCount}
                </div>
              </div>
              <div className="py-[15px] border-gray-light border-b">
                <Button title={
                  activeLottery.yourTicketCount ?
                    t("Get More Lottery Tickets") :
                    t("Get Lottery Tickets")
                } onClick={() => setShowGetTickets(true)} />
              </div>
            </div>
          </>
        : null}
        {previousLottery ?
          <div className="px-[12px] pb-[40px] mt-[10px]">
            <div className="style-caption">
              {t("Previous Lottery")}
            </div>
            <div className="mt-[10px]">
              <div className="text-body">
                {t("Ended on")}: {ISODateTimeToLocalDateTime(DateTime.fromISO(previousLottery.stopAt as string).toString(), locale)}
              </div>
              <div className="font-bold text-[24px] mt-[2px]">
                {previousLottery.title}
              </div>
              {previousLottery.youAreTheWinner ?
                <div className="mt-[30px] flex flex-col items-center">
                  <img src={fireworks} />
                  <div className={`mt-[40px] mb-[20px] font-bold ${activeLottery ? "text-[32px]" : "text-[48px]"}`}>
                    {t("Congratulations, you are the winner of this Lottery!")}
                  </div>
                </div>
              :
                <div className="mt-[20px]">
                  <div className="style-caption">
                    {t("Drawn Winner")}
                  </div>
                  <div className="style-body-copy">
                    {previousLottery.winner}
                  </div>
                </div>
              }
            </div>
          </div>
        : null}
      </div>

      {showGetTickets && activeLottery ?
        <GetLotteryTickets
          activeLottery={activeLottery}
          onClose={() => setShowGetTickets(false)}
          onAcquiredTickets={onAcquiredTickets} />
      : null}
    </>
  )

}