import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from "../ConsumerApi.dto";
import { PlaceClaim, ReferPlace } from '../place-claim';
import { PlaceReportProblem } from '../place-report-problem';
import { PlaceVerified } from '../place-verified';

type PlaceFooterProps = {
  showReportProblem?: boolean,
  place: ConsumerApi.PlaceDto,
  hideSeparatorAbove: boolean
}

export const PlaceFooter: React.FC<PlaceFooterProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;

  const [showReportProblem, setShowReportProblem] = useState(false);
  const [showPlaceVerified, setShowPlaceVerified] = useState(false);
  const [showPlaceClaim, setShowPlaceClaim] = useState(false);
  const [showPlaceRefer, setShowPlaceRefer] = useState(false);

  return (
    <>
      <div className={`flex flex-col pb-[15px] ${props.hideSeparatorAbove ? "pt-[15px]" : ""}`}>
        {props.showReportProblem &&
          <div className="flex justify-center">
            <button
              onClick={() => setShowReportProblem(true)}
              className="text-red cursor-pointer text-body">
              {t("Report Problem")}
            </button>
          </div>
        }
        {place.claimed ?
          <button
            onClick={() => setShowPlaceVerified(true)}
            className="flex items-center justify-center text-green cursor-pointer text-body">
            {t("Managed by")} {place.name}
          </button>
        :
          <>
            {!props.hideSeparatorAbove &&
              <div className="flex justify-center pb-[15px] px-[12px]">
                <div className="w-full border-b border-gray-lighther height-[1px]"></div>
              </div>
            }
            <div className="px-[12px]">
              <div className="text-body text-center">
                {t("Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.")}
              </div>
              <button
                onClick={() => setShowPlaceClaim(true)}
                className="mt-[15px] w-full text-title-bold text-blue border-blue border h-[45px] rounded-[3px]">
                {t("Invite or Manage")}
              </button>
            </div>
          </>
        }
      </div>

      {showReportProblem &&
        <PlaceReportProblem onClose={() => setShowReportProblem(false)} />
      }

      {showPlaceVerified &&
        <PlaceVerified onClose={() => setShowPlaceVerified(false)} name={place.name} />
      }

      {showPlaceClaim && place.guid &&
        <PlaceClaim
          onRefer={() => {
            setShowPlaceClaim(false);
            setShowPlaceRefer(true);
          }}
          onClose={() => setShowPlaceClaim(false)}
          place={place} />
      }

      {showPlaceRefer &&
        <ReferPlace onClose={() => setShowPlaceRefer(false)} place={place} />
      }
    </>);

}