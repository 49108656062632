import { ConsumerApi } from "../ConsumerApi.dto";

export class Filter {
  public readonly data?: any;
  public readonly label: string;
  public readonly featureType: ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term";
  public readonly selected?: boolean;

  constructor(init: Filter) {
    Object.assign(this, init);
  }
}