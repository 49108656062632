import { sharePlaceLinkUsedState } from "./sharePlaceLinkUsed";
import { atom, selector } from "recoil";

const localStorageKey = "sharePlaceToken";

const _sharePlaceTokenState = atom<string | undefined>({
  key: '_sharePlaceTokenState',
  default: localStorage.getItem(localStorageKey) ?? undefined
});

export const sharePlaceTokenState = selector<string | undefined>({
  key: 'sharePlaceTokenState',
  get: ({ get }) => get(_sharePlaceTokenState),
  set: ({ set }, token) => {
    if (token) {
      localStorage.setItem(localStorageKey, token as string)
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_sharePlaceTokenState, token);
    if (!token) {
      set(sharePlaceLinkUsedState, undefined);
    }
  }
});