import React from "react";

export const Fashion = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2245_3537)">
        <path d="M22 5.46573L14.95 3C14.95 4.8923 13.65 6.38322 12 6.38322C10.35 6.38322 9.05 4.8923 9.05 3L2 5.46573L3.7 10.5119L6.1 10.3972V21.923H17.9V10.3399L20.3 10.4545L22 5.46573Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_2245_3537">
          <rect width="20" height="19" fill="white" transform="translate(2 3)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
