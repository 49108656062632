import React from "react";

export const Vipps = () => {
  return (
    <svg width="52" height="37" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4.62599C0 2.24965 1.9401 0.323242 4.33333 0.323242H47.6667C50.0599 0.323242 52 2.24965 52 4.62599V32.5938C52 34.9702 50.0599 36.8966 47.6667 36.8966H4.33333C1.9401 36.8966 0 34.9702 0 32.5938V4.62599Z" fill="#FE5B24"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2215 15.7577C13.5422 14.9791 15.2246 15.4525 15.9793 16.815C16.5871 17.9123 18.052 19.9731 20.1661 21.7284C22.2706 23.4757 24.8179 24.7598 27.6649 24.7598C30.0619 24.7598 31.9644 24.1974 33.5992 23.1819C35.2624 22.1489 36.7967 20.554 38.3006 18.2698C39.1553 16.9717 40.8681 16.6342 42.1264 17.5159C43.3847 18.3977 43.7119 20.1648 42.8572 21.4628C41.0561 24.1984 38.9832 26.4709 36.4405 28.0503C33.8695 29.6473 30.9675 30.4425 27.6649 30.4425C23.1596 30.4425 19.4284 28.4103 16.7125 26.1554C14.0061 23.9084 12.1088 21.281 11.1967 19.6344C10.442 18.2719 10.9009 16.5363 12.2215 15.7577Z" fill="white"/>
      <path d="M34.6667 10.0044C34.6667 11.7867 33.2116 13.2315 31.4167 13.2315C29.6217 13.2315 28.1667 11.7867 28.1667 10.0044C28.1667 8.22216 29.6217 6.77736 31.4167 6.77736C33.2116 6.77736 34.6667 8.22216 34.6667 10.0044Z" fill="white"/>
    </svg>
  )
}
