import { ConsumerApi } from "./ConsumerApi.dto";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { defaultLanguage } from "./Constants";

import resourceEnUs from "./locale/resource.en-US.json";
import resourceNbNo from "./locale/resource.nb-NO.json";

// Function to extract locale from URL parameters
function getLocaleFromURL(): ConsumerApi.Locale | null {
  const searchParams = new URLSearchParams(window.location.search);
  const localeParam = searchParams.get('locale');
  switch (localeParam?.toLowerCase()) {
    case "nb-no":
    case "nb":
    case "no":
    case "nn":
      return ConsumerApi.Locale.NbNo;
    case "en-us":
    case "en":
      return ConsumerApi.Locale.EnUs;
    default:
      return null; // No valid locale found in URL
  }
}

export function getDefault(): ConsumerApi.Locale {
  // First, try to get the locale from the URL
  const urlLocale = getLocaleFromURL();
  if (urlLocale) {
    return urlLocale;
  }

  // Fallback to browser's language settings
  let languageMatched = defaultLanguage;
  MAIN:
  for (const language of navigator.languages) {
    const languageParts = language.split("-");
    const languages = [language];

    if (languageParts.length > 1) {
      languages.push(languageParts[0]);
    }

    for (const language of languages) {
      switch (language.toLowerCase()) {
        case "nb-no":
        case "nb":
        case "no":
        case "nn":
          languageMatched = ConsumerApi.Locale.NbNo;
          break MAIN;
        case "en-us":
        case "en":
          languageMatched = ConsumerApi.Locale.EnUs;
          break MAIN;
      }
    }
  }
  return languageMatched;
}

// the translations
const resources = {
  "EnUs": {
    translation: resourceEnUs
  },
  "NbNo": {
    translation: resourceNbNo
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getDefault().toString(),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
