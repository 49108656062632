import React from "react";
import { LatLng } from "../model";
import { atom, selector } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { LocationIcon } from "../icons/location";
import { PlaceLocation } from "../model/PlaceLocation";
import { apiServiceState } from "./apiService";

const _recentLocations = selector<PlaceLocation[]>({
  key: '_recentLocations',
  get: async ({get}) => {
    const apiService = get(apiServiceState);

    return await apiService.get(new ConsumerApi.GetRecentLocationRequest()).then(response =>
      response.recentLocations.filter(
        l => l.latitude &&
        l.locationType !== ConsumerApi.RecentLocationType.Home &&
        l.locationType !== ConsumerApi.RecentLocationType.Current).map(l => {
        return new PlaceLocation({
          name: l.name,
          locationType: l.locationType as ConsumerApi.RecentLocationType,
          icon: <LocationIcon type={l.locationType as ConsumerApi.RecentLocationType} />,
          location: new LatLng(l.latitude as number, l.longitude as number),
          description: l.description
        })
      })
    ).catch((err) => []);
  },
});

export const recentLocationsState = atom<PlaceLocation[]>({
  key: 'recentLocationsState',
  default: _recentLocations
});

export const appendRecentLocationState = selector<PlaceLocation | undefined>({
  key: 'appendRecentLocationState',
  get: () => undefined,
  set: ({set, get}, placeLocation) => {
    if (placeLocation &&
        (placeLocation as PlaceLocation).locationType !== ConsumerApi.RecentLocationType.Current &&
        (placeLocation as PlaceLocation).locationType !== ConsumerApi.RecentLocationType.Home ) {
      const apiService = get(apiServiceState);
      const p = placeLocation as PlaceLocation;

      let recentLocations = [...get(recentLocationsState)];
      let currentIndex = recentLocations.findIndex(l => l.name === p.name);

      apiService.post(new ConsumerApi.AddRecentLocationRequest({
        name: p.name,
        description: p.description,
        latitude: p.location.lat,
        longitude: p.location.lng,
        locationType: p.locationType,
        googleAddressComponents: [],
      }));

      if (currentIndex > 0) {
        recentLocations.splice(currentIndex, 1);
        recentLocations.unshift(p);
      } else {
        recentLocations = [p, ...recentLocations];
        if (recentLocations.length > 5) {
          recentLocations.splice(recentLocations.length - 1, 1);
        }
      }

      set(recentLocationsState, recentLocations);
    }
  }
});