import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsumerApi } from "../ConsumerApi.dto";
import { AdMessageModel } from "../model";


export const useAdMessageModel = (adMessageDto: ConsumerApi.AdMessageDto) => {

  const { t } = useTranslation();

  const adMessageModel = useMemo<AdMessageModel>(() => {
    const model = {dto: adMessageDto} as AdMessageModel;

    if (adMessageDto.detailsUnion.absoluteOnSelectedMerchandise) {
      const absolute = adMessageDto.detailsUnion.absoluteOnSelectedMerchandise;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      }
    } else if (adMessageDto.detailsUnion.absoluteRangeOnAllMerchandise) {
      var absolute = adMessageDto.detailsUnion.absoluteRangeOnAllMerchandise;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      }
    } else if (adMessageDto.detailsUnion.percentageOnSelectedMerchandise) {
      const percentage = adMessageDto.detailsUnion.percentageOnSelectedMerchandise;
      return {
        ...model,
        title: adMessageDto.title,
        description: percentage.description,
        moreInformation: percentage.moreInformation
      };
    } else if (adMessageDto.detailsUnion.percentageRangeOnAllMerchandise) {
      const percentage = adMessageDto.detailsUnion.percentageRangeOnAllMerchandise;
      return {
        ...model,
        title: adMessageDto.title,
        description: percentage.description,
        moreInformation: percentage.moreInformation
      };
    } else if (adMessageDto.detailsUnion.percentageOnAllMerchandise) {
      const percentage = adMessageDto.detailsUnion.percentageOnAllMerchandise;
      return {
        ...model,
        title: adMessageDto.title,
        description: percentage.description,
        moreInformation: percentage.moreInformation
      };
    } else if (adMessageDto.detailsUnion.event) {
      const _event = adMessageDto.detailsUnion.event;
      return {
        ...model,
        title: _event.title,
        description: _event.description,
        moreInformation: _event.moreInformation
      };
    } else if (adMessageDto.detailsUnion.freetext) {
      const freeText = adMessageDto.detailsUnion.freetext;
      return {
        ...model,
        title: freeText.title,
        description: freeText.description,
        moreInformation: freeText.moreInformation
      };
    } else if (adMessageDto.detailsUnion.newArrivals) {
      const newArrivals = adMessageDto.detailsUnion.newArrivals;
      return {
        ...model,
        title: adMessageDto.title,
        description: newArrivals.description,
        moreInformation: newArrivals.moreInformation
      };
    } else if (adMessageDto.detailsUnion.totalAmountOverXgivesYdiscount) {
      const amountOverXGivesY = adMessageDto.detailsUnion.totalAmountOverXgivesYdiscount;
      return {
        ...model,
        title: adMessageDto.title,
        description: amountOverXGivesY.description,
        moreInformation: amountOverXGivesY.moreInformation
      };
    } else if (adMessageDto.detailsUnion.xforY) {
      const xForY = adMessageDto.detailsUnion.xforY;
      return {
        ...model,
        title: adMessageDto.title,
        description: xForY.description,
        moreInformation: xForY.moreInformation
      } as AdMessageModel;
    }
    else if (adMessageDto.detailsUnion.todaysBreakFastOffer) {
      const absolute = adMessageDto.detailsUnion.todaysBreakFastOffer;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      };
    } else if (adMessageDto.detailsUnion.todaysLunchOffer) {
      const absolute = adMessageDto.detailsUnion.todaysLunchOffer;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      };
    } else if (adMessageDto.detailsUnion.todaysDinnerOffer) {
      const absolute = adMessageDto.detailsUnion.todaysDinnerOffer;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      };
    } else if (adMessageDto.detailsUnion.saveFoodAndMoney) {
      const absolute = adMessageDto.detailsUnion.saveFoodAndMoney;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      };
    } else if (adMessageDto.detailsUnion.percentageOfYourFoodBill) {
      const absolute = adMessageDto.detailsUnion.percentageOfYourFoodBill;
      return {
        ...model,
        title: adMessageDto.title,
        description: absolute.description,
        moreInformation: absolute.moreInformation
      };
    } else if (adMessageDto.detailsUnion.happyHour) {
      return {
        ...model,
        title: t("Happy Hour")
      };
    } else {
      throw Error(`AdMessage with ${adMessageDto.id} is not supported`);
    }
  }, [adMessageDto, t]);

  return adMessageModel
}