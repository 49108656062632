import {useState, useEffect, RefObject, useCallback} from "react";
import {LatLng} from "../model";
import {useNavigate} from "react-router";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    appendRecentLocationState,
    currentLocationWorkedState,
    lastLocationState,
    loadingState,
    recentLocationsState
} from "../atoms";
import {ConsumerApi} from "../ConsumerApi.dto";
import {LocationIcon} from "../icons/location";
import {PlaceLocation} from "../model";
import {buildUrl} from "../helpers/FinderHelper";
import {useTranslation} from "react-i18next";
import {currentPositionOptions} from "../Constants";
import {useHomeLocation} from "./UseHomeLocation";

const refreshCurrentTimeoutSeconds = 10;

export const usePlaceLocation = (
    googleAttribution: RefObject<HTMLDivElement>,
    base: "finder" | "finder-map",
    _paramTab?: string,
    paramLocation?: string,
    paramFindMode?: string,
    paramPlaceGuid?: string,
    paramAdMessageGuid?: string,
    paramFilter?: string,
    paramShared?: string
) => {

    const {t} = useTranslation();
    const setLoading = useSetRecoilState(loadingState);
    const navigate = useNavigate();
    const homeLocation = useHomeLocation();
    const recentLocations = useRecoilValue(recentLocationsState);
    const appendRecentLocations = useSetRecoilState(appendRecentLocationState);
    const setLastLocation = useSetRecoilState(lastLocationState);
    const currentLocationWorked = useRecoilValue(currentLocationWorkedState);

    const [showChangeLocation, setShowChangeLocation] = useState(false);
    const [lastCurrentRefresh, setLastCurrentRefresh] = useState(new Date());

    const paramTab = _paramTab || "places";

    const onApplyChangeLocation = useCallback((placeLocation: PlaceLocation) => {
        switch (placeLocation.locationType) {
            case ConsumerApi.RecentLocationType.Current:
                navigate(buildUrl(base, paramTab, "current", paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramShared), {replace: true});
                break;
            case ConsumerApi.RecentLocationType.Home:
                navigate(buildUrl(base, paramTab, "home", paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramShared), {replace: true});
                break;
            case ConsumerApi.RecentLocationType.Named:
            case ConsumerApi.RecentLocationType.Pin:
                navigate(buildUrl(
                    base,
                    paramTab,
                    `${placeLocation.location.lat},${placeLocation.location.lng}`,
                    paramFindMode,
                    paramPlaceGuid,
                    paramAdMessageGuid,
                    paramFilter,
                    paramShared), {replace: true});
        }
        setShowChangeLocation(false);

        setLastLocation({...placeLocation});
    }, [base, paramFilter, history, paramFindMode, paramTab, paramPlaceGuid, paramAdMessageGuid, setLastLocation, paramShared])

    useEffect(() => {
        if (!paramLocation) {
            const defaultLocation = currentLocationWorked ? "current" : "home";
            navigate(buildUrl(base, paramTab, defaultLocation, paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramShared), {replace: true});
            return;
        }
        switch (paramLocation) {
            case "current":
                setLoading(true);
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLoading(false);
                        setLastLocation(new PlaceLocation({
                            name: t("Current Location"),
                            icon: <LocationIcon type={ConsumerApi.RecentLocationType.Current}/>,
                            locationType: ConsumerApi.RecentLocationType.Current,
                            location: new LatLng(position.coords.latitude, position.coords.longitude)
                        }))
                    },
                    () => {
                        setLoading(false);
                        navigate(buildUrl(base, paramTab, "home", paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramShared), {replace: true});
                    }, currentPositionOptions)
                break;
            case "home":
                setLastLocation(homeLocation);
                break;
            default:
                const [lat, lng] = (paramLocation as string).split(",").map(v => parseFloat(v));
                const placeLocation = recentLocations.find(l => l.location.lat === lat && l.location.lng === lng);
                if (placeLocation) {
                    setLastLocation(l => l && l.locationType !== placeLocation.locationType || l?.location.lat !== placeLocation.location.lat || l?.location.lng !== placeLocation.location.lng ? placeLocation : l);
                } else {
                    google.maps.importLibrary("places").then(() => {
                        const googleService = new google.maps.places.PlacesService(googleAttribution.current as HTMLDivElement);
                        const request: google.maps.places.PlaceSearchRequest = {
                            location: new google.maps.LatLng({lat: lat, lng: lng}),
                            rankBy: google.maps.places.RankBy.DISTANCE,
                            type: "administrative_area_level_2"
                        }
                        googleService.nearbySearch(request, (results) => {
                            const result = results && results.length > 0 ? results[0] : undefined;
                            if (result) {
                                const placeLocation = new PlaceLocation({
                                    name: result.name || result.vicinity || `${lat},${lng}}`,
                                    description: result.vicinity,
                                    placeId: result.place_id,
                                    locationType: ConsumerApi.RecentLocationType.Named,
                                    icon: <LocationIcon type={ConsumerApi.RecentLocationType.Pin}/>,
                                    location: new LatLng(lat, lng)
                                })
                                appendRecentLocations(placeLocation);
                            } else {
                                navigate(buildUrl(base, paramTab, "home", paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramShared), {replace: true});
                            }
                        })
                    });
                }
        }

    }, [history, paramLocation, homeLocation, recentLocations, appendRecentLocations, googleAttribution, base, paramFindMode, paramPlaceGuid, paramAdMessageGuid, paramFilter, paramTab, paramShared, setLastLocation, setLoading, t, lastCurrentRefresh]);

    useEffect(() => {
        const newDate = new Date();
        if (paramLocation === "current" && (newDate.getTime() - lastCurrentRefresh.getTime()) / 1000 > refreshCurrentTimeoutSeconds) {
            setLastCurrentRefresh(() => newDate);
        }
    }, [lastCurrentRefresh, paramLocation]);

    return {
        showChangeLocation,
        setShowChangeLocation,
        onApplyChangeLocation
    };
}