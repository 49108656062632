/* Options:
Date: 2024-03-26 02:58:34
Version: 8.0
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://unstable-consumer-api.brovs.com

//GlobalNamespace: ConsumerApi
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: {Consumer},Authenticate,ResponseStatus,ResponseError,IHasSessionId,IHasBearerToken
//ExcludeTypes: 
//DefaultImports: 
*/


export module ConsumerApi
{
    // @ts-nocheck

    export interface IReturn<T>
    {
        createResponse(): T;
    }

    export interface IHasSessionId
    {
        sessionId?: string;
    }

    export interface IHasBearerToken
    {
        bearerToken?: string;
    }

    export interface IGet
    {
    }

    export interface IPost
    {
    }

    export interface IPut
    {
    }

    export interface IDelete
    {
    }

    export enum CustomImageSlug
    {
        SaveFoodAndMoney = 'SaveFoodAndMoney',
    }

    export enum Parking
    {
        StreetParking = 'StreetParking',
        ParkingGarageNearby = 'ParkingGarageNearby',
    }

    export enum PaymentOption
    {
        Visa = 'Visa',
        MasterCard = 'MasterCard',
        AmericanExpress = 'AmericanExpress',
        Diners = 'Diners',
        Vipps = 'Vipps',
    }

    export enum PriceMainDish
    {
        Value = 'Value',
        Medium = 'Medium',
        High = 'High',
        FineDining = 'FineDining',
    }

    export enum DayOfWeek
    {
        Sunday = 'Sunday',
        Monday = 'Monday',
        Tuesday = 'Tuesday',
        Wednesday = 'Wednesday',
        Thursday = 'Thursday',
        Friday = 'Friday',
        Saturday = 'Saturday',
    }

    export class OpenAtTimeDto
    {
        public weekDay?: DayOfWeek;
        public hour?: number;
        public minute?: number;

        public constructor(init?: Partial<OpenAtTimeDto>) { (Object as any).assign(this, init); }
    }

    export class QueryFilterDto
    {
        public term?: string;
        public placeGuid?: string;
        public adMessageGuid?: string;
        public categoryAbsoluteSlugs?: string[];
        public barSpecialitySlugs?: string[];
        public barTypeSlugs?: string[];
        public brandNames?: string[];
        public cuisineSlugs?: string[];
        public mealTypeSlugs?: string[];
        public parking?: Parking[];
        public paymentOptions?: PaymentOption[];
        public priceMainDishes?: PriceMainDish[];
        public eatingSuitabilitySlugs?: string[];
        public takeAwaySlugs?: string[];
        public wearPriceProfileSlugs?: string[];
        public wheelChairAccessible?: boolean;
        public wifi?: boolean;
        public outdoorSeating?: boolean;
        public saveFoodAndMoney?: boolean;
        public openAt?: OpenAtTimeDto;

        public constructor(init?: Partial<QueryFilterDto>) { (Object as any).assign(this, init); }
    }

    export class QueryLocationDto
    {
        public longitude?: number;
        public latitude?: number;
        public searchRadiusInKm?: number;

        public constructor(init?: Partial<QueryLocationDto>) { (Object as any).assign(this, init); }
    }

    export enum RecentLocationType
    {
        Home = 'Home',
        Current = 'Current',
        Pin = 'Pin',
        Named = 'Named',
    }

    export class DateOfBirth
    {
        public year?: number;
        public month?: number;
        public dayOfMonth?: number;

        public constructor(init?: Partial<DateOfBirth>) { (Object as any).assign(this, init); }
    }

    export enum Sex
    {
        Male = 'Male',
        Female = 'Female',
    }

    export enum DistanceUnit
    {
        Kilometer = 'Kilometer',
        Mile = 'Mile',
    }

    export enum Locale
    {
        EnUs = 'EnUs',
        NbNo = 'NbNo',
    }

    export class UserAddress
    {
        public streetName: string;
        public streetNumber: string;
        public postalCode: string;
        public region: string;
        public countryCode: string;

        public constructor(init?: Partial<UserAddress>) { (Object as any).assign(this, init); }
    }

    export class HomeLocation
    {
        public address: UserAddress;
        public latitude?: number;
        public longitude?: number;

        public constructor(init?: Partial<HomeLocation>) { (Object as any).assign(this, init); }
    }

    export enum AndroidPwaInstallPromptOutcome
    {
        Accepted = 'Accepted',
        Dismissed = 'Dismissed',
    }

    export interface ICacheable
    {
        cacheVersion?: number;
    }

    export enum NewsletterAreaOfInterest
    {
        RestaurantCafeBarClub = 'RestaurantCafeBarClub',
        Fashion = 'Fashion',
        Press = 'Press',
        Blogger = 'Blogger',
        PrivateIndividual = 'PrivateIndividual',
    }

    export class MapBoundsDto
    {
        public north?: number;
        public south?: number;
        public east?: number;
        public west?: number;
        public clusterZoomLevel?: number;

        public constructor(init?: Partial<MapBoundsDto>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionCategory
    {
        public name: string;
        public absoluteSlug: string;

        public constructor(init?: Partial<FindSearchCompletionCategory>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionBrand
    {
        public name: string;
        public categoryAbsoluteSlug: string;

        public constructor(init?: Partial<FindSearchCompletionBrand>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionPlace
    {
        public name: string;
        public guid?: string;

        public constructor(init?: Partial<FindSearchCompletionPlace>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionFreeText
    {
        public term: string;

        public constructor(init?: Partial<FindSearchCompletionFreeText>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionEntry
    {
        public category?: FindSearchCompletionCategory;
        public brand?: FindSearchCompletionBrand;
        public place?: FindSearchCompletionPlace;
        public freeText?: FindSearchCompletionFreeText;

        public constructor(init?: Partial<FindSearchCompletionEntry>) { (Object as any).assign(this, init); }
    }

    export class OpeningHour
    {
        public openAtHour?: number;
        public openAtMinute?: number;
        public closeAtHour?: number;
        public closeAtMinute?: number;

        public constructor(init?: Partial<OpeningHour>) { (Object as any).assign(this, init); }
    }

    export class WeeklyOpeningHours
    {
        public monday?: OpeningHour;
        public tuesday?: OpeningHour;
        public wednesday?: OpeningHour;
        public thursday?: OpeningHour;
        public friday?: OpeningHour;
        public saturday?: OpeningHour;
        public sunday?: OpeningHour;

        public constructor(init?: Partial<WeeklyOpeningHours>) { (Object as any).assign(this, init); }
    }

    export class OpeningHours
    {
        public weekly: WeeklyOpeningHours;

        public constructor(init?: Partial<OpeningHours>) { (Object as any).assign(this, init); }
    }

    export class CategoryTreeDto
    {
        public name: string;
        public slug: string;
        public absoluteSlug: string;
        public imageUrl?: string;
        public allImageUrl?: string;
        public iconImageUrl?: string;
        public position?: number;
        public children?: CategoryTreeDto[];

        public constructor(init?: Partial<CategoryTreeDto>) { (Object as any).assign(this, init); }
    }

    export enum FeatureType
    {
        BarSpeciality = 'BarSpeciality',
        BarType = 'BarType',
        Cuisine = 'Cuisine',
        SaveFoodAndMoney = 'SaveFoodAndMoney',
        MealType = 'MealType',
        Menu = 'Menu',
        OutdoorSeating = 'OutdoorSeating',
        Parking = 'Parking',
        PaymentOptions = 'PaymentOptions',
        PriceMainDish = 'PriceMainDish',
        SubCategories = 'SubCategories',
        SubCategoriesAndBrands = 'SubCategoriesAndBrands',
        EatingSuitabilities = 'EatingSuitabilities',
        TakeAway = 'TakeAway',
        WearPriceProfile = 'WearPriceProfile',
        WheelChairAccessible = 'WheelChairAccessible',
        Wifi = 'Wifi',
        WebShop = 'WebShop',
        Filtering = 'Filtering',
    }

    export class IndividualFeatureDataDto
    {
        public slug?: FeatureType;
        public label: string;

        public constructor(init?: Partial<IndividualFeatureDataDto>) { (Object as any).assign(this, init); }
    }

    export class BarSpecialityDto
    {
        public slug: string;
        public title: string;

        public constructor(init?: Partial<BarSpecialityDto>) { (Object as any).assign(this, init); }
    }

    export class BarTypeDto
    {
        public slug: string;
        public title: string;

        public constructor(init?: Partial<BarTypeDto>) { (Object as any).assign(this, init); }
    }

    export class BrandDto
    {
        public name: string;

        public constructor(init?: Partial<BrandDto>) { (Object as any).assign(this, init); }
    }

    export class PlaceCustomBrandDto
    {
        public name: string;

        public constructor(init?: Partial<PlaceCustomBrandDto>) { (Object as any).assign(this, init); }
    }

    export class PlaceSelectedBrandDto
    {
        public brand?: BrandDto;
        public categoryAbsoluteSlugs?: string[];
        public placeCustomBrand?: PlaceCustomBrandDto;

        public constructor(init?: Partial<PlaceSelectedBrandDto>) { (Object as any).assign(this, init); }
    }

    export class CuisineDto
    {
        public label: string;
        public slug: string;

        public constructor(init?: Partial<CuisineDto>) { (Object as any).assign(this, init); }
    }

    export class MealTypeDto
    {
        public label: string;
        public slug: string;

        public constructor(init?: Partial<MealTypeDto>) { (Object as any).assign(this, init); }
    }

    export class ParkingDto
    {
        public label: string;
        public slug?: Parking;

        public constructor(init?: Partial<ParkingDto>) { (Object as any).assign(this, init); }
    }

    export class PaymentOptionDto
    {
        public label: string;
        public slug?: PaymentOption;

        public constructor(init?: Partial<PaymentOptionDto>) { (Object as any).assign(this, init); }
    }

    export class EatingSuitabilityDto
    {
        public label: string;
        public slug: string;

        public constructor(init?: Partial<EatingSuitabilityDto>) { (Object as any).assign(this, init); }
    }

    export class TakeAwayDto
    {
        public label: string;
        public slug: string;

        public constructor(init?: Partial<TakeAwayDto>) { (Object as any).assign(this, init); }
    }

    export class WearPriceProfileDto
    {
        public slug: string;
        public label: string;

        public constructor(init?: Partial<WearPriceProfileDto>) { (Object as any).assign(this, init); }
    }

    export class MenuDto
    {
        public name: string;
        public url: string;
        public position?: number;

        public constructor(init?: Partial<MenuDto>) { (Object as any).assign(this, init); }
    }

    export class ImageUrls
    {
        public preview: string;
        public small: string;
        public medium: string;
        public large: string;

        public constructor(init?: Partial<ImageUrls>) { (Object as any).assign(this, init); }
    }

    export enum PlaceType
    {
        Mall = 'Mall',
        Regular = 'Regular',
    }

    export class PriceMainDishDto
    {
        public title: string;
        public slug?: PriceMainDish;

        public constructor(init?: Partial<PriceMainDishDto>) { (Object as any).assign(this, init); }
    }

    export class TimeZoneDto
    {
        public baseUtcOffset?: string;
        public daylightName: string;
        public displayName: string;
        public id: string;
        public standardName: string;
        public supportsDaylightSavingTime?: boolean;

        public constructor(init?: Partial<TimeZoneDto>) { (Object as any).assign(this, init); }
    }

    export class PlaceContact
    {
        public phone?: string;
        public email?: string;
        public website?: string;
        public webShop?: string;

        public constructor(init?: Partial<PlaceContact>) { (Object as any).assign(this, init); }
    }

    export class PlaceAddress
    {
        public street: string;
        public postalCode: string;
        public region: string;
        public countryCode: string;

        public constructor(init?: Partial<PlaceAddress>) { (Object as any).assign(this, init); }
    }

    export class PlaceBasicDto
    {
        public id?: number;
        public guid?: string;
        public overview?: string;
        public name: string;
        public longitude?: number;
        public latitude?: number;
        public claimed?: boolean;
        public organizationNumber?: string;
        public countryCode: string;
        public openingHours?: OpeningHours;
        public wheelchairAccessible?: boolean;
        public wifi?: boolean;
        public outdoorSeating?: boolean;
        public saveFoodAndMoney?: boolean;
        public placeType?: PlaceType;
        public priceMainDish?: PriceMainDishDto;
        public timeZoneDto: TimeZoneDto;
        public businessId?: number;
        public contact: PlaceContact;
        public address: PlaceAddress;
        public categories: CategoryTreeDto[];
        public logoUrl?: ImageUrls;
        public imageUrl?: ImageUrls;
        public wearPriceProfiles: WearPriceProfileDto[];
        public brands: PlaceSelectedBrandDto[];
        public floor?: string;

        public constructor(init?: Partial<PlaceBasicDto>) { (Object as any).assign(this, init); }
    }

    export class MallOtherServiceDto
    {
        public serviceName: string;
        public floor: string;

        public constructor(init?: Partial<MallOtherServiceDto>) { (Object as any).assign(this, init); }
    }

    export class PlaceDto
    {
        public id?: number;
        public guid?: string;
        public overview?: string;
        public name: string;
        public longitude?: number;
        public latitude?: number;
        public claimed?: boolean;
        public organizationNumber?: string;
        public countryCode: string;
        public placeState: string;
        public followersCount?: number;
        public openingHours?: OpeningHours;
        public categories: CategoryTreeDto[];
        public featureData: IndividualFeatureDataDto[];
        public barSpecialities: BarSpecialityDto[];
        public barTypes: BarTypeDto[];
        public brands: PlaceSelectedBrandDto[];
        public cuisines: CuisineDto[];
        public mealTypes: MealTypeDto[];
        public parking: ParkingDto[];
        public paymentOptions: PaymentOptionDto[];
        public eatingSuitabilities: EatingSuitabilityDto[];
        public takeAways: TakeAwayDto[];
        public wearPriceProfiles: WearPriceProfileDto[];
        public menus: MenuDto[];
        public logoUrl?: ImageUrls;
        public imageUrl?: ImageUrls;
        public wheelchairAccessible?: boolean;
        public wifi?: boolean;
        public outdoorSeating?: boolean;
        public saveFoodAndMoney?: boolean;
        public placeType?: PlaceType;
        public mall?: PlaceBasicDto;
        public priceMainDish?: PriceMainDishDto;
        public places?: PlaceBasicDto[];
        public otherServices?: MallOtherServiceDto[];
        public floor?: string;
        public timeZoneDto: TimeZoneDto;
        public businessId?: number;
        public contact: PlaceContact;
        public address: PlaceAddress;

        public constructor(init?: Partial<PlaceDto>) { (Object as any).assign(this, init); }
    }

    export enum VippsAddressType
    {
        Home = 'Home',
        Work = 'Work',
        Other = 'Other',
    }

    export class VippsAddress
    {
        public addressType?: VippsAddressType;
        public country: string;
        public formatted: string;
        public postalCode: string;
        public region: string;
        public streetAddress: string;

        public constructor(init?: Partial<VippsAddress>) { (Object as any).assign(this, init); }
    }

    export class VippsUserInfo
    {
        public address: VippsAddress;
        public birthdate?: string;
        public email?: string;
        public emailVerified?: boolean;
        public familyName: string;
        public givenName: string;
        public name: string;
        public phoneNumber: string;
        public sessionIdentifier: string;
        public subjectIdentifier: string;

        public constructor(init?: Partial<VippsUserInfo>) { (Object as any).assign(this, init); }
    }

    export class RecentLocationDto
    {
        public name: string;
        public description?: string;
        public latitude?: number;
        public longitude?: number;
        public locationType?: RecentLocationType;
        public googleAddressComponents?: string[];

        public constructor(init?: Partial<RecentLocationDto>) { (Object as any).assign(this, init); }
    }

    export class UserProfileDto
    {
        public locale?: Locale;
        public userGuid?: string;
        public firstName?: string;
        public lastName?: string;
        public profilePhotoUrl?: string;
        public mobilePhone: string;
        public mobilePhoneVerified?: boolean;
        public email?: string;
        public emailVerified?: boolean;
        public hasPassword?: boolean;
        public dateOfBirth: DateOfBirth;
        public sex?: Sex;
        public home: HomeLocation;
        public showDistancesIn?: DistanceUnit;
        public managesPlacesInBusinessWeb?: boolean;
        public pointsTotal?: number;

        public constructor(init?: Partial<UserProfileDto>) { (Object as any).assign(this, init); }
    }

    export enum LoginNextStep
    {
        FixInvalidPhoneNumber = 'FixInvalidPhoneNumber',
        Signup = 'Signup',
        Login = 'Login',
    }

    export enum UserStatus
    {
        LoginOrSignup = 'LoginOrSignup',
        CompleteProfile = 'CompleteProfile',
        Ready = 'Ready',
    }

    export class RewardInfo
    {
        public points?: number;
        public count?: number;
        public pointsGrantedOnSuccess?: number;

        public constructor(init?: Partial<RewardInfo>) { (Object as any).assign(this, init); }
    }

    export class ActiveLottery
    {
        public id?: number;
        public firstDay?: string;
        public lastDay?: string;
        public contentBeforeSharing?: string;
        public contentAfterSharing?: string;
        public countryCode: string;
        public yourNumberOfTicketsInThisLottery?: number;

        public constructor(init?: Partial<ActiveLottery>) { (Object as any).assign(this, init); }
    }

    export class ActiveLotteryDto
    {
        public id?: number;
        public imageUrls: string[];
        public startAt?: string;
        public stopAt?: string;
        public title: string;
        public description: string;
        public yourTicketCount?: number;

        public constructor(init?: Partial<ActiveLotteryDto>) { (Object as any).assign(this, init); }
    }

    export class PreviousLotteryDto
    {
        public id?: number;
        public startAt?: string;
        public stopAt?: string;
        public title: string;
        public yourTicketCount?: number;
        public winner: string;
        public youAreTheWinner?: boolean;

        public constructor(init?: Partial<PreviousLotteryDto>) { (Object as any).assign(this, init); }
    }

    export enum AndroidPwaInstallPromptOutcomeHandled
    {
        Stored = 'Stored',
        Cleared = 'Cleared',
        NoChange = 'NoChange',
    }

    export class AdMessageDetailsBase
    {
        public description?: string;
        public moreInformation?: string[];

        public constructor(init?: Partial<AdMessageDetailsBase>) { (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsPercentageDiscountOnAllMerchandise extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsPercentageDiscountOnAllMerchandise>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsPercentageDiscountRangeOnAllMerchandise extends AdMessageDetailsBase
    {
        public start?: number;
        public stop?: number;

        public constructor(init?: Partial<AdMessageDetailsPercentageDiscountRangeOnAllMerchandise>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsPercentageDiscountOnSelectedMerchandise extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsPercentageDiscountOnSelectedMerchandise>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsAbsoluteDiscountOnSelectedMerchandise extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsAbsoluteDiscountOnSelectedMerchandise>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsAbsoluteDiscountRangeOnAllMerchandise extends AdMessageDetailsBase
    {
        public start?: number;
        public stop?: number;

        public constructor(init?: Partial<AdMessageDetailsAbsoluteDiscountRangeOnAllMerchandise>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsTotalAmountOverXgivesYdiscount extends AdMessageDetailsBase
    {
        public ifTotalAmountMoreThanX?: number;
        public thenYouGetDiscountPercentageY?: number;

        public constructor(init?: Partial<AdMessageDetailsTotalAmountOverXgivesYdiscount>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsXforYdiscount extends AdMessageDetailsBase
    {
        public ifYouBuyX?: number;
        public thenYouGetY?: number;

        public constructor(init?: Partial<AdMessageDetailsXforYdiscount>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsNewArrivals extends AdMessageDetailsBase
    {

        public constructor(init?: Partial<AdMessageDetailsNewArrivals>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsFreetext extends AdMessageDetailsBase
    {
        public title: string;

        public constructor(init?: Partial<AdMessageDetailsFreetext>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsTodaysBreakFastOffer extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsTodaysBreakFastOffer>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsTodaysLunchOffer extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsTodaysLunchOffer>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsTodaysDinnerOffer extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsTodaysDinnerOffer>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsSaveFoodAndMoney extends AdMessageDetailsBase
    {
        public value?: number;

        public constructor(init?: Partial<AdMessageDetailsSaveFoodAndMoney>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsPercentageOffYourFoodBill extends AdMessageDetailsBase
    {
        public percentage?: number;

        public constructor(init?: Partial<AdMessageDetailsPercentageOffYourFoodBill>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsHappyHour extends AdMessageDetailsBase
    {

        public constructor(init?: Partial<AdMessageDetailsHappyHour>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsSomeFreeTablesLeftToday extends AdMessageDetailsBase
    {

        public constructor(init?: Partial<AdMessageDetailsSomeFreeTablesLeftToday>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsTwoDishesForThePriceOfOne extends AdMessageDetailsBase
    {

        public constructor(init?: Partial<AdMessageDetailsTwoDishesForThePriceOfOne>) { super(init); (Object as any).assign(this, init); }
    }

    export class AdMessageDetailsUnion
    {
        public percentageOnAllMerchandise?: AdMessageDetailsPercentageDiscountOnAllMerchandise;
        public percentageRangeOnAllMerchandise?: AdMessageDetailsPercentageDiscountRangeOnAllMerchandise;
        public percentageOnSelectedMerchandise?: AdMessageDetailsPercentageDiscountOnSelectedMerchandise;
        public absoluteOnSelectedMerchandise?: AdMessageDetailsAbsoluteDiscountOnSelectedMerchandise;
        public absoluteRangeOnAllMerchandise?: AdMessageDetailsAbsoluteDiscountRangeOnAllMerchandise;
        public totalAmountOverXgivesYdiscount?: AdMessageDetailsTotalAmountOverXgivesYdiscount;
        public xforY?: AdMessageDetailsXforYdiscount;
        public newArrivals?: AdMessageDetailsNewArrivals;
        public freetext?: AdMessageDetailsFreetext;
        public todaysBreakFastOffer?: AdMessageDetailsTodaysBreakFastOffer;
        public todaysLunchOffer?: AdMessageDetailsTodaysLunchOffer;
        public todaysDinnerOffer?: AdMessageDetailsTodaysDinnerOffer;
        public saveFoodAndMoney?: AdMessageDetailsSaveFoodAndMoney;
        public percentageOfYourFoodBill?: AdMessageDetailsPercentageOffYourFoodBill;
        public happyHour?: AdMessageDetailsHappyHour;
        public someFreeTablesLeftToday?: AdMessageDetailsSomeFreeTablesLeftToday;
        public twoDishesForThePriceOfOne?: AdMessageDetailsTwoDishesForThePriceOfOne;

        public constructor(init?: Partial<AdMessageDetailsUnion>) { (Object as any).assign(this, init); }
    }

    export class AdMessageDto
    {
        public id?: number;
        public guid?: string;
        public title: string;
        public startAt?: string;
        public stopAt?: string;
        public publishAt?: string;
        public imageUrls: ImageUrls[];
        public place: PlaceBasicDto;
        public detailsUnion: AdMessageDetailsUnion;
        public starredCount?: number;

        public constructor(init?: Partial<AdMessageDto>) { (Object as any).assign(this, init); }
    }

    export class StarredDto
    {
        public adMessage: AdMessageDto;

        public constructor(init?: Partial<StarredDto>) { (Object as any).assign(this, init); }
    }

    export class CategoryDto
    {
        public name: string;
        public slug: string;
        public absoluteSlug: string;
        public imageUrl?: string;
        public allImageUrl?: string;
        public iconImageUrl?: string;
        public position?: number;

        public constructor(init?: Partial<CategoryDto>) { (Object as any).assign(this, init); }
    }

    export class FilterDetails
    {
        public categories: CategoryDto[];
        public barSpecialities: BarSpecialityDto[];
        public barTypes: BarTypeDto[];
        public brands: BrandDto[];
        public cuisines: CuisineDto[];
        public mealTypes: MealTypeDto[];
        public parking: ParkingDto[];
        public paymentOptions: PaymentOptionDto[];
        public priceMainDishes: PriceMainDishDto[];
        public eatingSuitability: EatingSuitabilityDto[];
        public takeAways: TakeAwayDto[];
        public wearPriceProfiles: WearPriceProfileDto[];
        public rootCategories: CategoryDto[];

        public constructor(init?: Partial<FilterDetails>) { (Object as any).assign(this, init); }
    }

    export class SavedSearchDto
    {
        public id?: number;
        public name: string;
        public filter: QueryFilterDto;
        public location: QueryLocationDto;
        public filterDetails: FilterDetails;
        public createdAt?: string;
        public updatedAt?: string;

        public constructor(init?: Partial<SavedSearchDto>) { (Object as any).assign(this, init); }
    }

    export class NewsDto
    {
        public subject: string;
        public body: string;
        public author: string;
        public createdAt?: string;

        public constructor(init?: Partial<NewsDto>) { (Object as any).assign(this, init); }
    }

    export class BarTypeCountDto extends BarTypeDto
    {
        public count?: number;

        public constructor(init?: Partial<BarTypeCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class BarSpecialityCountDto extends BarSpecialityDto
    {
        public count?: number;

        public constructor(init?: Partial<BarSpecialityCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class BrandCountDto extends BrandDto
    {
        public count?: number;

        public constructor(init?: Partial<BrandCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class CuisineCountDto extends CuisineDto
    {
        public count?: number;

        public constructor(init?: Partial<CuisineCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class MealTypeCountDto extends MealTypeDto
    {
        public count?: number;

        public constructor(init?: Partial<MealTypeCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class EatingSuitabilityCountDto extends EatingSuitabilityDto
    {
        public count?: number;

        public constructor(init?: Partial<EatingSuitabilityCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class ParkingCountDto extends ParkingDto
    {
        public count?: number;

        public constructor(init?: Partial<ParkingCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class PaymentOptionCountDto extends PaymentOptionDto
    {
        public count?: number;

        public constructor(init?: Partial<PaymentOptionCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class PriceMainDishCountDto extends PriceMainDishDto
    {
        public count?: number;

        public constructor(init?: Partial<PriceMainDishCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class TakeAwayCountDto extends TakeAwayDto
    {
        public count?: number;

        public constructor(init?: Partial<TakeAwayCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export class WearPriceProfileCountDto extends WearPriceProfileDto
    {
        public count?: number;

        public constructor(init?: Partial<WearPriceProfileCountDto>) { super(init); (Object as any).assign(this, init); }
    }

    export enum CategoryFacetTreeType
    {
        Parent = 'Parent',
        Selected = 'Selected',
        Available = 'Available',
    }

    export class CategoryFacetTreeDto
    {
        public name: string;
        public slug: string;
        public absoluteSlug: string;
        public featureTypes?: FeatureType[];
        public type?: CategoryFacetTreeType;
        public count?: number;
        public imageUrl?: string;
        public allImageUrl?: string;
        public iconImageUrl?: string;
        public position?: number;
        public children?: CategoryFacetTreeDto[];

        public constructor(init?: Partial<CategoryFacetTreeDto>) { (Object as any).assign(this, init); }
    }

    export class FindBoundedMapPinCluster
    {
        public count?: number;
        public latitude?: number;
        public longitude?: number;

        public constructor(init?: Partial<FindBoundedMapPinCluster>) { (Object as any).assign(this, init); }
    }

    export class Position
    {
        public longitude?: number;
        public latitude?: number;

        public constructor(init?: Partial<Position>) { (Object as any).assign(this, init); }
    }

    export class PlaceWithDisplayLocationDto
    {
        public place: PlaceDto;
        public crowdedMapDisplayLocation: Position;

        public constructor(init?: Partial<PlaceWithDisplayLocationDto>) { (Object as any).assign(this, init); }
    }

    export class AdMessageWithDisplayLocationDto
    {
        public adMessage: AdMessageDto;
        public crowdedMapDisplayLocation: Position;

        public constructor(init?: Partial<AdMessageWithDisplayLocationDto>) { (Object as any).assign(this, init); }
    }

    export class ReferFriendUser
    {
        public firstName?: string;
        public lastName?: string;
        public profilePhotoUrl?: string;

        public constructor(init?: Partial<ReferFriendUser>) { (Object as any).assign(this, init); }
    }

    export enum ReferFriendState
    {
        Available = 'Available',
        Awarded = 'Awarded',
        CanNotReferYourself = 'CanNotReferYourself',
        YouWereAlreadySignedUp = 'YouWereAlreadySignedUp',
        Expired = 'Expired',
    }

    export class ReferBusinessUser
    {
        public firstName: string;
        public lastName: string;
        public profilePhotoUrl?: string;

        public constructor(init?: Partial<ReferBusinessUser>) { (Object as any).assign(this, init); }
    }

    export class ReferredPlace
    {
        public id?: number;
        public guid?: string;
        public name: string;
        public logoUrl?: string;
        public imageUrl?: string;

        public constructor(init?: Partial<ReferredPlace>) { (Object as any).assign(this, init); }
    }

    export enum ReferBusinessState
    {
        SignInToSee = 'SignInToSee',
        Available = 'Available',
        CanNotReferYourself = 'CanNotReferYourself',
        AlreadyClaimedByYou = 'AlreadyClaimedByYou',
        AlreadyClaimedBySomeoneElse = 'AlreadyClaimedBySomeoneElse',
        Expired = 'Expired',
    }

    export class SharingUser
    {
        public firstName: string;
        public lastName: string;
        public profilePhotoUrl?: string;

        public constructor(init?: Partial<SharingUser>) { (Object as any).assign(this, init); }
    }

    export class SharedRootCategory
    {
        public name: string;
        public absoluteSlug: string;

        public constructor(init?: Partial<SharedRootCategory>) { (Object as any).assign(this, init); }
    }

    export class SharePriceMainDish
    {
        public label: string;
        public slug?: PriceMainDish;

        public constructor(init?: Partial<SharePriceMainDish>) { (Object as any).assign(this, init); }
    }

    export class ShareWearPriceProfile
    {
        public label: string;
        public slug: string;

        public constructor(init?: Partial<ShareWearPriceProfile>) { (Object as any).assign(this, init); }
    }

    export class SharedPlace
    {
        public id?: number;
        public guid?: string;
        public name: string;
        public logoUrl?: string;
        public imageUrl?: string;
        public openingHours?: OpeningHours;
        public timeZoneDto: TimeZoneDto;
        public rootCategories: SharedRootCategory[];
        public priceMainDish?: SharePriceMainDish;
        public wearPriceProfiles: ShareWearPriceProfile[];

        public constructor(init?: Partial<SharedPlace>) { (Object as any).assign(this, init); }
    }

    export enum SharingState
    {
        Teaser = 'Teaser',
        Awarded = 'Awarded',
        SelfShare = 'SelfShare',
        Expired = 'Expired',
    }

    export class SharedAdMessage
    {
        public id?: number;
        public guid?: string;
        public title: string;
        public imageUrl?: string;
        public startAt?: string;
        public stopAt?: string;

        public constructor(init?: Partial<SharedAdMessage>) { (Object as any).assign(this, init); }
    }

    // @DataContract
    export class ResponseError
    {
        // @DataMember(Order=1)
        public errorCode?: string;

        // @DataMember(Order=2)
        public fieldName?: string;

        // @DataMember(Order=3)
        public message?: string;

        // @DataMember(Order=4)
        public meta?: { [index: string]: string; };

        public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
    }

    // @DataContract
    export class ResponseStatus
    {
        // @DataMember(Order=1)
        public errorCode?: string;

        // @DataMember(Order=2)
        public message?: string;

        // @DataMember(Order=3)
        public stackTrace?: string;

        // @DataMember(Order=4)
        public errors?: ResponseError[];

        // @DataMember(Order=5)
        public meta?: { [index: string]: string; };

        public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
    }

    export class CustomImageGetResponse
    {
        public slug?: CustomImageSlug;
        public imageUrl: string;

        public constructor(init?: Partial<CustomImageGetResponse>) { (Object as any).assign(this, init); }
    }

    export class FindSearchCompletionResponse
    {
        public suggestions: FindSearchCompletionEntry[];

        public constructor(init?: Partial<FindSearchCompletionResponse>) { (Object as any).assign(this, init); }
    }

    export class GetFollowingListResponse
    {
        public userGuid?: string;
        public places: PlaceDto[];

        public constructor(init?: Partial<GetFollowingListResponse>) { (Object as any).assign(this, init); }
    }

    export class UnfollowResponse
    {
        public deleted?: boolean;

        public constructor(init?: Partial<UnfollowResponse>) { (Object as any).assign(this, init); }
    }

    export class PlacePokeResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<PlacePokeResponse>) { (Object as any).assign(this, init); }
    }

    export class TrackAppUsedResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<TrackAppUsedResponse>) { (Object as any).assign(this, init); }
    }

    export class TrackAppViewedAdMessageResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<TrackAppViewedAdMessageResponse>) { (Object as any).assign(this, init); }
    }

    export class TrackAppViewedPlaceResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<TrackAppViewedPlaceResponse>) { (Object as any).assign(this, init); }
    }

    export class VippsLoginResponse
    {
        public startUrl: string;
        public expiresAt?: string;

        public constructor(init?: Partial<VippsLoginResponse>) { (Object as any).assign(this, init); }
    }

    export class VippsLoginCallbackResponse
    {
        public vippsUserInfo?: VippsUserInfo;

        public constructor(init?: Partial<VippsLoginCallbackResponse>) { (Object as any).assign(this, init); }
    }

    export class AddRecentLocationResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<AddRecentLocationResponse>) { (Object as any).assign(this, init); }
    }

    export class ConfirmEmailResponse
    {
        public confirmedEmail: string;

        public constructor(init?: Partial<ConfirmEmailResponse>) { (Object as any).assign(this, init); }
    }

    export class GetBusinessWebSignInLinkResponse
    {
        public timeLimitedBusinessWebUrlWithTokens: string;

        public constructor(init?: Partial<GetBusinessWebSignInLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class GetRecentLocationResponse
    {
        public recentLocations: RecentLocationDto[];

        public constructor(init?: Partial<GetRecentLocationResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileGetResponse
    {
        public userProfile: UserProfileDto;

        public constructor(init?: Partial<ProfileGetResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdatePersonalInformationResponse
    {
        public firstName: string;
        public lastName: string;
        public dateOfBirth: DateOfBirth;
        public sex?: Sex;
        public showDistancesIn?: DistanceUnit;

        public constructor(init?: Partial<ProfileUpdatePersonalInformationResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdateLanguageResponse
    {
        public locale?: Locale;

        public constructor(init?: Partial<ProfileUpdateLanguageResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdateMobilePhoneSendVerificationSmsResponse
    {
        public profileUpdateMobilePhoneRequestToken: string;
        public sentSmsWithRequestId: string;

        public constructor(init?: Partial<ProfileUpdateMobilePhoneSendVerificationSmsResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdateMobilePhoneResponse
    {
        public verifiedMobilePhone: string;

        public constructor(init?: Partial<ProfileUpdateMobilePhoneResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdateEmailSendVerificationLinkResponse
    {
        public sentEmailWithRequestId: string;

        public constructor(init?: Partial<ProfileUpdateEmailSendVerificationLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdateHomeLocationResponse
    {
        public homeLocation: HomeLocation;

        public constructor(init?: Partial<ProfileUpdateHomeLocationResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileUpdatePhotoResponse
    {
        public imageUrl: string;

        public constructor(init?: Partial<ProfileUpdatePhotoResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileDeletePhotoResponse
    {
        public deleteCount?: number;

        public constructor(init?: Partial<ProfileDeletePhotoResponse>) { (Object as any).assign(this, init); }
    }

    export class ProfileChangePasswordResponse
    {
        public passwordScore?: number;

        public constructor(init?: Partial<ProfileChangePasswordResponse>) { (Object as any).assign(this, init); }
    }

    export class ResendConfirmEmailResponse
    {
        public sentEmailWithRequestId: string;

        public constructor(init?: Partial<ResendConfirmEmailResponse>) { (Object as any).assign(this, init); }
    }

    export class LoginNextStepResponse
    {
        public nextStep?: LoginNextStep;
        public hasMobilePhone?: boolean;
        public hasConfirmedMobilePhone?: boolean;
        public hasVippsAccount?: boolean;
        public hasPassword?: boolean;
        public hasEmail?: boolean;
        public hasConfirmedEmail?: boolean;

        public constructor(init?: Partial<LoginNextStepResponse>) { (Object as any).assign(this, init); }
    }

    export class UserStatusResponse
    {
        public status?: UserStatus;
        public suggestPwaInstallation?: boolean;
        public userProfile?: UserProfileDto;

        public constructor(init?: Partial<UserStatusResponse>) { (Object as any).assign(this, init); }
    }

    export class CompleteProfileResponse
    {
        public userProfile: UserProfileDto;

        public constructor(init?: Partial<CompleteProfileResponse>) { (Object as any).assign(this, init); }
    }

    export class EmailPasswordResetAndLoginResponse
    {
        public passwordScore?: number;

        public constructor(init?: Partial<EmailPasswordResetAndLoginResponse>) { (Object as any).assign(this, init); }
    }

    export class SendSmsForPasswordResetResponse
    {
        public sentSmsWithRequestId: string;
        public smsPasswordResetAndLoginRequestToken: string;

        public constructor(init?: Partial<SendSmsForPasswordResetResponse>) { (Object as any).assign(this, init); }
    }

    export class SmsPasswordResetAndLoginResponse
    {
        public passwordScore?: number;

        public constructor(init?: Partial<SmsPasswordResetAndLoginResponse>) { (Object as any).assign(this, init); }
    }

    export class SendSmsForVerificationResponse
    {
        public sentSmsWithTransactiontId: string;
        public smsVerificationRequestToken: string;

        public constructor(init?: Partial<SendSmsForVerificationResponse>) { (Object as any).assign(this, init); }
    }

    export class SmsVerificationResponse
    {
        public userCreateRequestToken: string;

        public constructor(init?: Partial<SmsVerificationResponse>) { (Object as any).assign(this, init); }
    }

    export class UserCreateResponse
    {
        public sentVerificationEmailWithRequestId: string;
        public passwordScore?: number;
        public userGuid?: string;
        public referFriendAwarded?: boolean;

        public constructor(init?: Partial<UserCreateResponse>) { (Object as any).assign(this, init); }
    }

    export class UserDeleteResponse
    {
        public deletedUserGuid?: string;
        public deletedUserPhone: string;

        public constructor(init?: Partial<UserDeleteResponse>) { (Object as any).assign(this, init); }
    }

    export class GetRewardsStatusResponse
    {
        public pointsInTotal?: number;
        public pointsExpiringAtEndOfThisYear?: number;
        public referFriend: RewardInfo;
        public sharePlace: RewardInfo;
        public shareAdMessage: RewardInfo;
        public referBusiness: RewardInfo;
        public pointsAwardedForSignup?: number;
        public activeLottery?: ActiveLottery;

        public constructor(init?: Partial<GetRewardsStatusResponse>) { (Object as any).assign(this, init); }
    }

    export class LotteryGetResponse
    {
        public activeLottery?: ActiveLotteryDto;
        public relevantPreviousLottery?: PreviousLotteryDto;

        public constructor(init?: Partial<LotteryGetResponse>) { (Object as any).assign(this, init); }
    }

    export class LotteryAvailabePointsResponse
    {
        public availablePoints?: number;

        public constructor(init?: Partial<LotteryAvailabePointsResponse>) { (Object as any).assign(this, init); }
    }

    export class LotteryAcquireTicketsResponse
    {
        public newTicketsAcquired?: number;
        public pointsSpent?: number;
        public totalPointsLeft?: number;
        public totalTicketsInLottery?: number;

        public constructor(init?: Partial<LotteryAcquireTicketsResponse>) { (Object as any).assign(this, init); }
    }

    export class AndroidPwaInstallPromptOutcomeResponse
    {
        public handling?: AndroidPwaInstallPromptOutcomeHandled;

        public constructor(init?: Partial<AndroidPwaInstallPromptOutcomeResponse>) { (Object as any).assign(this, init); }
    }

    export class GetStarredAdMessageListResponse
    {
        public starredList: StarredDto[];

        public constructor(init?: Partial<GetStarredAdMessageListResponse>) { (Object as any).assign(this, init); }
    }

    export class StarredAdMessageResponse
    {
        public starredAdMessageId?: number;

        public constructor(init?: Partial<StarredAdMessageResponse>) { (Object as any).assign(this, init); }
    }

    export class UnstarAdMessageResponse
    {
        public unstarredAdMessageId?: number;

        public constructor(init?: Partial<UnstarAdMessageResponse>) { (Object as any).assign(this, init); }
    }

    export class AddSavedSearchResponse
    {
        public savedSearchId?: number;

        public constructor(init?: Partial<AddSavedSearchResponse>) { (Object as any).assign(this, init); }
    }

    export class DeleteSavedSearchResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<DeleteSavedSearchResponse>) { (Object as any).assign(this, init); }
    }

    export class GetSavedSearchesResponse
    {
        public searches: SavedSearchDto[];

        public constructor(init?: Partial<GetSavedSearchesResponse>) { (Object as any).assign(this, init); }
    }

    export class UpdateSavedSearchResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<UpdateSavedSearchResponse>) { (Object as any).assign(this, init); }
    }

    export class GetPlaceResponse
    {
        public placeDto: PlaceDto;
        public adMessages: AdMessageDto[];
        public topCategoriesSlug: string[];

        public constructor(init?: Partial<GetPlaceResponse>) { (Object as any).assign(this, init); }
    }

    export class NewsletterSignupResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<NewsletterSignupResponse>) { (Object as any).assign(this, init); }
    }

    export class GetNewsResponse
    {
        public news: NewsDto[];

        public constructor(init?: Partial<GetNewsResponse>) { (Object as any).assign(this, init); }
    }

    export class GetAppMenuDataResponse
    {
        public followedPlacesCount?: number;
        public starredAdMessagesCount?: number;
        public savedSearchesCount?: number;
        public newsCount?: number;
        public hasActiveLottery?: boolean;

        public constructor(init?: Partial<GetAppMenuDataResponse>) { (Object as any).assign(this, init); }
    }

    export class FollowResponse
    {
        public success?: boolean;

        public constructor(init?: Partial<FollowResponse>) { (Object as any).assign(this, init); }
    }

    export class FindAvailableFilterResponse
    {
        public barTypes: BarTypeCountDto[];
        public barSpecialities: BarSpecialityCountDto[];
        public brands: BrandCountDto[];
        public cuisines: CuisineCountDto[];
        public mealType: MealTypeCountDto[];
        public eatingSuitabilities: EatingSuitabilityCountDto[];
        public parkings: ParkingCountDto[];
        public paymentOptions: PaymentOptionCountDto[];
        public priceMainDishes: PriceMainDishCountDto[];
        public takeAways: TakeAwayCountDto[];
        public wearPriceProfiles: WearPriceProfileCountDto[];
        public categories: CategoryFacetTreeDto[];

        public constructor(init?: Partial<FindAvailableFilterResponse>) { (Object as any).assign(this, init); }
    }

    export class FindAdMessageResponse
    {
        public adMessages: AdMessageDto[];
        public adMessageCount?: number;
        public adMessageSaveFoodAndMoneyCount?: number;
        public resultAt?: string;
        public categoryFacetTrees: CategoryFacetTreeDto[];

        public constructor(init?: Partial<FindAdMessageResponse>) { (Object as any).assign(this, init); }
    }

    export class FindBoundedMapPlacesResponse
    {
        public clusters: FindBoundedMapPinCluster[];
        public placesWithDisplayLocation: PlaceWithDisplayLocationDto[];
        public categoryFacetTrees: CategoryFacetTreeDto[];
        public adMessageCount?: number;
        public adMessageSaveFoodAndMoneyCount?: number;

        public constructor(init?: Partial<FindBoundedMapPlacesResponse>) { (Object as any).assign(this, init); }
    }

    export class FindBoundedMapAdMessagesResponse
    {
        public clusters: FindBoundedMapPinCluster[];
        public adMessagesWithDisplayLocation: AdMessageWithDisplayLocationDto[];
        public categoryFacetTrees: CategoryFacetTreeDto[];
        public placeCount?: number;
        public adMessageSaveFoodAndMoneyCount?: number;

        public constructor(init?: Partial<FindBoundedMapAdMessagesResponse>) { (Object as any).assign(this, init); }
    }

    export class FindPlaceResponse
    {
        public places: PlaceDto[];
        public placeCount?: number;
        public resultAt?: string;
        public categoryFacetTrees: CategoryFacetTreeDto[];

        public constructor(init?: Partial<FindPlaceResponse>) { (Object as any).assign(this, init); }
    }

    export class FindResponse
    {
        public adMessages: AdMessageDto[];
        public places: PlaceDto[];
        public placeCount?: number;
        public adMessageCount?: number;
        public adMessageSaveFoodAndMoneyCount?: number;
        public resultAt?: string;
        public categoryFacetTrees: CategoryFacetTreeDto[];

        public constructor(init?: Partial<FindResponse>) { (Object as any).assign(this, init); }
    }

    export class GetAdMessageDraftsResponse
    {
        public draftAdMessages: AdMessageDto[];

        public constructor(init?: Partial<GetAdMessageDraftsResponse>) { (Object as any).assign(this, init); }
    }

    export class GetAdMessageResponse
    {
        public adMessageDto: AdMessageDto;

        public constructor(init?: Partial<GetAdMessageResponse>) { (Object as any).assign(this, init); }
    }

    export class TestLogResponse
    {
        public sentinel: string;

        public constructor(init?: Partial<TestLogResponse>) { (Object as any).assign(this, init); }
    }

    export class LogFrontEndErrorResponse
    {
        public loggedAt?: string;

        public constructor(init?: Partial<LogFrontEndErrorResponse>) { (Object as any).assign(this, init); }
    }

    export class LogFrontEndEventResponse
    {
        public loggedAt?: string;

        public constructor(init?: Partial<LogFrontEndEventResponse>) { (Object as any).assign(this, init); }
    }

    export class GetRefreshTokenResponse
    {
        public refreshToken: string;

        public constructor(init?: Partial<GetRefreshTokenResponse>) { (Object as any).assign(this, init); }
    }

    export class UseRefreshTokenResponse
    {
        public refreshToken: string;
        public bearerToken: string;

        public constructor(init?: Partial<UseRefreshTokenResponse>) { (Object as any).assign(this, init); }
    }

    export class EmailSendVerificationCodeResponse
    {
        public sentEmailWithRequestId: string;
        public emailVerificationWithCodeToken: string;

        public constructor(init?: Partial<EmailSendVerificationCodeResponse>) { (Object as any).assign(this, init); }
    }

    export class EmailResendVerificationCodeResponse
    {
        public email: string;
        public sentEmailWithRequestId: string;
        public emailVerificationWithCodeToken: string;

        public constructor(init?: Partial<EmailResendVerificationCodeResponse>) { (Object as any).assign(this, init); }
    }

    export class EmailVerificationWithCodeResponse
    {
        public verifiedEmail: string;

        public constructor(init?: Partial<EmailVerificationWithCodeResponse>) { (Object as any).assign(this, init); }
    }

    export class StatusResponse
    {
        public databaseConnectionOk?: boolean;
        public searchConnectionOk?: boolean;
        public deployedVersion: string;
        public deployedAt?: string;

        public constructor(init?: Partial<StatusResponse>) { (Object as any).assign(this, init); }
    }

    export class ReferFriendLinkResponse
    {
        public linkWithReferFriendToken: string;

        public constructor(init?: Partial<ReferFriendLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class ReferFriendLinkUsedResponse
    {
        public referringUser?: ReferFriendUser;
        public expiresAt?: string;
        public state?: ReferFriendState;

        public constructor(init?: Partial<ReferFriendLinkUsedResponse>) { (Object as any).assign(this, init); }
    }

    export class ReferBusinessLinkResponse
    {
        public linkWithReferBusinessToken: string;

        public constructor(init?: Partial<ReferBusinessLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class ReferBusinessLinkUsedResponse
    {
        public referringUser?: ReferBusinessUser;
        public referredPlace?: ReferredPlace;
        public expiresAt?: string;
        public state?: ReferBusinessState;

        public constructor(init?: Partial<ReferBusinessLinkUsedResponse>) { (Object as any).assign(this, init); }
    }

    export class SharePlaceLinkResponse
    {
        public linkWithSharePlaceToken: string;

        public constructor(init?: Partial<SharePlaceLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class SharePlaceLinkUsedResponse
    {
        public sharingUser?: SharingUser;
        public place?: SharedPlace;
        public expiresAt?: string;
        public state?: SharingState;

        public constructor(init?: Partial<SharePlaceLinkUsedResponse>) { (Object as any).assign(this, init); }
    }

    export class ShareAdMessageLinkResponse
    {
        public linkWithShareAdMessageToken: string;

        public constructor(init?: Partial<ShareAdMessageLinkResponse>) { (Object as any).assign(this, init); }
    }

    export class ShareAdMessageLinkUsedResponse
    {
        public user?: SharingUser;
        public adMessage?: SharedAdMessage;
        public place?: SharedPlace;
        public expiresAt?: string;
        public state?: SharingState;

        public constructor(init?: Partial<ShareAdMessageLinkUsedResponse>) { (Object as any).assign(this, init); }
    }

    // @DataContract
    export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
    {
        // @DataMember(Order=1)
        public userId?: string;

        // @DataMember(Order=2)
        public sessionId?: string;

        // @DataMember(Order=3)
        public userName?: string;

        // @DataMember(Order=4)
        public displayName?: string;

        // @DataMember(Order=5)
        public referrerUrl?: string;

        // @DataMember(Order=6)
        public bearerToken?: string;

        // @DataMember(Order=7)
        public refreshToken?: string;

        // @DataMember(Order=8)
        public profileUrl?: string;

        // @DataMember(Order=9)
        public roles?: string[];

        // @DataMember(Order=10)
        public permissions?: string[];

        // @DataMember(Order=11)
        public responseStatus?: ResponseStatus;

        // @DataMember(Order=12)
        public meta?: { [index: string]: string; };

        public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
    }

    // @Route("/assets/CustomImageGet", "GET")
    export class CustomImageGetRequest implements IReturn<CustomImageGetResponse>, IGet
    {
        public slug?: CustomImageSlug;

        public constructor(init?: Partial<CustomImageGetRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'CustomImageGetRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new CustomImageGetResponse(); }
    }

    // @Route("/find/completion", "GET")
    export class FindSearchCompletionRequest implements IReturn<FindSearchCompletionResponse>, IGet, IPost
    {
        public filter: QueryFilterDto;
        public location: QueryLocationDto;

        public constructor(init?: Partial<FindSearchCompletionRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindSearchCompletionRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindSearchCompletionResponse(); }
    }

    // @Route("/place/GetFollowingList", "GET")
    export class GetFollowingListRequest implements IReturn<GetFollowingListResponse>, IGet
    {

        public constructor(init?: Partial<GetFollowingListRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetFollowingListRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetFollowingListResponse(); }
    }

    // @Route("/place/Unfollow", "POST")
    export class UnfollowRequest implements IReturn<UnfollowResponse>, IPost
    {
        public placeId?: number;

        public constructor(init?: Partial<UnfollowRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UnfollowRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new UnfollowResponse(); }
    }

    // @Route("/place/PlacePoke", "POST")
    export class PlacePokeRequest implements IReturn<PlacePokeResponse>, IPost
    {
        public placeGuid?: string;

        public constructor(init?: Partial<PlacePokeRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'PlacePokeRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new PlacePokeResponse(); }
    }

    // @Route("/track/usage", "POST")
    export class TrackAppUsedRequest implements IReturn<TrackAppUsedResponse>, IPost
    {
        public openedAt?: string;
        public closedAt?: string;

        public constructor(init?: Partial<TrackAppUsedRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'TrackAppUsedRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new TrackAppUsedResponse(); }
    }

    // @Route("/track/admessage", "POST")
    export class TrackAppViewedAdMessageRequest implements IReturn<TrackAppViewedAdMessageResponse>, IPost
    {
        public adMessageId?: number;
        public openedAt?: string;
        public closedAt?: string;

        public constructor(init?: Partial<TrackAppViewedAdMessageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'TrackAppViewedAdMessageRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new TrackAppViewedAdMessageResponse(); }
    }

    // @Route("/track/place", "POST")
    export class TrackAppViewedPlaceRequest implements IReturn<TrackAppViewedPlaceResponse>, IPost
    {
        public placeId?: number;
        public openedAt?: string;
        public closedAt?: string;

        public constructor(init?: Partial<TrackAppViewedPlaceRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'TrackAppViewedPlaceRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new TrackAppViewedPlaceResponse(); }
    }

    // @Route("/vipps/login", "GET")
    export class VippsLoginRequest implements IReturn<VippsLoginResponse>, IGet
    {

        public constructor(init?: Partial<VippsLoginRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'VippsLoginRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new VippsLoginResponse(); }
    }

    // @Route("/vipps/login/callback", "GET")
    export class VippsLoginCallbackRequest implements IReturn<VippsLoginCallbackResponse>, IGet
    {
        public state: string;
        public code: string;
        public scope: string;
        public error: string;
        public errorDescription: string;
        public errorHint: string;

        public constructor(init?: Partial<VippsLoginCallbackRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'VippsLoginCallbackRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new VippsLoginCallbackResponse(); }
    }

    // @Route("/location/AddRecentLocation", "POST")
    export class AddRecentLocationRequest implements IReturn<AddRecentLocationResponse>, IPost
    {
        public name: string;
        public description?: string;
        public latitude?: number;
        public longitude?: number;
        public locationType?: RecentLocationType;
        public googleAddressComponents: string[];

        public constructor(init?: Partial<AddRecentLocationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'AddRecentLocationRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new AddRecentLocationResponse(); }
    }

    export class ConfirmEmailRequest implements IReturn<ConfirmEmailResponse>, IPost
    {
        public token: string;

        public constructor(init?: Partial<ConfirmEmailRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ConfirmEmailRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new ConfirmEmailResponse(); }
    }

    // @Route("/login/GetBusinessWebSignInLinkRequest", "GET")
    export class GetBusinessWebSignInLinkRequest implements IReturn<GetBusinessWebSignInLinkResponse>, IGet
    {
        public referBusinessToken?: string;
        public claimPlaceGuid?: string;

        public constructor(init?: Partial<GetBusinessWebSignInLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetBusinessWebSignInLinkRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetBusinessWebSignInLinkResponse(); }
    }

    // @Route("/location/GetRecentLocation", "GET")
    export class GetRecentLocationRequest implements IReturn<GetRecentLocationResponse>, IGet
    {

        public constructor(init?: Partial<GetRecentLocationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetRecentLocationRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetRecentLocationResponse(); }
    }

    // @Route("/profile", "GET")
    export class ProfileGetRequest implements IReturn<ProfileGetResponse>, IGet
    {

        public constructor(init?: Partial<ProfileGetRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileGetRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new ProfileGetResponse(); }
    }

    // @Route("/profile/personal-information", "PUT")
    export class ProfileUpdatePersonalInformationRequest implements IReturn<ProfileUpdatePersonalInformationResponse>, IPut
    {
        public firstName: string;
        public lastName: string;
        public dateOfBirth: DateOfBirth;
        public sex?: Sex;
        public showDistancesIn?: DistanceUnit;

        public constructor(init?: Partial<ProfileUpdatePersonalInformationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdatePersonalInformationRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdatePersonalInformationResponse(); }
    }

    // @Route("/profile/language", "PUT")
    export class ProfileUpdateLanguageRequest implements IReturn<ProfileUpdateLanguageResponse>, IPut
    {
        public locale?: Locale;

        public constructor(init?: Partial<ProfileUpdateLanguageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdateLanguageRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdateLanguageResponse(); }
    }

    // @Route("/profile/update-mobile-phone-start", "PUT")
    export class ProfileUpdateMobilePhoneSendVerificationSmsRequest implements IReturn<ProfileUpdateMobilePhoneSendVerificationSmsResponse>, IPut
    {
        public mobilePhone: string;

        public constructor(init?: Partial<ProfileUpdateMobilePhoneSendVerificationSmsRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdateMobilePhoneSendVerificationSmsRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdateMobilePhoneSendVerificationSmsResponse(); }
    }

    // @Route("/profile/update-mobile-phone-finish", "PUT")
    export class ProfileUpdateMobilePhoneRequest implements IReturn<ProfileUpdateMobilePhoneResponse>, IPut
    {
        public token: string;
        public shortCode: string;

        public constructor(init?: Partial<ProfileUpdateMobilePhoneRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdateMobilePhoneRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdateMobilePhoneResponse(); }
    }

    // @Route("/profile/update-email-start", "PUT")
    export class ProfileUpdateEmailSendVerificationLinkRequest implements IReturn<ProfileUpdateEmailSendVerificationLinkResponse>, IPut
    {
        // @Validate(Validator="NotEmpty")
        // @Validate(Validator="Email")
        public email: string;

        public constructor(init?: Partial<ProfileUpdateEmailSendVerificationLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdateEmailSendVerificationLinkRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdateEmailSendVerificationLinkResponse(); }
    }

    // @Route("/profile/update-home", "PUT")
    export class ProfileUpdateHomeLocationRequest implements IReturn<ProfileUpdateHomeLocationResponse>, IPut
    {
        public home: HomeLocation;

        public constructor(init?: Partial<ProfileUpdateHomeLocationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdateHomeLocationRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdateHomeLocationResponse(); }
    }

    // @Route("/profile/update-photo", "PUT")
    export class ProfileUpdatePhotoRequest implements IReturn<ProfileUpdatePhotoResponse>, IPut
    {
        public base64EncodedImage?: string;

        public constructor(init?: Partial<ProfileUpdatePhotoRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileUpdatePhotoRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileUpdatePhotoResponse(); }
    }

    // @Route("/profile/delete-photo", "PUT")
    export class ProfileDeletePhotoRequest implements IReturn<ProfileDeletePhotoResponse>, IPut
    {

        public constructor(init?: Partial<ProfileDeletePhotoRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileDeletePhotoRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileDeletePhotoResponse(); }
    }

    // @Route("/profile/change-password", "PUT")
    export class ProfileChangePasswordRequest implements IReturn<ProfileChangePasswordResponse>, IPut
    {
        public password: string;
        public passwordConfirmation: string;

        public constructor(init?: Partial<ProfileChangePasswordRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ProfileChangePasswordRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new ProfileChangePasswordResponse(); }
    }

    // @Route("/login/ResendConfirmEmail", "POST")
    export class ResendConfirmEmailRequest implements IReturn<ResendConfirmEmailResponse>, IPost
    {

        public constructor(init?: Partial<ResendConfirmEmailRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ResendConfirmEmailRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new ResendConfirmEmailResponse(); }
    }

    // @Route("/login/next_step", "POST")
    export class LoginNextStepRequest implements IReturn<LoginNextStepResponse>, IPost
    {
        public phoneNumber: string;

        public constructor(init?: Partial<LoginNextStepRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LoginNextStepRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new LoginNextStepResponse(); }
    }

    // @Route("/user/status", "GET")
    export class UserStatusRequest implements IReturn<UserStatusResponse>, IGet
    {
        // @Validate(Validator="NotNull")
        public fromPwa: boolean;

        public constructor(init?: Partial<UserStatusRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UserStatusRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new UserStatusResponse(); }
    }

    // @Route("/user/complete_profile", "PUT")
    export class CompleteProfileRequest implements IReturn<CompleteProfileResponse>, IPut
    {
        // @Validate(Validator="NotNull")
        public sex: Sex;

        // @Validate(Validator="NotEmpty")
        public acceptTermsOfService: boolean;

        public constructor(init?: Partial<CompleteProfileRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'CompleteProfileRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new CompleteProfileResponse(); }
    }

    // @Route("/login/reset_password", "POST")
    export class EmailPasswordResetAndLoginRequest implements IReturn<EmailPasswordResetAndLoginResponse>, IPost
    {
        public token: string;
        public password: string;
        public passwordConfirmation: string;

        public constructor(init?: Partial<EmailPasswordResetAndLoginRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'EmailPasswordResetAndLoginRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new EmailPasswordResetAndLoginResponse(); }
    }

    // @Route("/login/send_sms_for_password_reset", "POST")
    export class SendSmsForPasswordResetRequest implements IReturn<SendSmsForPasswordResetResponse>, IPost
    {
        public phoneNumber: string;

        public constructor(init?: Partial<SendSmsForPasswordResetRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SendSmsForPasswordResetRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new SendSmsForPasswordResetResponse(); }
    }

    // @Route("/login/sms_password_reset", "POST")
    export class SmsPasswordResetAndLoginRequest implements IReturn<SmsPasswordResetAndLoginResponse>, IPost
    {
        public token: string;
        public shortCode: string;
        public password: string;
        public passwordConfirmation: string;

        public constructor(init?: Partial<SmsPasswordResetAndLoginRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SmsPasswordResetAndLoginRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new SmsPasswordResetAndLoginResponse(); }
    }

    // @Route("/signup/send_sms_for_verification", "POST")
    export class SendSmsForVerificationRequest implements IReturn<SendSmsForVerificationResponse>, IPost
    {
        public phoneNumber: string;
        public locale?: Locale;

        public constructor(init?: Partial<SendSmsForVerificationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SendSmsForVerificationRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new SendSmsForVerificationResponse(); }
    }

    // @Route("/signup/sms_verification", "POST")
    export class SmsVerificationRequest implements IReturn<SmsVerificationResponse>, IPost
    {
        public shortCode: string;
        public token: string;

        public constructor(init?: Partial<SmsVerificationRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SmsVerificationRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new SmsVerificationResponse(); }
    }

    // @Route("/signup/create_user", "POST")
    export class UserCreateRequest implements IReturn<UserCreateResponse>, IPost
    {
        public token: string;
        public firstName: string;
        public lastName: string;
        public sex?: Sex;
        public dateOfBirth: DateOfBirth;
        public email: string;
        public password: string;
        public passwordConfirmation: string;
        public home: HomeLocation;
        public acceptTermsOfService?: boolean;
        public referFriendToken?: string;

        public constructor(init?: Partial<UserCreateRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UserCreateRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new UserCreateResponse(); }
    }

    // @Route("/user/delete", "POST")
    export class UserDeleteRequest implements IReturn<UserDeleteResponse>, IPost
    {
        public confirmedByUser?: boolean;

        public constructor(init?: Partial<UserDeleteRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UserDeleteRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new UserDeleteResponse(); }
    }

    // @Route("/user/rewards", "GET")
    export class GetRewardsStatusRequest implements IReturn<GetRewardsStatusResponse>, IGet
    {

        public constructor(init?: Partial<GetRewardsStatusRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetRewardsStatusRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetRewardsStatusResponse(); }
    }

    // @Route("/lottery", "GET")
    export class LotteryGetRequest implements IReturn<LotteryGetResponse>, IGet
    {

        public constructor(init?: Partial<LotteryGetRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LotteryGetRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new LotteryGetResponse(); }
    }

    // @Route("/lottery/points", "GET")
    export class LotteryAvailabePointsRequest implements IReturn<LotteryAvailabePointsResponse>, IGet
    {

        public constructor(init?: Partial<LotteryAvailabePointsRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LotteryAvailabePointsRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new LotteryAvailabePointsResponse(); }
    }

    // @Route("/lottery/points", "POST")
    export class LotteryAcquireTicketsRequest implements IReturn<LotteryAcquireTicketsResponse>, IPost
    {
        public pointsToConvert?: number;

        public constructor(init?: Partial<LotteryAcquireTicketsRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LotteryAcquireTicketsRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new LotteryAcquireTicketsResponse(); }
    }

    // @Route("/user/android-install-prompt-outcome", "POST")
    export class AndroidPwaInstallPromptOutcomeRequest implements IReturn<AndroidPwaInstallPromptOutcomeResponse>, IPost
    {
        // @Validate(Validator="NotNull")
        public outcome: AndroidPwaInstallPromptOutcome;

        public constructor(init?: Partial<AndroidPwaInstallPromptOutcomeRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'AndroidPwaInstallPromptOutcomeRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new AndroidPwaInstallPromptOutcomeResponse(); }
    }

    // @Route("/admessage/GetStarredAdMessageList", "GET")
    export class GetStarredAdMessageListRequest implements IReturn<GetStarredAdMessageListResponse>, IGet
    {

        public constructor(init?: Partial<GetStarredAdMessageListRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetStarredAdMessageListRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetStarredAdMessageListResponse(); }
    }

    // @Route("/admessage/StarredAdMessage", "POST")
    export class StarredAdMessageRequest implements IReturn<StarredAdMessageResponse>, IPost
    {
        public adMessageId?: number;

        public constructor(init?: Partial<StarredAdMessageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'StarredAdMessageRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new StarredAdMessageResponse(); }
    }

    // @Route("/admessage/UnstarAdMessage", "DELETE")
    export class UnstarAdMessageRequest implements IReturn<UnstarAdMessageResponse>, IDelete
    {
        public adMessageId?: number;

        public constructor(init?: Partial<UnstarAdMessageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UnstarAdMessageRequest'; }
        public getMethod() { return 'DELETE'; }
        public createResponse() { return new UnstarAdMessageResponse(); }
    }

    // @Route("/searches/AddSavedSearch", "POST")
    export class AddSavedSearchRequest implements IReturn<AddSavedSearchResponse>, IPost
    {
        public name: string;
        public filter: QueryFilterDto;
        public location: QueryLocationDto;

        public constructor(init?: Partial<AddSavedSearchRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'AddSavedSearchRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new AddSavedSearchResponse(); }
    }

    // @Route("/searches/DeleteSavedSearch", "DELETE")
    export class DeleteSavedSearchRequest implements IReturn<DeleteSavedSearchResponse>, IDelete
    {
        public id?: number;

        public constructor(init?: Partial<DeleteSavedSearchRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'DeleteSavedSearchRequest'; }
        public getMethod() { return 'DELETE'; }
        public createResponse() { return new DeleteSavedSearchResponse(); }
    }

    // @Route("/searches/GetSavedSearches", "GET")
    export class GetSavedSearchesRequest implements IReturn<GetSavedSearchesResponse>, IGet
    {

        public constructor(init?: Partial<GetSavedSearchesRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetSavedSearchesRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetSavedSearchesResponse(); }
    }

    // @Route("/searches/UpdateSavedSearch", "PUT")
    export class UpdateSavedSearchRequest implements IReturn<UpdateSavedSearchResponse>, IPut
    {
        public id?: number;
        public name: string;
        public filter: QueryFilterDto;
        public location: QueryLocationDto;

        public constructor(init?: Partial<UpdateSavedSearchRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UpdateSavedSearchRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new UpdateSavedSearchResponse(); }
    }

    // @Route("/place/GetPlace", "GET")
    export class GetPlaceRequest implements IReturn<GetPlaceResponse>, IGet, ICacheable
    {
        public placeGuid?: string;
        public cacheVersion?: number;

        public constructor(init?: Partial<GetPlaceRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetPlaceRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetPlaceResponse(); }
    }

    export class NewsletterSignupRequest implements IReturn<NewsletterSignupResponse>, IPost
    {
        public firstName: string;
        public lastName: string;
        public email: string;
        public areaOfInterest?: NewsletterAreaOfInterest;
        public phone?: string;

        public constructor(init?: Partial<NewsletterSignupRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'NewsletterSignupRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new NewsletterSignupResponse(); }
    }

    // @Route("/news", "GET")
    export class GetNewsRequest implements IReturn<GetNewsResponse>, IGet
    {

        public constructor(init?: Partial<GetNewsRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetNewsRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetNewsResponse(); }
    }

    // @Route("/menu", "GET")
    export class GetAppMenuDataRequest implements IReturn<GetAppMenuDataResponse>, IGet
    {

        public constructor(init?: Partial<GetAppMenuDataRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetAppMenuDataRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetAppMenuDataResponse(); }
    }

    // @Route("/place/Follow", "POST")
    export class FollowRequest implements IReturn<FollowResponse>, IPost
    {
        public placeId?: number;

        public constructor(init?: Partial<FollowRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FollowRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new FollowResponse(); }
    }

    // @Route("/find/filtering", "GET")
    export class FindAvailableFilterRequest implements IReturn<FindAvailableFilterResponse>, IGet, IPost, ICacheable
    {
        public filter: QueryFilterDto;
        public location: QueryLocationDto;
        public cacheVersion?: number;

        public constructor(init?: Partial<FindAvailableFilterRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindAvailableFilterRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindAvailableFilterResponse(); }
    }

    export class FindAdMessageRequest implements IReturn<FindAdMessageResponse>, IGet, IPost
    {
        public offset?: number;
        public size?: number;
        public resultAt?: string;
        public filter?: QueryFilterDto;
        public location: QueryLocationDto;
        public cacheVersion?: number;

        public constructor(init?: Partial<FindAdMessageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindAdMessageRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindAdMessageResponse(); }
    }

    export class FindBoundedMapPlacesRequest implements IReturn<FindBoundedMapPlacesResponse>, IGet, IPost, ICacheable
    {
        public cacheVersion?: number;
        public filter: QueryFilterDto;
        public bounds: MapBoundsDto;

        public constructor(init?: Partial<FindBoundedMapPlacesRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindBoundedMapPlacesRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindBoundedMapPlacesResponse(); }
    }

    export class FindBoundedMapAdMessagesRequest implements IReturn<FindBoundedMapAdMessagesResponse>, IGet, IPost, ICacheable
    {
        public cacheVersion?: number;
        public filter?: QueryFilterDto;
        public bounds: MapBoundsDto;

        public constructor(init?: Partial<FindBoundedMapAdMessagesRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindBoundedMapAdMessagesRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindBoundedMapAdMessagesResponse(); }
    }

    // @Route("/find/place", "GET")
    export class FindPlaceRequest implements IReturn<FindPlaceResponse>, IGet, IPost
    {
        public offset?: number;
        public size?: number;
        public resultAt?: string;
        public filter?: QueryFilterDto;
        public location: QueryLocationDto;
        public cacheVersion?: number;

        public constructor(init?: Partial<FindPlaceRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindPlaceRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindPlaceResponse(); }
    }

    // @Route("/find", "GET")
    export class FindRequest implements IReturn<FindResponse>, IGet, IPost, ICacheable
    {
        public offset?: number;
        public size?: number;
        public resultAt?: string;
        public filter?: QueryFilterDto;
        public location: QueryLocationDto;
        public cacheVersion?: number;

        public constructor(init?: Partial<FindRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'FindRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new FindResponse(); }
    }

    // @Route("/admessage/drafts", "GET")
    export class GetAdMessageDraftsRequest implements IReturn<GetAdMessageDraftsResponse>, IGet
    {

        public constructor(init?: Partial<GetAdMessageDraftsRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetAdMessageDraftsRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetAdMessageDraftsResponse(); }
    }

    // @Route("/admessage/{AdMessageGuid}", "GET")
    export class GetAdMessageRequest implements IReturn<GetAdMessageResponse>, IGet, ICacheable
    {
        public adMessageGuid?: string;
        public cacheVersion?: number;

        public constructor(init?: Partial<GetAdMessageRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetAdMessageRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new GetAdMessageResponse(); }
    }

    // @Route("/log/test", "POST")
    export class TestLogRequest implements IReturn<TestLogResponse>, IPost
    {

        public constructor(init?: Partial<TestLogRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'TestLogRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new TestLogResponse(); }
    }

    // @Route("/log/error", "POST")
    export class LogFrontEndErrorRequest implements IReturn<LogFrontEndErrorResponse>, IPost
    {
        public location: string;
        public message: string;
        public stackTrace: string;

        public constructor(init?: Partial<LogFrontEndErrorRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LogFrontEndErrorRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new LogFrontEndErrorResponse(); }
    }

    // @Route("/log/event", "POST")
    export class LogFrontEndEventRequest implements IReturn<LogFrontEndEventResponse>, IPost
    {
        // @Validate(Validator="NotEmpty")
        // @Validate(Validator="MaximumLength(256)")
        public location: string;

        // @Validate(Validator="NotEmpty")
        // @Validate(Validator="MaximumLength(128)")
        public tag: string;

        // @Validate(Validator="NotEmpty")
        // @Validate(Validator="MaximumLength(512)")
        public message: string;

        public constructor(init?: Partial<LogFrontEndEventRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'LogFrontEndEventRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new LogFrontEndEventResponse(); }
    }

    // @Route("/login/get_refresh_token", "POST")
    export class GetRefreshTokenRequest implements IReturn<GetRefreshTokenResponse>, IPost
    {

        public constructor(init?: Partial<GetRefreshTokenRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'GetRefreshTokenRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new GetRefreshTokenResponse(); }
    }

    // @Route("/login/use-refresh-token", "POST")
    export class UseRefreshTokenRequest implements IReturn<UseRefreshTokenResponse>, IPost
    {
        public refreshToken: string;

        public constructor(init?: Partial<UseRefreshTokenRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'UseRefreshTokenRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new UseRefreshTokenResponse(); }
    }

    // @Route("/signup/send-verification-email", "PUT")
    export class EmailSendVerificationCodeRequest implements IReturn<EmailSendVerificationCodeResponse>, IPut
    {
        // @Validate(Validator="NotEmpty")
        // @Validate(Validator="Email")
        public email: string;

        public constructor(init?: Partial<EmailSendVerificationCodeRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'EmailSendVerificationCodeRequest'; }
        public getMethod() { return 'PUT'; }
        public createResponse() { return new EmailSendVerificationCodeResponse(); }
    }

    // @Route("/signup/resend-send-verification-email", "POST")
    export class EmailResendVerificationCodeRequest implements IReturn<EmailResendVerificationCodeResponse>, IPost
    {

        public constructor(init?: Partial<EmailResendVerificationCodeRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'EmailResendVerificationCodeRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new EmailResendVerificationCodeResponse(); }
    }

    // @Route("/signup/verify-email", "POST")
    export class EmailVerificationWithCodeRequest implements IReturn<EmailVerificationWithCodeResponse>, IPost
    {
        public shortCode: string;
        public token: string;

        public constructor(init?: Partial<EmailVerificationWithCodeRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'EmailVerificationWithCodeRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new EmailVerificationWithCodeResponse(); }
    }

    // @Route("/status", "GET")
    export class StatusRequest implements IReturn<StatusResponse>, IGet
    {

        public constructor(init?: Partial<StatusRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'StatusRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new StatusResponse(); }
    }

    // @Route("/share/refer-friend-link", "GET")
    export class ReferFriendLinkRequest implements IReturn<ReferFriendLinkResponse>, IGet
    {

        public constructor(init?: Partial<ReferFriendLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ReferFriendLinkRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new ReferFriendLinkResponse(); }
    }

    // @Route("/share/refer-friend-link-used", "POST")
    export class ReferFriendLinkUsedRequest implements IReturn<ReferFriendLinkUsedResponse>, IPost
    {
        public referFriendToken: string;

        public constructor(init?: Partial<ReferFriendLinkUsedRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ReferFriendLinkUsedRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new ReferFriendLinkUsedResponse(); }
    }

    // @Route("/share/refer-business-link", "GET")
    export class ReferBusinessLinkRequest implements IReturn<ReferBusinessLinkResponse>, IGet
    {
        // @Validate(Validator="NotNull")
        public placeId: number;

        public constructor(init?: Partial<ReferBusinessLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ReferBusinessLinkRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new ReferBusinessLinkResponse(); }
    }

    // @Route("/share/refer-business-link-used", "POST")
    export class ReferBusinessLinkUsedRequest implements IReturn<ReferBusinessLinkUsedResponse>, IPost
    {
        public referBusinessToken: string;

        public constructor(init?: Partial<ReferBusinessLinkUsedRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ReferBusinessLinkUsedRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new ReferBusinessLinkUsedResponse(); }
    }

    // @Route("/share/share-place-link", "GET")
    export class SharePlaceLinkRequest implements IReturn<SharePlaceLinkResponse>, IGet
    {
        // @Validate(Validator="NotNull")
        public placeId: number;

        public constructor(init?: Partial<SharePlaceLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SharePlaceLinkRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new SharePlaceLinkResponse(); }
    }

    // @Route("/share/share-place-link-used", "POST")
    export class SharePlaceLinkUsedRequest implements IReturn<SharePlaceLinkUsedResponse>, IPost
    {
        public sharePlaceToken: string;
        public locale?: Locale;

        public constructor(init?: Partial<SharePlaceLinkUsedRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'SharePlaceLinkUsedRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new SharePlaceLinkUsedResponse(); }
    }

    // @Route("/share/share-ad-message-link", "GET")
    export class ShareAdMessageLinkRequest implements IReturn<ShareAdMessageLinkResponse>, IGet
    {
        // @Validate(Validator="NotNull")
        public adMessageId: number;

        public constructor(init?: Partial<ShareAdMessageLinkRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ShareAdMessageLinkRequest'; }
        public getMethod() { return 'GET'; }
        public createResponse() { return new ShareAdMessageLinkResponse(); }
    }

    // @Route("/share/share-ad-message-link-used", "POST")
    export class ShareAdMessageLinkUsedRequest implements IReturn<ShareAdMessageLinkUsedResponse>, IPost
    {
        public shareAdMessageToken: string;
        public locale?: Locale;

        public constructor(init?: Partial<ShareAdMessageLinkUsedRequest>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'ShareAdMessageLinkUsedRequest'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new ShareAdMessageLinkUsedResponse(); }
    }

    /** @description Sign In */
    // @Route("/auth", "OPTIONS,GET,POST,DELETE")
    // @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
    // @Api(Description="Sign In")
    // @DataContract
    export class Authenticate implements IReturn<AuthenticateResponse>, IPost
    {
        /** @description AuthProvider, e.g. credentials */
        // @DataMember(Order=1)
        public provider?: string;

        // @DataMember(Order=2)
        public state?: string;

        // @DataMember(Order=3)
        public oauth_token?: string;

        // @DataMember(Order=4)
        public oauth_verifier?: string;

        // @DataMember(Order=5)
        public userName?: string;

        // @DataMember(Order=6)
        public password?: string;

        // @DataMember(Order=7)
        public rememberMe?: boolean;

        // @DataMember(Order=9)
        public errorView?: string;

        // @DataMember(Order=10)
        public nonce?: string;

        // @DataMember(Order=11)
        public uri?: string;

        // @DataMember(Order=12)
        public response?: string;

        // @DataMember(Order=13)
        public qop?: string;

        // @DataMember(Order=14)
        public nc?: string;

        // @DataMember(Order=15)
        public cnonce?: string;

        // @DataMember(Order=17)
        public accessToken?: string;

        // @DataMember(Order=18)
        public accessTokenSecret?: string;

        // @DataMember(Order=19)
        public scope?: string;

        // @DataMember(Order=20)
        public returnUrl?: string;

        // @DataMember(Order=21)
        public meta?: { [index: string]: string; };

        public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
        public getTypeName() { return 'Authenticate'; }
        public getMethod() { return 'POST'; }
        public createResponse() { return new AuthenticateResponse(); }
    }

}

