import {
    apiServiceState,
    loadingState,
    referBusinessTokenState,
    referFriendTokenState,
    shareAdMessageTokenState,
    sharePlaceTokenState
} from '../atoms';
import {ConsumerApi} from '../ConsumerApi.dto';
import React, {useState, useEffect, Consumer, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useRecoilValue} from 'recoil';
import {Button} from '../button';
import {Header} from '../header';
import {VippsButton} from '../vipps-button';
import {ContinueWithPhone} from './ContinueWithPhone';
import {DateTime} from 'luxon';
import * as i18n from '../i18n';
import {ShareAndReferTeaser, ShareAndReferTeaserExpired} from './ShareAndReferTeaser';
import {Place} from '../place';
import {AdMessage} from '../ad-message';
import {Link} from 'react-router-dom';
import logo from '../images/logo.svg';


export const Welcome: React.FC = () => {

    const {t} = useTranslation();
    const [continueWithPhone, setContinueWithPhone] = useState(false);
    const setLoading = useSetRecoilState(loadingState);

    const apiService = useRecoilValue(apiServiceState);

    const [invitationDaysLeft, setInvitationDaysLeft] = useState<number>();

    const [referFriendToken, setReferFriendToken] = useRecoilState(referFriendTokenState);
    const [referFriendLink, setReferFriendLink] = useState<ConsumerApi.ReferFriendLinkUsedResponse>();

    const [referBusinessToken, setReferBusinessToken] = useRecoilState(referBusinessTokenState);
    const [referBusinessLink, setReferBusinessLink] = useState<ConsumerApi.ReferBusinessLinkUsedResponse>();

    const [sharePlaceToken, setSharePlaceToken] = useRecoilState(sharePlaceTokenState);
    const [sharePlaceLink, setSharePlaceLink] = useState<ConsumerApi.SharePlaceLinkUsedResponse>();

    const [shareAdMessageToken, setShareAdMessageToken] = useRecoilState(shareAdMessageTokenState);
    const [shareAdMessageLink, setShareAdMessageLink] = useState<ConsumerApi.ShareAdMessageLinkUsedResponse>();

    useEffect(() => {
        if (referFriendToken) {
            setLoading(true);
            apiService.post(new ConsumerApi.ReferFriendLinkUsedRequest({
                referFriendToken: referFriendToken
            }))
                .then((res) => {
                    setReferFriendLink(res);
                    switch (res.state) {
                        case ConsumerApi.ReferFriendState.Expired:
                            setReferFriendToken(undefined);
                            break;
                        case ConsumerApi.ReferFriendState.Available:
                            setInvitationDaysLeft(Math.ceil(DateTime.fromISO(res.expiresAt as string).diffNow(["days"]).days));
                            break;
                        case ConsumerApi.ReferFriendState.Awarded:
                        case ConsumerApi.ReferFriendState.CanNotReferYourself:
                        case ConsumerApi.ReferFriendState.YouWereAlreadySignedUp:
                        default:
                        // Ignore. Logically impossible.
                    }
                })
                .finally(() => setLoading(false));
        } else if (referBusinessToken) {
            setLoading(true);
            apiService.post(new ConsumerApi.ReferBusinessLinkUsedRequest({
                referBusinessToken: referBusinessToken,
            }))
                .then((res) => {
                    setReferBusinessLink(res);
                    switch (res.state) {
                        case ConsumerApi.ReferBusinessState.Expired:
                            setReferBusinessToken(undefined);
                            break;
                        case ConsumerApi.ReferBusinessState.SignInToSee:
                            setInvitationDaysLeft(Math.ceil(DateTime.fromISO(res.expiresAt as string).diffNow(["days"]).days));
                            break;
                        case ConsumerApi.ReferBusinessState.Available:
                        case ConsumerApi.ReferBusinessState.CanNotReferYourself:
                        case ConsumerApi.ReferBusinessState.AlreadyClaimedByYou:
                        case ConsumerApi.ReferBusinessState.AlreadyClaimedBySomeoneElse:
                        default:
                        // Ignore. Logically impossible.
                    }
                })
                .finally(() => setLoading(false));
        } else if (sharePlaceToken) {
            setLoading(true);
            apiService.post(new ConsumerApi.SharePlaceLinkUsedRequest({
                sharePlaceToken: sharePlaceToken,
                locale: i18n.getDefault()
            }))
                .then((res) => {
                    setSharePlaceLink(res);
                    switch (res.state) {
                        case ConsumerApi.SharingState.Expired:
                            setSharePlaceToken(undefined);
                            break;
                        case ConsumerApi.SharingState.Teaser:
                            setInvitationDaysLeft(Math.ceil(DateTime.fromISO(res.expiresAt as string).diffNow(["days"]).days));
                            break;
                        case ConsumerApi.SharingState.Awarded:
                        case ConsumerApi.SharingState.SelfShare:
                        default:
                        // Ignore. Logically impossible.
                    }
                })
                .finally(() => setLoading(false));
        } else if (shareAdMessageToken) {
            setLoading(true);
            apiService.post(new ConsumerApi.ShareAdMessageLinkUsedRequest({
                shareAdMessageToken: shareAdMessageToken,
                locale: i18n.getDefault()
            }))
                .then((res) => {
                    setShareAdMessageLink(res);
                    switch (res.state) {
                        case ConsumerApi.SharingState.Expired:
                            setShareAdMessageToken(undefined);
                            break;
                        case ConsumerApi.SharingState.Teaser:
                            setInvitationDaysLeft(Math.ceil(DateTime.fromISO(res.expiresAt as string).diffNow(["days"]).days));
                            break;
                        case ConsumerApi.SharingState.Awarded:
                        case ConsumerApi.SharingState.SelfShare:
                        default:
                        // Ignore. Logically impossible.
                    }
                })
                .finally(() => setLoading(false));
        }

    }, [apiService, referBusinessToken, referFriendToken, setLoading, setReferBusinessToken, setReferFriendToken, setShareAdMessageToken, setSharePlaceToken, shareAdMessageToken, sharePlaceToken])

    const place = useMemo(() => sharePlaceLink && sharePlaceLink.place ?
        convertSharedPlaceToPlaceDto(sharePlaceLink.place) : undefined, [sharePlaceLink]);

    const adMessage = useMemo(() =>
            shareAdMessageLink && shareAdMessageLink.adMessage && shareAdMessageLink.place ?
                convertSharedAdMessageToAdMessageDto(shareAdMessageLink.adMessage, shareAdMessageLink.place)
                : undefined
        , [shareAdMessageLink]);

    return (
        <>
            <div
                className="h-full overflow-y-scroll pb-[64px] pt-[64px]"
                style={{
                    backgroundImage: `url(${require("../images/welcome-background.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'rgba(0,0,0,.5)',
                    backgroundBlendMode: 'multiply'
                }}>
                <div className="w-full mx-auto max-w-md px-15px">
                    <div className="text-white">
                        {referFriendLink &&
                        (referFriendLink.state === ConsumerApi.ReferFriendState.Available ||
                            referFriendLink.state === ConsumerApi.ReferFriendState.Expired) ?

                            referFriendLink.state === ConsumerApi.ReferFriendState.Expired ?
                                <ShareAndReferTeaserExpired
                                    firstName={referFriendLink.referringUser?.firstName}
                                    lastName={referFriendLink.referringUser?.lastName}
                                />
                                :
                                <ShareAndReferTeaser
                                    firstName={referFriendLink.referringUser?.firstName}
                                    lastName={referFriendLink.referringUser?.lastName}
                                    profilePhotoUrl={referFriendLink.referringUser?.profilePhotoUrl}
                                    invitationMessage={<span>{t("invites you to use this App.")}</span>}
                                    bodyMessage={
                                        <Trans>
                                            Sign up NOW and the both of you earn points to WIN our lottery.<br/><br/>
                                            The invite expires in {{invitationDaysLeft}} days.
                                        </Trans>
                                    }/>

                            : referBusinessLink &&
                            (referBusinessLink.state === ConsumerApi.ReferBusinessState.SignInToSee ||
                                referBusinessLink.state === ConsumerApi.ReferBusinessState.Expired) ?

                                referBusinessLink.state === ConsumerApi.ReferBusinessState.Expired ?
                                    <ShareAndReferTeaserExpired
                                        firstName={referBusinessLink.referringUser?.firstName}
                                        lastName={referBusinessLink.referringUser?.lastName}
                                    />
                                    :
                                    <ShareAndReferTeaser
                                        firstName={referBusinessLink.referringUser?.firstName}
                                        lastName={referBusinessLink.referringUser?.lastName}
                                        profilePhotoUrl={referBusinessLink.referringUser?.profilePhotoUrl}
                                        invitationMessage={
                                            <span>
                                              {t("invites you to manage your business {{businessName}}.",
                                                  {businessName: referBusinessLink.referredPlace?.name})
                                              }
                                            </span>
                                        }
                                        bodyMessage={
                                            <Trans>
                                                Sign up NOW to claim and manage your business and the both of you earn
                                                points that can be used in our lottery.<br/><br/>
                                                The invite expires in {{invitationDaysLeft}} days.
                                            </Trans>
                                        }/>
                                : sharePlaceLink &&
                                (sharePlaceLink.state === ConsumerApi.SharingState.Teaser ||
                                    sharePlaceLink.state === ConsumerApi.SharingState.Expired) ?

                                    sharePlaceLink.state === ConsumerApi.SharingState.Expired ?
                                        <ShareAndReferTeaserExpired
                                            firstName={sharePlaceLink.sharingUser?.firstName}
                                            lastName={sharePlaceLink.sharingUser?.lastName}
                                        />
                                        : place ?
                                            <ShareAndReferTeaser
                                                firstName={sharePlaceLink.sharingUser?.firstName}
                                                lastName={sharePlaceLink.sharingUser?.lastName}
                                                previewImageUrl={sharePlaceLink.place?.imageUrl}
                                                profilePhotoUrl={sharePlaceLink.sharingUser?.profilePhotoUrl}
                                                preview={
                                                    <div className="border-t border-gray-light w-full">
                                                        <Place place={place} teaser={true}/>
                                                    </div>
                                                }
                                                invitationMessage={
                                                    <span>
                                                        {t("wants to share with you")}:
                                                    </span>
                                                }
                                                bodyMessage={
                                                    <Trans>
                                                        Sign up NOW to see it and the both of you
                                                        earn points that can be used in our lottery.<br/><br/>
                                                        The invite expires in {{invitationDaysLeft}} days.
                                                    </Trans>
                                                }/>
                                            : null
                                    : shareAdMessageLink &&
                                    (shareAdMessageLink.state === ConsumerApi.SharingState.Teaser ||
                                        shareAdMessageLink.state === ConsumerApi.SharingState.Expired) ?

                                        shareAdMessageLink.state === ConsumerApi.SharingState.Expired ?
                                            <ShareAndReferTeaserExpired
                                                firstName={shareAdMessageLink.user?.firstName}
                                                lastName={shareAdMessageLink.user?.lastName}
                                            />
                                            : adMessage ?
                                                <ShareAndReferTeaser
                                                    firstName={shareAdMessageLink.user?.firstName}
                                                    lastName={shareAdMessageLink.user?.lastName}
                                                    profilePhotoUrl={shareAdMessageLink.user?.profilePhotoUrl}
                                                    previewImageUrl={shareAdMessageLink.adMessage?.imageUrl || shareAdMessageLink.place?.imageUrl}
                                                    preview={
                                                        <div className="border-t border-b border-gray-light w-full">
                                                            <AdMessage adMessage={adMessage} type="teaser" />
                                                        </div>
                                                    }
                                                    invitationMessage={
                                                        <span>
                                                          {t("wants to share with you")}:
                                                        </span>
                                                    }
                                                    bodyMessage={
                                                        <Trans>
                                                            Sign up NOW to view the AD Message.<br/><br/>
                                                            You and the person inviting you will both earn Reward Points
                                                            that can be used in our lottery.<br/><br/>
                                                            The invite expires in {{invitationDaysLeft}} days.
                                                        </Trans>
                                                    }/>
                                                : null
                                        :
                                        <>
                                            <div className="flex justify-center">
                                                <img src={logo} alt="logo"/>
                                            </div>
                                            <div
                                                className="font-bold text-center w-full px-[10px] mt-[32px] text-h6">
                                                <Trans>
                                                    Browse ads, products and<br/>
                                                    services at local businesses
                                                </Trans>
                                            </div>
                                            <div className="font-bold text-center w-full mt-[72px] text-h6">
                                                {t("Fashion")}
                                            </div>
                                            <div className="font-bold text-center w-full mt-[16px] text-h6">
                                                {t("Food & Drink")}
                                            </div>
                                            <div className="font-bold text-center w-full mt-[16px] text-h6">
                                                {t("Shopping Malls")}
                                            </div>
                                            <div
                                                className="font-bold text-center w-full mt-[72px] mb-[24px] text-h7">
                                                {t("Easy and FREE Sign In")}
                                            </div>
                                        </>
                        }
                    </div>
                    {!continueWithPhone ?
                        <>
                            <VippsButton/>
                            <div className="flex justify-center items-center my-[24px]">
                                <div
                                    className="px-[12px] text-body-bold flex font-bold justify-center text-white">{t("OR")}</div>
                            </div>
                            <div onClick={() => setContinueWithPhone(true)}
                                 className="font-bold text-center w-full text-title-bold text-white cursor-pointer">
                                {t("Continue with Mobile Number")}
                            </div>
                        </>
                        :
                        <ContinueWithPhone
                            onBack={() => setContinueWithPhone(false)}/>
                    }
                </div>
            </div>
        </>
    )
}

const convertSharedPlaceToPlaceDto = (sharedPlace: ConsumerApi.SharedPlace): ConsumerApi.PlaceDto => {
    return new ConsumerApi.PlaceDto({
        name: sharedPlace.name,
        guid: sharedPlace.guid,
        id: sharedPlace.id,
        imageUrl: new ConsumerApi.ImageUrls({
            preview: sharedPlace.imageUrl,
            small: sharedPlace.imageUrl,
            medium: sharedPlace.imageUrl,
            large: sharedPlace.imageUrl
        }),
        logoUrl: sharedPlace.logoUrl ? new ConsumerApi.ImageUrls({
            preview: sharedPlace.logoUrl,
            small: sharedPlace.logoUrl,
            medium: sharedPlace.logoUrl,
            large: sharedPlace.logoUrl
        }) : undefined,
        categories: sharedPlace.rootCategories.map(c => new ConsumerApi.CategoryDto({
            name: c.name,
            absoluteSlug: c.absoluteSlug
        })),
        wearPriceProfiles: sharedPlace.wearPriceProfiles?.map(wp => new ConsumerApi.WearPriceProfileDto({
            slug: wp.slug,
            label: wp.label
        })),
        priceMainDish: sharedPlace.priceMainDish ? new ConsumerApi.PriceMainDishDto({
            title: sharedPlace.priceMainDish.label,
            slug: sharedPlace.priceMainDish.slug,
        }) : undefined,
        openingHours: sharedPlace.openingHours,
        timeZoneDto: sharedPlace.timeZoneDto
    })
}

const convertSharedAdMessageToAdMessageDto = (sharedAdMessage: ConsumerApi.SharedAdMessage, sharedPlace: ConsumerApi.SharedPlace): ConsumerApi.AdMessageDto => {

    return new ConsumerApi.AdMessageDto({
        guid: sharedAdMessage.guid,
        id: sharedAdMessage.id,
        startAt: sharedAdMessage.startAt,
        stopAt: sharedAdMessage.stopAt,
        detailsUnion: new ConsumerApi.AdMessageDetailsUnion({
            freetext: new ConsumerApi.AdMessageDetailsFreetext({
                title: sharedAdMessage.title,
            })
        }),
        imageUrls: [new ConsumerApi.ImageUrls({
            preview: sharedAdMessage.imageUrl,
            small: sharedAdMessage.imageUrl,
            medium: sharedAdMessage.imageUrl,
            large: sharedAdMessage.imageUrl
        })],
        place: convertSharedPlaceToPlaceDto(sharedPlace)
    })
}