import { referFriendLinkUsedState } from "./referFriendLinkUsed";
import { atom, selector } from "recoil";

const localStorageKey = "referFriendToken";

const _referFriendTokenState = atom<string | undefined>({
  key: '_referFriendTokenState',
  default: localStorage.getItem(localStorageKey) ?? undefined
});

export const referFriendTokenState = selector<string | undefined>({
  key: 'referFriendTokenState',
  get: ({ get }) => get(_referFriendTokenState),
  set: ({ set }, token) => {
    if (token) {
      localStorage.setItem(localStorageKey, token as string)
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_referFriendTokenState, token);
    if (!token) {
      set(referFriendLinkUsedState, undefined);
    }
  }
});