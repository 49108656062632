import React from "react";

export const Diners = () => {
  return (
    <svg width="52" height="37" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66667 0.3125H49.3333C50.8061 0.3125 52 1.6221 52 3.2375V33.95C52 35.5655 50.8061 36.875 49.3333 36.875H2.66667C1.19393 36.875 0 35.5655 0 33.95V3.2375C0 1.6221 1.19393 0.3125 2.66667 0.3125Z" fill="#0079BE"/>
      <path d="M39.9953 19.0967C39.9953 12.469 34.4633 7.888 28.402 7.89H23.1859C17.0523 7.8878 12.0039 12.4703 12.0039 19.0967C12.0039 25.1587 17.0523 30.1393 23.1859 30.11H28.402C34.4629 30.1391 39.9953 25.1571 39.9953 19.0967Z" fill="white"/>
      <path d="M23.2187 8.82861C17.614 8.83041 13.0727 13.3825 13.0713 19.0006C13.0727 24.6178 17.6143 29.1693 23.2187 29.1713C28.8247 29.1695 33.3673 24.6177 33.368 19.0006C33.3673 13.3825 28.8247 8.83061 23.2187 8.82861Z" fill="#0079BE"/>
      <path d="M16.8047 18.9734C16.81 16.228 18.5211 13.8869 20.934 12.9567V24.9887C18.5211 24.0588 16.8099 21.719 16.8047 18.9734ZM25.538 24.9916V12.9563C27.9518 13.8843 29.6656 16.2268 29.6699 18.9734C29.6656 21.7209 27.9518 24.0616 25.538 24.9914V24.9916Z" fill="white"/>
    </svg>
  )
}
