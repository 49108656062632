import React from "react";

export const MasterCard = () => {
  return (
    <svg width="52" height="37" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4.1875C0 1.94384 1.81884 0.125 4.0625 0.125H47.9375C50.1812 0.125 52 1.94384 52 4.1875V32.625C52 34.8687 50.1812 36.6875 47.9375 36.6875H4.0625C1.81884 36.6875 0 34.8687 0 32.625V4.1875Z" fill="#374961"/>
      <path d="M30.6325 9.6941H21.3971V26.2904H30.6325V9.6941Z" fill="#FF5F00"/>
      <path d="M21.9835 17.9923C21.982 16.3939 22.3442 14.8162 23.0427 13.3785C23.7411 11.9408 24.7575 10.6809 26.0148 9.69411C24.4578 8.47024 22.5878 7.70913 20.6186 7.49778C18.6495 7.28643 16.6606 7.63336 14.8793 8.49892C13.098 9.36449 11.5961 10.7138 10.5454 12.3925C9.4947 14.0713 8.9375 16.0118 8.9375 17.9923C8.9375 19.9727 9.4947 21.9132 10.5454 23.592C11.5961 25.2708 13.098 26.62 14.8793 27.4856C16.6606 28.3512 18.6495 28.6981 20.6186 28.4867C22.5878 28.2754 24.4578 27.5143 26.0148 26.2904C24.7575 25.3036 23.7411 24.0437 23.0427 22.606C22.3443 21.1683 21.9821 19.5906 21.9835 17.9923Z" fill="#EB001B"/>
      <path d="M43.0916 17.9923C43.0916 19.9727 42.5345 21.9132 41.4838 23.592C40.4332 25.2707 38.9314 26.62 37.1501 27.4856C35.3688 28.3512 33.38 28.6981 31.4108 28.4867C29.4417 28.2754 27.5718 27.5143 26.0148 26.2904C27.271 25.3026 28.2866 24.0425 28.9849 22.605C29.6832 21.1676 30.0461 19.5904 30.0461 17.9923C30.0461 16.3942 29.6832 14.8169 28.9849 13.3795C28.2866 11.942 27.271 10.6819 26.0148 9.69411C27.5718 8.47024 29.4417 7.70913 31.4108 7.49778C33.38 7.28643 35.3688 7.63337 37.1501 8.49895C38.9314 9.36452 40.4332 10.7138 41.4838 12.3926C42.5345 14.0713 43.0916 16.0118 43.0916 17.9923Z" fill="#F79E1B"/>
      <path d="M42.0846 24.5326V24.1928H42.2216V24.1236H41.8727V24.1928H42.0098V24.5326H42.0846ZM42.762 24.5326V24.1229H42.6551L42.5321 24.4047L42.409 24.1229H42.302V24.5326H42.3775V24.2236L42.4929 24.49H42.5712L42.6866 24.2229V24.5326H42.762Z" fill="#F79E1B"/>
    </svg>
  );
}
