import { ArrowRightSmallIcon } from "icons";
import { PlaceLocation } from "../model";
import { useTranslation } from "react-i18next";
import { Button } from "button";

type FinderLocationProps = {
  location: PlaceLocation | undefined
  showNavigateToMap: boolean
  onClickLocation: () => void
  onClickNavigateToMap?: () => void
}

export const FinderLocation: React.FC<FinderLocationProps> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center h-[48px] px-[12px]">
      <div
        onClick={props.onClickLocation}
        className="flex flex-none h-[32px] items-center cursor-pointer pl-[4px]">
        <div>
          {props.location?.icon}
        </div>
        <div className="px-[5px] text-body-bold flex items-center">
          {props.location?.name}
          <div className="pl-[5px]">
            <ArrowRightSmallIcon />
          </div>
        </div>
      </div>
      {props.showNavigateToMap ?
        <div
          onClick={props.onClickNavigateToMap}
          className="flex items-center justify-center rounded text-white text-small-bold text-[10px] py-[8px] px-[15px] bg-blue cursor-pointer">
          {t("MAP")}
        </div>
      : null}
    </div>
  )
}