import React from "react";
import { ConsumerApi } from "../../ConsumerApi.dto";
import { LocationCurrentIcon } from "./LocationCurrentIcon";
import { LocationHomeIcon } from "./LocationHomeIcon";
import { LocationPinIcon } from "./LocationPinIcon";

type LocationIconProps = {
  type: ConsumerApi.RecentLocationType
}

export const LocationIcon: React.FC<LocationIconProps> = (props) => {
  switch (props.type) {
    case ConsumerApi.RecentLocationType.Current:
      return <LocationCurrentIcon />
    case ConsumerApi.RecentLocationType.Home:
      return <LocationHomeIcon />
    case ConsumerApi.RecentLocationType.Pin:
    case ConsumerApi.RecentLocationType.Named:
    default:
      return <LocationPinIcon />
  }
}