import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { apiServiceState, localeState } from '../atoms';
import { bookmarkPlaceState, placeBookmarksState, unbookmarkPlaceState } from '../atoms/placeBookmarks';
import { ConfirmUnbookmark } from '../confirm-unbookmark';
import { industryFashion, industryFoodAndDrink, industryShoppingCentre } from '../Constants';
import { ConsumerApi } from "../ConsumerApi.dto";
import { AdMessageIcon, BookmarkCheckedIcon, BookmarkIcon, MenusIcon, PlaceCategoriesIcon, PlaceContactIcon, PlaceDetailIcon, ShareIcon } from '../icons';
import { OptionsMenu } from '../options-menu';
import { PlaceReportProblem } from '../place-report-problem';
import { PlaceVerified } from '../place-verified';
import { SharePlace } from '../share-place';
import { PlaceAdMessages } from './PlaceAdMessages';
import { PlaceCategories } from './PlaceCategories';
import { PlaceContact } from './PlaceContact';
import { PlaceDescription } from './PlaceDescription';
import { PlaceHeader } from './PlaceHeader';
import { PlaceMenus } from './PlaceMenus';
import { formatNumberWithSuffix } from 'helpers/StringHelper';
import { useTranslation } from 'react-i18next';
import { PlaceFooter } from './PlaceFooter';

export enum PlaceTabType {
  Overview = "description",
  Brands = "categories",
  Menu = "menu",
  Contact = "contact",
  Ads = "adMessages"
}

const tabTypeIcon: {[tabType in PlaceTabType]: React.ReactNode} = {
  [PlaceTabType.Overview]: <PlaceDetailIcon />,
  [PlaceTabType.Brands]: <PlaceCategoriesIcon />,
  [PlaceTabType.Menu]: <MenusIcon />,
  [PlaceTabType.Contact]: <PlaceContactIcon />,
  [PlaceTabType.Ads]: <AdMessageIcon />
};

type PlaceProps = {
  adMessages?: ConsumerApi.AdMessageDto[]
  place: ConsumerApi.PlaceDto
  tabType?: PlaceTabType
  teaser?: boolean
}

export const Place: React.FC<PlaceProps> = (props) => {
  const place = props.place;

  const locale = useRecoilValue(localeState);
  const bookmarkPlace = useSetRecoilState(bookmarkPlaceState);
  const unbookmarkPlace = useSetRecoilState(unbookmarkPlaceState);
  const placeBookmarks = useRecoilValue(placeBookmarksState);
  const { t }  = useTranslation();

  const apiService = useRecoilValue(apiServiceState);
  // If tabType is assigned then props.place is assumed as placeExtended
  const [placeExtended, setPlaceExtended] = useState<ConsumerApi.PlaceDto | undefined>(props.tabType && !props.teaser ? place : undefined);
  const [adMessages, setAdMessages] = useState(props.adMessages);
  const [selectedTab, setSelectedTab] = useState<PlaceTabType | undefined>(props.tabType)
  
  const [isSharingPlace, setIsSharingPlace] = useState<boolean>();
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>();
  const [showReportProblem, setShowReportProblem] = useState<boolean>();
  const [showPlaceVerified, setShowPlaceVerified] = useState<boolean>();
  const [showConfirmUnbookmark, setShowConfirmUnbookmark] = useState<boolean>();
  const [followersCount, setFollowersCount] = useState(place.followersCount ?? 0);
  const industry = useMemo(
    () => {
      const category = place.categories.find(c => c.absoluteSlug.indexOf(".") === -1);
      // TODO: Temporary condition added to support Shopping Malls result, since Shopping Malls places are not returning categories
      return category ? category as ConsumerApi.CategoryDto : new ConsumerApi.CategoryDto({
        name: t("Shopping Malls"),
        slug: "shopping_center",
        absoluteSlug: "shopping_center"
      });
    }, [place]);

  const hasBookmark = useMemo(() =>
    placeBookmarks && placeBookmarks.some((b) => b.id === place.id),
    [placeBookmarks, place]);

  const bookmark = useCallback(() => {
    bookmarkPlace(place);
    apiService
      .post(new ConsumerApi.FollowRequest({placeId: place.id}))
      .then(() => setFollowersCount((followersCount) => followersCount + 1))
      .catch(() => unbookmarkPlace(place));
  }, [apiService, bookmarkPlace, place, unbookmarkPlace]);

  const unbookmark = useCallback(() => {
    unbookmarkPlace(place);
    apiService
      .post(new ConsumerApi.UnfollowRequest({placeId: place.id}))
      .then(() => setFollowersCount((followersCount) => followersCount - 1))
      .catch(() => bookmarkPlace(place));
  }, [apiService, bookmarkPlace, place, unbookmarkPlace]);

  const hasSubCategoriesAndBrands = useMemo(() =>
    place.featureData?.some(fd => fd.slug === ConsumerApi.FeatureType.SubCategoriesAndBrands), [place]);

  useEffect(() => {
    if (selectedTab && !placeExtended && place) {
      const request = new ConsumerApi.GetPlaceRequest({placeGuid: place.guid});
      apiService.get(request).then((response) => {
        setPlaceExtended(response.placeDto);
        setAdMessages(response.adMessages);
      })
    }
  }, [apiService, place, placeExtended, selectedTab])

  return (
    <>
      <div className="flex flex-col bg-white text-near-black">
        <PlaceHeader
          onClickPlaceName={place.claimed ? () => setShowPlaceVerified(true) : undefined}
          place={place}
          teaser={props.teaser}
          onClickOptionsMenu={() => setShowOptionsMenu(true)}
          onClickOpeningHours={() => setSelectedTab(PlaceTabType.Overview)} />
        <div>
          <img onClick={() => !selectedTab ?
            setSelectedTab(hasSubCategoriesAndBrands ? PlaceTabType.Brands : PlaceTabType.Overview) :
            setSelectedTab(undefined)}
            className="w-full object-cover cursor-pointer"
            alt="Place"
            src={place.imageUrl?.medium}
            style={{height: "356px"}} />
        </div>
        <div className={`flex items-center px-[12px] pt-[8px] ${selectedTab ? "border-b border-gray-light" : "pb-[8px]"}`}>
          <div className="flex flex-auto items-center">
            {Object.values(PlaceTabType).filter(t =>
                (industry.absoluteSlug === industryFoodAndDrink && t !== PlaceTabType.Brands) ||
                (industry.absoluteSlug === industryFashion && t !== PlaceTabType.Menu) ||
                (industry.absoluteSlug === industryShoppingCentre && t !== PlaceTabType.Brands && t !== PlaceTabType.Menu)
              ).map((value, i) => {
                const tabType = value as PlaceTabType;
                return (<div
                  key={i}
                  className={`flex-none flex flex-col items-center ${!props.teaser ? "cursor-pointer" : ""}`}
                  onClick={() => !props.teaser && setSelectedTab(tabType === selectedTab ? undefined : tabType)}>
                  <div
                    className={`flex items-center px-[12px] ${tabType === selectedTab ? 'text-blue' : ''}`}>
                    {tabTypeIcon[tabType]}
                  </div>
                  <div className={`text-small px-[2px] pt-[2px] ${tabType === selectedTab ? 'text-blue' : ''}`}>
                    {(() => {
                      switch (tabType) {
                        case PlaceTabType.Overview:
                          return t("Overview");
                        case PlaceTabType.Brands:
                          return t("Brands");
                        case PlaceTabType.Menu:
                          return t("Menu");
                        case PlaceTabType.Contact:
                          return t("Contact");
                        case PlaceTabType.Ads:
                          return t("Ads");
                      }
                    })()}
                  </div>
                  <div className={`flex-none w-full h-1 ${tabType === selectedTab ? 'bg-blue' : ''}`}></div>
                </div>);
              })
            }
          </div>

          <div
            className={`flex flex-col items-center ${!props.teaser ? 'cursor-pointer' : ""}`}
            onClick={() => !props.teaser && setIsSharingPlace(true)}>
            <div className="px-[8px]">
              <ShareIcon />
            </div>
            <div className="text-small px-[2px] pt-[2px]">
              {t("Share")}
            </div>
          </div>
          <div className="flex flex-col items-center cursor-pointer">
            <div className="px-[8px] flex items-center">
              <div className="text-body pl-[8px]">
                {followersCount > 0 && formatNumberWithSuffix(followersCount, locale)}
              </div>
              <div
                className={`pr-[8px] ${!props.teaser ? "cursor-pointer" : ""}`}
                onClick={() => !props.teaser && (hasBookmark ? setShowConfirmUnbookmark(true) : bookmark()) }>
                {hasBookmark ?
                  <BookmarkCheckedIcon />
                : <BookmarkIcon />}
              </div>
            </div>
            <div className="text-small px-[2px] pt-[2px]">
              {t("Bookmark")}
            </div>
          </div>
        </div>
        {(!props.teaser && selectedTab && !placeExtended) ?
          <div className="px-[12px] py-[15px]">Loading ...</div>
        : !props.teaser && selectedTab && placeExtended &&
          <>{
              selectedTab === PlaceTabType.Overview ?
                <PlaceDescription place={placeExtended} industry={industry} />
              : selectedTab === PlaceTabType.Brands ?
                <PlaceCategories place={placeExtended} />
              : selectedTab === PlaceTabType.Menu ?
                <PlaceMenus place={placeExtended} />
              : selectedTab === PlaceTabType.Contact ?
                <PlaceContact place={placeExtended} />
              : selectedTab === PlaceTabType.Ads ?
                <PlaceAdMessages place={placeExtended} adMessages={adMessages} />
              : null
            }
            <PlaceFooter
              place={place}
              hideSeparatorAbove={selectedTab === PlaceTabType.Contact} />
          </>
        }
        <div className="flex justify-center">
          <div className="w-[80%] border-b border-b-[2px] border-black"></div>
        </div>
      </div>

      {isSharingPlace ?
        <SharePlace place={place} onClose={() => setIsSharingPlace(false)} />
      : showOptionsMenu ?
        <OptionsMenu
          onClose={() => setShowOptionsMenu(false)}
          onClickUnbookmark={hasBookmark ? () => { setShowOptionsMenu(false); setShowConfirmUnbookmark(true) } : undefined}
          onClickBookmark={!hasBookmark ? () => { setShowOptionsMenu(false); bookmark() } : undefined}
          onClickReportProblem={() => { setShowOptionsMenu(false); setShowReportProblem(true) }} />
      : showReportProblem ?
        <PlaceReportProblem onClose={() => setShowReportProblem(false)} />
      : showConfirmUnbookmark ?
        <ConfirmUnbookmark
          onClose={() => setShowConfirmUnbookmark(false)}
          onClickConfirm={() => { setShowConfirmUnbookmark(false); unbookmark() }} />
      : showPlaceVerified ?
        <PlaceVerified name={place.name} onClose={() => setShowPlaceVerified(false)} />
      : null}
    </>
  )
}