import { apiServiceState, loadingState, referBusinessLinkUsedState, referBusinessTokenState, referFriendLinkUsedState, referFriendTokenState, shareAdMessageLinkUsedState, shareAdMessageTokenState, sharePlaceLinkUsedState, sharePlaceTokenState } from '../atoms';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RewardsIcon } from '../icons';
import { ConsumerApi } from '../ConsumerApi.dto';
import { Trans, useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

type HeaderProps = {
  title?: string
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  onClickLeftIcon?: () => void
  onClickRightIcon?: () => void
  onClickTitle?: () => void
  hideBottomBorder?: boolean
}

type RewardMessage = {
  line1: React.ReactNode
  line2: React.ReactNode
}

export const Header: React.FC<HeaderProps> = (props) => {

  const { t } = useTranslation();
  const rewardMessageRef = useRef<HTMLDivElement>(null);

  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);

  const setReferFriendToken = useSetRecoilState(referFriendTokenState);
  const referFriendUsedLink = useRecoilValue(referFriendLinkUsedState);

  const [referBusinessToken, setReferBusinessToken] = useRecoilState(referBusinessTokenState);
  const referBusinessUsedLink = useRecoilValue(referBusinessLinkUsedState);

  const setSharePlaceToken = useSetRecoilState(sharePlaceTokenState);
  const sharePlaceUsedLink = useRecoilValue(sharePlaceLinkUsedState);

  const setShareAdMessageToken = useSetRecoilState(shareAdMessageTokenState);
  const shareAdMessageUsedLink = useRecoilValue(shareAdMessageLinkUsedState);

  const [rewardMessages, setRewardMessages] = useState<RewardMessage[]>([]);

  const closeRewardMessage = useCallback(() => {
    setReferFriendToken(undefined);
    setReferBusinessToken(undefined);
    setShareAdMessageToken(undefined);
    setSharePlaceToken(undefined);
  }, [setReferFriendToken, setReferBusinessToken, setShareAdMessageToken, setSharePlaceToken]);

  const clickOutsideOfRewardMessage = useCallback(event => {
    if (rewardMessageRef.current && !rewardMessageRef.current.contains(event.target)) {
      closeRewardMessage();
    }
  }, [closeRewardMessage, rewardMessageRef]);

  useEffect(() => {
    const _rewardMessages = [];

    if (referFriendUsedLink) {
      const referringUser = referFriendUsedLink.referringUser ?
        `${referFriendUsedLink.referringUser.firstName} ${referFriendUsedLink.referringUser.lastName}` :
        undefined; // Undefined only happens when ReferFriendState is expired

      switch (referFriendUsedLink.state) {
        case ConsumerApi.ReferFriendState.Awarded:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You and {{referringUser}} got points</span> that can be used in our lottery, because you signed up with the referral link.</Trans>,
            line2: <Trans>Check out Rewards in the menu <RewardsIcon className="inline mx-10px" /> to spend the points!</Trans>
          });
          break;
        case ConsumerApi.ReferFriendState.CanNotReferYourself:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You made an invite and tried to use it yourself.</span></Trans>,
            line2: <Trans>Invite someone else to earn more points. Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.ReferFriendState.YouWereAlreadySignedUp:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">The invite from {{referringUser}} is only for new users</span>, you were already signed up.</Trans>,
            line2: <Trans>Invite someone else to earn more points. Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.ReferFriendState.Expired:
          _rewardMessages.push({
            line1: referringUser ?
              <Trans><span className="font-bold">The invite from {{referringUser}} has expired</span>, you were already signed up.</Trans>
            : <Trans><span className="font-bold">The invite has expired</span>, and you were already signed up.</Trans>,
            line2: <Trans>Invite someone else to earn more points. Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.ReferFriendState.Available:
        default:
          // Logically impossible. Ignore.
      }
    }

    if (referBusinessUsedLink && referBusinessUsedLink.referredPlace) {
      const referringUser = referBusinessUsedLink.referringUser ?
        `${referBusinessUsedLink.referringUser.firstName} ${referBusinessUsedLink.referringUser.lastName}` :
        undefined; // Undefined only happens when ReferBusinessState is expired

      const placeName = referBusinessUsedLink.referredPlace.name;

      switch (referBusinessUsedLink.state) {
        case ConsumerApi.ReferBusinessState.Available:
          setLoading(true);

          apiService.get(new ConsumerApi.GetBusinessWebSignInLinkRequest({
              referBusinessToken: referBusinessToken
            }))
            .then((res) => {
              closeRewardMessage();
              setTimeout(() =>
                window.location.href = res.timeLimitedBusinessWebUrlWithTokens
              );
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
          break;
        case ConsumerApi.ReferBusinessState.AlreadyClaimedByYou:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You and {{referringUser}} got points</span> that can be used in our lottery, because you claimed a business with the referral link.</Trans>,
            line2: <Trans>Check out Rewards in the menu <RewardsIcon className="inline mx-10px" /> to spend the points!</Trans>
          });
          break;
        case ConsumerApi.ReferBusinessState.CanNotReferYourself:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You tried to use your own business referral for {{placeName}}</span>, instead send it to someone else. Then the both of you earn points that can be used in our lottery.</Trans>,
            line2: <Trans>Check out Rewards in the menu <RewardsIcon className="inline mx-10px" /> to earn and spend points!</Trans>
          });
          break;
        case ConsumerApi.ReferBusinessState.AlreadyClaimedBySomeoneElse:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">{{referringUser}} invited you to manage {{placeName}}</span>, but that business is already claimed by someone else.</Trans>,
            line2: <Trans>If that is incorrect contact support!. Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.ReferBusinessState.Expired:
          _rewardMessages.push({
            line1:
            referringUser ?
              <Trans><span className="font-bold">The invite from {{referringUser}} has expired.</span></Trans>
            : <Trans><span className="font-bold">The invite has expired.</span></Trans>,
            line2: <Trans>Invite someone else to earn more points. Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.ReferBusinessState.SignInToSee:
        default:
          // Logically impossible. Ignore.
      }
    }

    if (sharePlaceUsedLink) {
      const referringUser = sharePlaceUsedLink.sharingUser ?
        `${sharePlaceUsedLink.sharingUser.firstName} ${sharePlaceUsedLink.sharingUser.lastName}` :
        undefined; // Undefined only happens when SharingState is expired

      switch (sharePlaceUsedLink.state) {
        case ConsumerApi.SharingState.Awarded:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">{{referringUser}} got points</span> because you looked at the place shared. You can also share and win points that can be used in our lottery.</Trans>,
            line2: <Trans>Check out Rewards in the menu <RewardsIcon className="inline mx-10px" /> to learn more!</Trans>
          });
          break;
        case ConsumerApi.SharingState.SelfShare:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You shared and tried to use it yourself</span>. Invite someone else to earn more points.</Trans>,
            line2: <Trans>Find the link in the menu <RewardsIcon className="inline mx-10px" />.</Trans>
          });
          break;
        case ConsumerApi.SharingState.Expired:
          _rewardMessages.push({
            line1:
              referringUser ?
                <Trans><span className="font-bold">The invite from {{referringUser}} has expired</span>. Invite someone else to earn more points.</Trans>
              : <Trans><span className="font-bold">The invite has expired</span>. Invite someone else to earn more points.</Trans>,
            line2: <Trans>Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.SharingState.Teaser:
        default:
          // Logically impossible. Ignore.
      }
    }

    if (shareAdMessageUsedLink) {
      const referringUser = shareAdMessageUsedLink.user ?
        `${shareAdMessageUsedLink.user.firstName} ${shareAdMessageUsedLink.user.lastName}` :
        undefined; // Undefined only happens when SharingState is expired

      switch (shareAdMessageUsedLink.state) {
        case ConsumerApi.SharingState.Awarded:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">{{referringUser}} got points</span> because you looked at the AD Message shared. You can also share and win points that can be used in our lottery.</Trans>,
            line2: <Trans>Check out Rewards in the menu <RewardsIcon className="inline mx-10px" /> to spend the points!</Trans>
          });
          break;
        case ConsumerApi.SharingState.SelfShare:
          _rewardMessages.push({
            line1: <Trans><span className="font-bold">You shared and tried to use it yourself</span>. Invite someone else to earn more points.</Trans>,
            line2: <Trans>Find the link in the menu <RewardsIcon className="inline mx-10px" />.</Trans>
          });
          break;
        case ConsumerApi.SharingState.Expired:
          _rewardMessages.push({
            line1:
              referringUser ?
                <Trans><span className="font-bold">The invite from {{referringUser}} has expired</span>. Invite someone else to earn more points.</Trans>
              : <Trans><span className="font-bold">The invite has expired</span>. Invite someone else to earn more points.</Trans>,
            line2: <Trans>Find the link in the menu <RewardsIcon className="inline ml-10px" />.</Trans>
          });
          break;
        case ConsumerApi.SharingState.Teaser:
        default:
          // Logically impossible. Ignore.
      }
    }

    setRewardMessages(_rewardMessages);
  }, [referFriendUsedLink, referBusinessUsedLink, setLoading, apiService, referBusinessToken, closeRewardMessage, sharePlaceUsedLink, shareAdMessageUsedLink]);

  useEffect(() => {
    if (rewardMessages.length > 0) {
      document.addEventListener('click', clickOutsideOfRewardMessage);

      return () => {
        document.removeEventListener('click', clickOutsideOfRewardMessage);
      };
    }
  }, [clickOutsideOfRewardMessage, closeRewardMessage, rewardMessages])

  return (
    <div
      className={`relative w-full mx-auto max-w-md text-18 flex items-center justify-center border-gray-lighther ${!props.hideBottomBorder ? "border-b" : ""}`}
      style={{height: "60px", paddingTop: "3px"}}>
      {props.leftIcon || props.rightIcon ?
        <div onClick={props.onClickLeftIcon}
          className={`flex-none pr-25px ${props.leftIcon ? "cursor-pointer" : ""}`}
          style={{paddingLeft: "12px", minWidth: "50px"}}>
          {props.leftIcon}
        </div>
      : null}

      <div
        onClick={props.onClickTitle}
        className={`flex-auto text-center style-heading overflow-ellipsis truncate font-bold ${props.onClickTitle ? "cursor-pointer" : ""}`}>
        {props.title}
      </div>

      {props.leftIcon || props.rightIcon ?
        <div onClick={props.onClickRightIcon}
          className={`flex-none pl-25px ${props.rightIcon ? "cursor-pointer" : ""}`}
          style={{paddingRight: "12px", minWidth: "50px"}}>
          {props.rightIcon}
        </div>
      : null}

      {rewardMessages.length > 0 ?
        <>
          <div
            className="absolute z-999999"
            style={{left: "42px", top: "42px"}}>
            <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2314 53.1985L1.33452 1.33451L53.1985 15.2314L15.2314 53.1985Z" fill="#F6F6F6" stroke="#BDBDBD"/>
              <path d="M2.62744 2.62744L56.5 16.5L16.5 56.5L2.62744 2.62744Z" fill="#F6F6F6"/>
            </svg>
          </div>
          <div
            ref={rewardMessageRef}
            className="absolute bg-near-white border border-gray-light p-30px max-w-345px z-99999"
            style={{left: "56px", top: "56px", right: "0"}}>
            {rewardMessages.map((rewardMessage, i) =>
              <React.Fragment key={i}>
                <div className={`mb-20px style-body-large ${i === 0 ? "pt-30px border-t border-gray-lightest" : null}`}>
                  {rewardMessage.line1}
                </div>
                <div className="mb-20px text-left style-body-large">
                  {rewardMessage.line2}
                </div>
              </React.Fragment>
            )}
            <div className="flex justify-end">
              <div onClick={closeRewardMessage} className="text-blue style-caption cursor-pointer">
                {t("Got It")}
              </div>
            </div>
          </div>
        </>
      : null}
    </div>
  );
}