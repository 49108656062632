import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { showReferFriendsState, showSidebarState, userProfileState, loadingState, apiServiceState } from '../atoms';
import { BookmarkIcon, BusinessIcon, LotteryIcon, RewardsIcon, SearchIcon, SettingsIcon, ShareIcon } from '../icons';
import { ProfilePhoto } from '../profile-photo';
import { ManageBusiness } from './ManageBusiness';

export const Sidebar: React.FC = () => {

  const { t } = useTranslation();
  const userProfile = useRecoilValue(userProfileState);
  const [showSidebar, setShowsidebar] = useRecoilState(showSidebarState);
  const setShowReferFriends = useSetRecoilState(showReferFriendsState);
  const [showManageBusiness, setShowManageBusiness] = useState(false);

  const linkRef = useRef<HTMLAnchorElement>(null);

  const onClickOption = useCallback(() => {
    setShowsidebar(false);
  }, []);

  return (
    <>
      {/* Hidden link to set response.timeLimitedBusinessWebUrlWithTokens and open
        Business Web in a new window with Safari iOS support */}
      <a className="hidden" ref={linkRef} target="_blank">Manage Business</a>
      {showSidebar && userProfile ?
        <div
          onClick={() => setShowsidebar(false)}
          className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-start z-60">
          <div className="h-full bg-white overflow-y-scroll" style={{width: "260px", padding: "56px 30px 56px 30px"}}>
            <Link
              to="/account"
              onClick={onClickOption}
              className="flex items-center cursor-pointer">
              <div>
                <ProfilePhoto
                  firstName={userProfile.firstName}
                  lastName={userProfile.lastName}
                  profilePhotoUrl={userProfile.profilePhotoUrl}
                  lengthInPx={50} />
              </div>
              <div className="pl-3 text-body">
                {userProfile.firstName} {userProfile.lastName}
              </div>
            </Link>
            <Link
              to="/finder"
              onClick={onClickOption}
              className="flex items-center cursor-pointer mt-[50px] mb-1">
              <div className="w-6 flex justify-center">
                <SearchIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                BROVS
              </div>
            </Link>
            <Link
              to="/bookmarks"
              onClick={onClickOption}
              className="flex items-center cursor-pointer mt-[50px] mb-1">
              <div className="w-6 flex justify-center">
                <BookmarkIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                {t("Bookmarks")}
              </div>
            </Link>
            <Link
              to="/rewards"
              onClick={onClickOption}
              className="flex items-center cursor-pointer mt-[50px] mb-1">
              <div className="w-6 flex justify-center">
                <RewardsIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                {t("Rewards")}
              </div>
            </Link>
            <Link
              to="/lottery"
              onClick={onClickOption}
              className="flex items-center cursor-pointer mt-[50px] mb-1">
              <div className="w-6 flex justify-center">
                <LotteryIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                {t("Lottery")}
              </div>
            </Link>
            <div
              onClick={() => { setShowsidebar(false); setShowReferFriends(true) }}
              className="flex items-center cursor-pointer mt-[50px] mb-7">
              <div className="w-6 flex justify-center">
                <ShareIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                {t("Invite a friend")}
              </div>
            </div>
            <Link
              to="/settings"
              onClick={onClickOption}
              className="flex items-center cursor-pointer mt-[50px] mb-7">
              <div className="w-6 flex justify-center">
                <SettingsIcon />
              </div>
              <div className="pl-[20px] style-subheading">
                {t("Settings")}
              </div>
            </Link>
            {userProfile.managesPlacesInBusinessWeb ?
              <>
                <div className="w-full border-b border-gray-lighther my-0.5"></div>
                <div
                  onClick={() => {
                    setShowsidebar(false);
                    setShowManageBusiness(true);
                  }}
                  className="flex items-center cursor-pointer mt-7 mb-1">
                  <div className="w-6 flex justify-center">
                    <BusinessIcon />
                  </div>
                  <div className="pl-[20px] style-subheading">
                    {t("Business")}
                  </div>
                </div>
              </>
            : null}
          </div>
        </div>
      :
      null}
      {showManageBusiness ?
        <ManageBusiness onClose={() => setShowManageBusiness(false)} />
      : null}
    </>
  );
}