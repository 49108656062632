import { atom, selector } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { userProfileState } from "./userProfile";

export const _userStatusState = atom<ConsumerApi.UserStatusResponse | undefined>({
  key: '_userStatusState',
  default: undefined
});

export const userStatusState = selector<ConsumerApi.UserStatusResponse | undefined>({
  key: 'userStatusState',
  get: ({get}) => get(_userStatusState),
  set: ({set}, userStatus) => {
    if (userStatus) {
      set(_userStatusState, userStatus);
      set(userProfileState, (userStatus as ConsumerApi.UserStatusResponse).userProfile);
    } else {
      set(_userStatusState, new ConsumerApi.UserStatusResponse({status: ConsumerApi.UserStatus.LoginOrSignup}));
      set(userProfileState, undefined);
    }
  }
});