import { JsonServiceClient } from "@servicestack/client";
import { apiBaseUrl } from "../Constants";
import { ConsumerApi } from "../ConsumerApi.dto";

function logError(message: Event | string, source?: string, lineno?: number, colno?: number, error?: Error, logConsole: boolean = false) {
  if (error) message = error.stack as string;

  const head = (val: string, length: number) => {
    return val.length > length ? val.substring(val.length - length) : val;
  }
  const tail = (val: string, length: number) => {
    return val.length > length ? val.substring(0, length) : val;
  }

  const data = {
    location: head(`${source}:${lineno}:${colno}`, 256),
    message: tail(message.toString(), 512),
    tag: "error"
  }

  if (logConsole) {
    console.error(data);
  }

  const client = new JsonServiceClient(apiBaseUrl);
  client
    .post(new ConsumerApi.LogFrontEndEventRequest(data))
    .catch(error => console.log(error));
}

export function logErrorOnServer(message: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
  logError(message, source, lineno, colno, error, false);
}

export function logErrorOnServerAndConsole(message: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
  logError(message, source, lineno, colno, error, true);
}