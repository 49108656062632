import { atom, selector } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";

export const adMessageBookmarksState = atom<ConsumerApi.AdMessageDto[] | undefined>({
  key: 'adMessageBookmarksState',
  default: undefined
});

export const bookmarkAdMessageState = selector<ConsumerApi.AdMessageDto | undefined>({
  key: 'bookmarkAdMessageState',
  get: () => undefined,
  set: ({ set, get }, adMessage) => {
    if (adMessage) {
      const a = adMessage as ConsumerApi.AdMessageDto;
      const adMessages = [...(get(adMessageBookmarksState) || [])];
      const currentIndex = adMessages.findIndex(_a => _a.id === a.id);

      if (currentIndex === -1) {
        set(adMessageBookmarksState, [a, ...adMessages]);
      }
    }
  }
});

export const unbookmarkAdMessageState = selector<ConsumerApi.AdMessageDto | undefined>({
  key: 'unbookmarkAdMessageState',
  get: () => undefined,
  set: ({ set, get }, adMessage) => {
    if (adMessage) {
      const a = adMessage as ConsumerApi.AdMessageDto;
      const adMessages = [...(get(adMessageBookmarksState) || [])];
      const currentIndex = adMessages.findIndex(_a => _a.id === a.id);

      if (currentIndex > -1) {
        adMessages.splice(currentIndex, 1)
        set(adMessageBookmarksState, adMessages);
      }
    }
  }
});