import { apiServiceState, loadingState } from '../atoms';
import { ConsumerApi } from '../ConsumerApi.dto';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { Overlay } from '../overlay';
import { Button } from '../button';
import { setTimeout } from 'timers';

type ManageBusinessProps = {
  onClose: () => void
}

export const ManageBusiness: React.FC<ManageBusinessProps> = (props) => {

  const { t } = useTranslation()
  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const [manageBusinessLink, setManageBusinessLink] = useState<string>();
  const [manageBusinessLinkExpirationTime, setManageBusinessLinkExpirationTime] = useState(59);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (manageBusinessLink) {
      if (manageBusinessLinkExpirationTime > 0) {
        timeout = setTimeout(() => setManageBusinessLinkExpirationTime(v => v - 1) , 1000)
      } else {
        props.onClose();
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [manageBusinessLink, manageBusinessLinkExpirationTime]);

  useEffect(() => {
    setLoading(true);
    apiService.get(new ConsumerApi.GetBusinessWebSignInLinkRequest())
      .then(response =>
        setManageBusinessLink(response.timeLimitedBusinessWebUrlWithTokens)
      )
      .finally(() => setLoading(false));
  }, [apiService, setLoading]);

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z" stroke="#D2D2D2" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M39.625 16H19.375L16 23.5H43L39.625 16ZM16 25H43V41.5H16V25ZM31.75 29.4H19.375V38.2H31.75V29.4ZM39.625 29.4H35.125V38.2H39.625V29.4Z" fill="currentColor"/>
          </svg>
        </div>
        <div className="font-bold text-20 text-center max-w-280px line-clamp-3 mt-[25px]">
          <Trans>Manage Business</Trans>
        </div>
        <div className="center mt-[15px] style-body-copy">
          <Trans>Expires in {{claimBusinessLinkExpirationTime: manageBusinessLinkExpirationTime}} seconds.</Trans>
        </div>
        <div className="mt-[25px] w-full">
          <Button
            onClick={() => {
              setManageBusinessLink(undefined);
              props.onClose();
            }}
            type="externalLink"
            href={manageBusinessLink}
            title={t("Manage Now")}
            target="_blank" />
        </div>
      </div>
    </Overlay>
  )
}