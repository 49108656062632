import { AmericanExpress, Diners, MasterCard, Vipps, Visa } from '../icons/payment-options';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { industryFoodAndDrink, industryShoppingCentre as industryShoppingCenter } from '../Constants';
import { ConsumerApi } from "../ConsumerApi.dto";
import { BarSpecialtiesIcon, BarTypesIcon, CuisinesIcon, InformationIcon, MealsIcon, MoneyIcon, TakeawayIcon } from '../icons';
import { PlaceOpeningHours } from './PlaceOpeningHours';
import { logErrorOnServerAndConsole } from '../helpers/ErrorHelper';
import { PlaceShoppingCenter } from './PlaceShoppingCenter';

type PlaceDetailProps = {
  place: ConsumerApi.PlaceDto
  industry?: ConsumerApi.CategoryDto
}

export const PlaceDescription: React.FC<PlaceDetailProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;

  const industry = useMemo(() => props.industry?.absoluteSlug || place.categories[0].absoluteSlug.split(".")[0], [place])

  const title = (icon: React.ReactNode, title: string) => {
    return (
      <div className="flex mb-[10px] items-center">
        <div className="flex justify-center pr-[10px] pt-[2px]">
          {icon}
        </div>
        <div className="text-title-bold flex-auto">
          {title}
        </div>
      </div>
    )
  }

  const list = (icon: React.ReactNode, _title: string, items?: string[]) => {
    return (
      <div className="flex flex-col py-[15px] not-first:border-t border-gray-lighther">
        {title(icon, _title)}
        <div className="text-body whitespace-pre-wrap">{items && items.length > 0 ? items.join("   |   ") : t("Not added")}</div>
      </div>)
  }

  const paymentIcon = (paymentSlug?: string) => {
    switch (paymentSlug) {
      case "AmericanExpress":
        return <AmericanExpress />;
      case "MasterCard":
        return <MasterCard />;
      case "Diners":
        return <Diners />;
      case "Vipps":
        return <Vipps />;
      case "Visa":
        return <Visa />;
      default:
        const err = new Error(`Missing Payment Option icon for "${paymentSlug}"`);
        logErrorOnServerAndConsole(err.message, undefined, undefined, undefined, err);
        return null;
    }
  }

  return (
    <div className="flex flex-col px-[12px]">
      {place.overview &&
        <div className="py-[15px] text-body whitespace-pre-wrap">
          {place.overview}
        </div>
      }

      {place.wearPriceProfiles?.length > 0 &&
        list(<MoneyIcon />, t("Profile"), place.wearPriceProfiles.map(p => p.label))
      }

      {industry === industryFoodAndDrink ?
        <>
          {list(<CuisinesIcon />, t("Cuisines"), place.cuisines?.map(c => c.label))}
          {list(<MealsIcon />, t("Meals"), place.mealTypes?.map(m => m.label))}
          {list(<TakeawayIcon />, t("Takeaway"), place.takeAways?.map(t => t.label))}
          {list(<BarTypesIcon />, t("Type of Bar"), place.barTypes?.map(b => b.title))}
          {list(<BarSpecialtiesIcon />, t("Specialties"), place.barSpecialities?.map(b => b.title))}
          {list(<MoneyIcon />, t("Price Level"), place.priceMainDish ? [place.priceMainDish.title] : undefined)}
        </>
      : industry === industryShoppingCenter &&
        <PlaceShoppingCenter place={place} />
      }

      <PlaceOpeningHours openingHours={place.openingHours} />

      { (place.wifi ||
        place.outdoorSeating !== undefined ||
        place.parking?.length > 0 ||
        place.paymentOptions?.length > 0 ||
        place.wheelchairAccessible) &&
        <div className="py-[15px] not-first:border-t border-gray-lighther">
          {title(<InformationIcon />, t("More Information"))}
          { place.wifi &&
            <div>
              <div className="text-body-bold">{t("WiFi")}</div>
              <div className="text-body">{t("Free")}</div>
            </div>
          }
          { place.outdoorSeating !== undefined &&
            <div className="pt-[10px]">
              <div className="text-body-bold">{t("Outdoor Seating")}</div>
              <div className="text-body">{place.outdoorSeating ? t("Yes") : t("No")}</div>
            </div>
          }
          { place.eatingSuitabilities?.length > 0 &&
            <div className="pt-[10px]">
              <div className="text-body-bold">{t("Suitability")}</div>
              <div className="text-body whitespace-pre-wrap">{
                place.eatingSuitabilities.map(p => p.label).join("   |   ")
              }</div>
            </div>
          }
          { place.parking?.length > 0 &&
            <div className="pt-[10px]">
              <div className="text-body-bold">{t("Parking")}</div>
              <div className="text-body whitespace-pre-wrap">{
                place.parking.map(p => p.label).join("   |   ")
              }</div>
            </div>
          }
          { place.paymentOptions?.length > 0 &&
            <div className="pt-[10px]">
              <div className="text-body-bold">{t("Payment Options")}</div>
              <div className="flex mt-[5px]">{
                place.paymentOptions.map((p, i) => <div key={p.slug} className="mr-[5px]">{paymentIcon(p.slug)}</div>)
              }</div>
            </div>
          }
          { place.wheelchairAccessible &&
            <div className="pt-[10px]">
              <div className="text-body-bold">{t("Accessibility")}</div>
              <div className="text-body">{t("Wheelchair Accessible")}</div>
            </div>
          }
        </div>
      }
    </div>
  )
}