import { ConsumerApi } from '../ConsumerApi.dto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeparatorHeader } from '../separator-header';

type EarnRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse
}

const EarnRewardPoints: React.FC<EarnRewardPointsProps> = (props) => {

  const { t } = useTranslation();

  return (
    <>
      <SeparatorHeader title={t("Earn Reward Points")} />
      <div className="flex items-center mt-[15px]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      <div className="flex mt-[25px] mb-[14px]">
        <div className="flex-auto style-body-copy">
        </div>
        <div className="flex-none w-[100px] style-heading text-center">
          {t("Points")}
        </div>
      </div>
      { [ [t("Friend Referrals"), props.rewardsStatus.referFriend.pointsGrantedOnSuccess],
          [t("Business Referrals"), props.rewardsStatus.referBusiness.pointsGrantedOnSuccess],
          [t("Shared Places"), props.rewardsStatus.sharePlace.pointsGrantedOnSuccess],
          [t("Shared AD Messages"), props.rewardsStatus.shareAdMessage.pointsGrantedOnSuccess],
          [t("Sign Up Bonus"), props.rewardsStatus.pointsAwardedForSignup]
        ].map(([title, value], i) =>
          <div
            className="flex py-[6px] items-center"
            key={i}>
            <div className="flex-auto style-body-copy">
              {title}
            </div>
            <div className="flex-none w-[100px] style-body-subheading text-center">
              {value}
            </div>
          </div>        
        )
      }
    </>
  )
}

export default EarnRewardPoints;