import { isPwa } from "../helpers/PwaHelper";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiServiceState, userStatusState, skipSuggestPwaInstallationState, suggestPwaInstallationState } from "../atoms";
import { ConsumerApi } from "../ConsumerApi.dto";

export const useInitUserStatus = (): (callback?: (userStatus?: ConsumerApi.UserStatusResponse) => void) => void => {
  const apiService = useRecoilValue(apiServiceState);
  const setUserStatus = useSetRecoilState(userStatusState);
  const setSuggestPwaInstallation = useSetRecoilState(suggestPwaInstallationState);
  const skipSuggestPwaInstallation = useRecoilValue(skipSuggestPwaInstallationState);

  return  useCallback((callback?: (userStatus?: ConsumerApi.UserStatusResponse) => void) => {
    apiService.get(new ConsumerApi.UserStatusRequest( { fromPwa: isPwa() } ))
      .then((res) => {
        setUserStatus(res)
        if (callback) {
          callback(res);
        }
        setSuggestPwaInstallation(res.suggestPwaInstallation === true && !skipSuggestPwaInstallation);
      })
      .catch(() => {
        const userStatus = new ConsumerApi.UserStatusResponse({status: ConsumerApi.UserStatus.LoginOrSignup});
        setUserStatus(userStatus);
        if (callback) {
          callback(userStatus);
        }
      })
  }, [apiService, setUserStatus])
};