import { ConsumerApi } from '../ConsumerApi.dto';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiServiceState, loadingState, showSidebarState } from '../atoms';
import { Header } from '../header';
import { MenuIcon } from '../icons';
import BusinessReferrals from './BusinessReferrals';
import EarnRewardPoints from './EarnRewardPoints';
import FriendReferrals from './FriendReferrals';
import MyRewardPoints from './MyRewardPoints';
import SharedAdMessages from './SharedAdMessages';
import SharedPlaces from './SharedPlaces';
import UseRewardPoints from './UseRewardPoints';

export const Rewards: React.FC = (props) => {

  const { t } = useTranslation();

  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const [rewardsStatus, setRewardsStatus] = useState<ConsumerApi.GetRewardsStatusResponse>();

  useEffect(() => {
    setLoading(true);
    apiService.get(new ConsumerApi.GetRewardsStatusRequest())
    .then((res) => setRewardsStatus(res))
    .catch((err) => console.log(err))
    .finally(() => setLoading(false))

  }, [apiService])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30 mx-auto max-w-md bg-white">
        <Header
          leftIcon={<MenuIcon />}
          onClickLeftIcon={() => setShowSidebar(true)}
          title={t("My Rewards")} />
      </div>
      {rewardsStatus ?

        <div className="px-15px py-60px h-full overflow-y-scroll">

          <div className="mt-[25px]">
            <MyRewardPoints rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px]">
            <EarnRewardPoints rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px] pt-[40px] border-t-[1px] border-gray-light">
            <FriendReferrals rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px] pt-[40px] border-t-[1px] border-gray-light">
            <BusinessReferrals rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px] pt-[40px] border-t-[1px] border-gray-light">
            <SharedPlaces rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px] pt-[40px] border-t-[1px] border-gray-light">
            <SharedAdMessages rewardsStatus={rewardsStatus} />
          </div>

          <div className="mt-[40px]">
            <UseRewardPoints rewardsStatus={rewardsStatus} />
          </div>

        </div>

      : null}
    </>
  )
}

export default Rewards;