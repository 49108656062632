import { DateTime } from "luxon";
import { atom, selector } from "recoil";

const localStorageKey = "skipSuggestPwaInstallation";

const _skipSuggestPwaInstallationState = atom<boolean>({
  key: '_skipSuggestPwaInstallationState',
  default: localStorage.getItem(localStorageKey) === "true" ?? false
});

export const skipSuggestPwaInstallationState = selector<boolean>({
  key: 'skipSuggestPwaInstallationState',
  get: () => {
    const stringValue = localStorage.getItem(localStorageKey);
    if (stringValue) {
      const jsonValue = JSON.parse(stringValue);
      if (DateTime.fromISO(jsonValue.expiry) > DateTime.now() && jsonValue.skip === "true") {
        return true;
      } else {
        localStorage.removeItem(localStorageKey)
      }
    }
    return false;
  },
  set: ({ set }, skip) => {
    if (skip) {
      localStorage.setItem(localStorageKey, JSON.stringify({
        skip: "true",
        expiry: DateTime.now().plus({hours: 24}).toISO()
      }))
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_skipSuggestPwaInstallationState, skip)
  }
});