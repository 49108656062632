import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Overlay } from '../overlay';

type PlaceVerifiedProps = {
  onClose: () => void
  name: string
}

export const PlaceVerified: React.FC<PlaceVerifiedProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div className="text-green">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="currentColor"/>
            <path d="M36.0684 17L40 19.9307L26.2637 40L17 30.3324L20.4164 26.767L25.6472 32.2259L36.0684 17Z" fill="white"/>
          </svg>
        </div>
        <div
          className="font-bold text-20 mt-[25px]">
          {t("Verified Profile")}
        </div>
        <div
          className="style-body-copy text-center mt-[15px]">
          <Trans>This profile is managed by<br />{props.name}.</Trans>
        </div>
      </div>
    </Overlay>
  )
}