import { ProfilePhoto } from "../profile-photo";
import { useTranslation } from "react-i18next";

type InvitationProps = {
  firstName?: string
  lastName?: string
  profilePhotoUrl?: string
  invitationMessage: React.ReactNode
  previewImageUrl?: string
  preview?: React.ReactNode
  bodyMessage: React.ReactNode
}

export const ShareAndReferTeaser: React.FC<InvitationProps> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center mt-35px">
      <div className="w-full max-w-300px flex flex-col items-center">
        <ProfilePhoto
          lengthInPx={80}
          firstName={props.firstName}
          lastName={props.lastName}
          profilePhotoUrl={props.profilePhotoUrl} />
        <div className="text-22 font-bold text-center mt-35px">
          <span className="uppercase">
            {props.firstName} {props.lastName}
          </span><br />
          {props.invitationMessage}
        </div>
      </div>

      {props.preview ?
        <div className="mt-25px">
          {props.preview}
        </div>
      : null}

      <div className="max-w-300px style-body-large text-center style-body-large mt-35px mb-45px">
        {props.bodyMessage}
      </div>
    </div>
  )
}

type ShareAndReferTeaserExpiredProps = {
  firstName?: string
  lastName?: string
}

export const ShareAndReferTeaserExpired: React.FC<ShareAndReferTeaserExpiredProps> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="mt-150px mb-45px text-22 text-center font-bold w-full max-w-300px m-auto">
      {!props.firstName || !props.lastName ?
        t("The invite has expired.")
      :
        t("The invite from {{firstName}} {{lastName}} has expired.",
          {
            firstName: props.firstName,
            lastName: props.lastName
          }
        )
      }
    </div>
  )
}