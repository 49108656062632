import { apiServiceState, loadingState } from '../atoms';
import { ConsumerApi } from '../ConsumerApi.dto';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sendSMS } from '../helpers/ShareHelper';
import { AdMessageModel } from '../model';
import { Overlay } from '../overlay';

type ShareAdMessageProps = {
  adMessage: AdMessageModel
  onClose: () => void
}

export const ShareAdMessage: React.FC<ShareAdMessageProps> = (props) => {
  const { t } = useTranslation()

  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const share = useCallback((sms?: boolean) => {
    setLoading(true);

    apiService.get(new ConsumerApi.ShareAdMessageLinkRequest({
        adMessageId: props.adMessage.dto.id
      }))
      .then((res) => {
        if (sms) {
          sendSMS(
            t(`Hi :-) you might have an interest in this ad {{adTitle}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.
{{url}}`,
              {
                adTitle: props.adMessage.title,
                adDescription: props.adMessage.description,
                placeName: props.adMessage.dto.place.name,
                url: res.linkWithShareAdMessageToken
              }
            ))
        } else {
          navigator.share({
            title: props.adMessage.title,
            text: t(`Hi :-) you might have an interest in this ad {{adTitle}} {{adDescription}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.
{{url}}`,
              {
                adTitle: props.adMessage.title,
                adDescription: props.adMessage.description,
                placeName: props.adMessage.dto.place.name,
                url: res.linkWithShareAdMessageToken
              }
            ),
            url: res.linkWithShareAdMessageToken
          })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [apiService, props.adMessage.description, props.adMessage.title, setLoading, t])

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z" stroke="#D2D2D2" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.6471 25.7351L39 19V38.7647L27.0731 34.4906L24.9593 40.1765H19.2353L21.934 32.649L20.6471 32.1878V25.7351ZM19.2353 31.7059H15V26.0588H19.2353V31.7059Z" fill="currentColor"/>
          </svg>
        </div>
        <div
          style={{marginTop: "25px"}}
          className="font-bold text-20">
          {t("Share this AD Message")}
        </div>
        <button
          onClick={() => share(true)}
          style={{marginTop: "25px"}}
          className="bg-blue text-white style-subtitle h-11 w-full rounded">
          {t("SMS")}
        </button>
        {/* TODO: Hide until we have tested and fixed the preview in Twitter, FB and other media. */}
        {/* {navigator.share !== undefined ?
          <button
            onClick={() => share()}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Other")}
          </button>
        : null} */}
      </div>
    </Overlay>
  )
}