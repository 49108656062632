import { useMemo } from "react";
import { useQueryString } from "./UseQueryString";

export const useFinderParams = () => {

  const searchParams = useQueryString();

  const paramLocation = useMemo(() =>
    searchParams.has("location") ? searchParams.get("location") as string : undefined,
    [searchParams]);

  const paramPlaceGuid = useMemo(() =>
    searchParams.has("placeGuid") ? searchParams.get("placeGuid") as string : undefined,
    [searchParams]);

  const paramAdMessageGuid = useMemo(() =>
    searchParams.has("adMessageGuid") ? searchParams.get("adMessageGuid") as string : undefined,
    [searchParams]);

  const paramShared = useMemo(() =>
    searchParams.has("shared") ? searchParams.get("shared") as string : undefined,
    [searchParams]);

  return { paramLocation, paramPlaceGuid, paramAdMessageGuid, paramShared };
}