import React, { useCallback, useEffect, useState } from 'react';
import { apiServiceState, loadingState } from '../atoms';
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Overlay } from '../overlay';
import { Button } from '../button';
import { Input } from '../input';
import { ConsumerApi } from '../ConsumerApi.dto';
import { Link } from 'react-router-dom';

type GetLotteryTicketsProps = {
  activeLottery: ConsumerApi.ActiveLotteryDto;
  onClose: () => void
  onAcquiredTickets: (totalTicketsInLottery: number) => void
}

export const GetLotteryTickets: React.FC<GetLotteryTicketsProps> = (props) => {

  const { t } = useTranslation();
  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);

  const [lotteryAvailabePoints, setLotteryAvailabePoints] = useState(0);
  const [pointsToConvert, setPointsToConvert] = useState("1");

  const acquireTickets = useCallback(() => {
    setLoading(true);
    const request = new ConsumerApi.LotteryAcquireTicketsRequest({
      pointsToConvert: parseInt(pointsToConvert)
    });
    apiService.post(request)
      .then((response) =>
        props.onAcquiredTickets(response.totalTicketsInLottery || 0))
      .finally(() => setLoading(false));
  }, [apiService, pointsToConvert])
  
  useEffect(() => {
    setLoading(true);
    const request = new ConsumerApi.LotteryAvailabePointsRequest();
    apiService.get(request)
      .then((response) => {
        setLotteryAvailabePoints(response.availablePoints || 0);
      })
      .finally(() => setLoading(false));
  }, [apiService])

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div className="font-bold text-[20px] text-center max-w-[280px]">
          {t("Get Lottery Tickets")}
        </div>
        <div className="center mt-[15px] style-body-copy">
          {t("My Reward Points")}
        </div>
        <div className="font-bold text-[32px] mt-[5px]">
          {lotteryAvailabePoints}
        </div>
        {lotteryAvailabePoints === 0 ?
          <>
            <div className="text-body">
              {t("You don't have Reward Points to get Lottery tickets.")}
            </div>
            <Link className="text-body-link" to="/rewards">
              {t("See how to Earn Reward Points.")}
            </Link>
            <div className="mt-[25px] w-full">
              <Button title={t("Ok")} onClick={props.onClose} />
            </div>
          </>
        :
          <>
            <div
              className="center mt-[15px] style-body-copy text-center">
              <Trans>How many points do you want to<br></br>convert to Lottery tickets?</Trans>
            </div>
            <div className="mt-[10px] w-[90px]">
              <Input type="number" value={pointsToConvert} autoFocus={true} textAlign="center" onChange={(v) => setPointsToConvert(v)} />
            </div>
            <div className="center mt-[10px] style-body-copy">
              {t("One point = One Lottery Ticket")}
            </div>
            <div className="mt-[25px] w-full">
              <Button title={t("Get Now")} onClick={acquireTickets} />
            </div>
            <div className="center mt-10px style-body-copy-link cursor-pointer" onClick={props.onClose}>
              {t("Cancel")}
            </div>
          </>
        }
      </div>
    </Overlay>
  )
}