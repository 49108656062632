import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AdMessageIcon, PlaceIcon } from '../icons';
import { useFinderParams } from '../hooks/UseFinderParams';
import { buildQueryString } from '../helpers/FinderHelper';

export enum TabType {
  adMessages = "admessages",
  places = "places",
  searches = "searches"
}

type FinderTabProps = {
  tabType: TabType,
  selected: boolean,
  count?: number,
  hideIcon?: boolean,
  onClick?: () => void
}

export const FinderTab: React.FC<FinderTabProps> = (props) => {

  const { t } = useTranslation();
  const location = useLocation();

  const count = props.count;
  const tabType = props.tabType;
  const selected = props.selected;

  const { paramLocation, paramPlaceGuid, paramAdMessageGuid } = useFinderParams();

  const pathArray = location.pathname.split("/");
  pathArray[2] = props.tabType;
  const queryString = buildQueryString(paramLocation || "home", paramPlaceGuid, paramAdMessageGuid);
  const path = `${pathArray.join("/")}?${queryString}`

  return (
    <Link
      to={path}
      onClick={props.onClick}
      className="flex flex-col h-full cursor-pointer"
      style={{flexGrow: 1, flexBasis: 0}}>
      <div className="flex-auto flex justify-center items-center pb-[4px]">
        {!props.hideIcon ?
          <div className={`${selected ? 'text-blue' : ''}`}>
            {tabType === TabType.adMessages ?
              <AdMessageIcon /> :
              <PlaceIcon />
            }
          </div>
        : null}
        <div className={`px-[6px] ${selected ? 'text-blue' : ''}`}>
          <div className="text-body-bold">
            {tabType === TabType.adMessages ?
              t("Ads") :
            tabType === TabType.places ?
              t("Places")
            : t("Searches")}
          </div>
          <div className="text-small">
            {count}
          </div>
        </div>
      </div>
      <div className={`flex-none ${selected ? 'bg-blue h-[4px]' : 'bg-gray-light h-[1px] mt-[3px]'}`}></div>
    </Link>
  )
}