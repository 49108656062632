import { selector } from "recoil";
import { atom } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { localeState } from "./locale";

const _userProfileState = atom<ConsumerApi.UserProfileDto | undefined>({
  key: '_userProfileState',
  default: undefined
});

export const userProfileState = selector<ConsumerApi.UserProfileDto | undefined>({
  key: 'userProfileState',
  get: ({get}) => get(_userProfileState),
  set: ({set}, userProfile) => {

    set(_userProfileState, userProfile);

    if (userProfile) {
      const _userProfile = userProfile as ConsumerApi.UserProfileDto;
      if (_userProfile.locale) {
        set(localeState, _userProfile.locale);
      }
    }

  }
});
