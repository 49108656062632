import React, { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client'; // Updated import for React 18
import './index.css';
import '@splidejs/splide/dist/css/splide.min.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { Spinner } from './spinner';
import { BrowserRouter } from 'react-router-dom';
import { logErrorOnServer } from './helpers/ErrorHelper';
import 'react-image-crop/dist/ReactCrop.css'

window.onerror = function (message, source, lineno, colno, error) {
  logErrorOnServer(message, source, lineno, colno, error);
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOMClient.createRoot(rootElement);
root.render(
    <React.StrictMode>
      <Suspense fallback={<Spinner fullScreen={true} />}>
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </Suspense>
    </React.StrictMode>
);

const useServiceWorker = true;
if ('serviceWorker' in navigator) {
  if (useServiceWorker) {
    navigator.serviceWorker
      .register(
        new URL('service-worker.ts', import.meta.url),
        { type: 'module' }
      )
      .then(function () {
        // console.log("Service Worker Registered");
      });
  } else {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
  }
}

/*
Usage:
  androidInstallPromptEvent.prompt();
  androidInstallPromptEvent.userChoice.then(choice => {
    if (choice.outcome == "accepted") {
      console.log("User chose to install PWA on " + choice.platform);
    } else if (choice.outcome == "dismissed") {
      console.error("User chose not to install PWA on " + choice.platform);
    } else {
      throw new Error("Unknown install outcome");
    }
    // Clear the saved prompt since it can't be used again
    androidInstallPromptEvent = null;
  });
*/
// App Installation
window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
  // Don't show the installer. This project only shows it after login,
  // and only if the user has not already installed the app.
  event.preventDefault();
  // Stash the event so it can be triggered later.
  window.androidInstallPromptEvent = event;
  // console.log("Can offer to install on Android.");
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
