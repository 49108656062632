import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { saveFoodAndMoneyImageState } from '../atoms';
import { industryFoodAndDrink } from '../Constants';
import { ConsumerApi } from '../ConsumerApi.dto';

import { ArrowBackIcon } from '../icons';

export type FinderCategoriesProps = {
  category: ConsumerApi.CategoryFacetTreeDto
  adMessageSaveFoodAndMoneyCount: number
  onBack: () => void
  onSelect: (absoluteSlug: string, saveFoodAndMoney: boolean) => void
}

const Categories: React.FC<FinderCategoriesProps> = (props) => {

  const { t } = useTranslation();

  const saveFoodAndMoneyImageUrl = useRecoilValue(saveFoodAndMoneyImageState);

  const categories = useMemo(() =>
    [
      props.category,
      ...props.category.children ? props.category.children : []
    ], [props.category]);

  return (
    <div className="fixed inset-0 z-999999 bg-white overflow-y-auto mx-auto max-w-md">
      <div className="fixed top-0 left-0 right-0 z-30 bg-white">
        <div className="w-full mx-auto max-w-md border-b border-gray-lighther">
          <div className="flex items-center h-14 w-full bg-white">
            <div onClick={props.onBack} className="flex-none p-3 w-7 cursor-pointer">
              <ArrowBackIcon />
            </div>
            <div className="flex-auto text-center style-heading">
              {props.category.name}
            </div>
            <div className="flex-none px-3 w-7">
            </div>
          </div>
        </div>
      </div>

      <div className="mt-14 flex flex-wrap py-3 pl-3">
        {props.category.absoluteSlug === industryFoodAndDrink && props.adMessageSaveFoodAndMoneyCount > 0 ?
          <div
            onClick={() => props.onSelect(industryFoodAndDrink, true)}
            className="cursor-pointer w-full pb-3 pr-3">
            <div className="relative bg-cover"
              style={{height: "139px", backgroundImage: `url(${saveFoodAndMoneyImageUrl})`}}>
              <div className="absolute inset-0 bg-black bg-opacity-20 z-10"></div>
              <div className="absolute inset-0 z-20">
                <div className="text-title-xbold text-white text-center w-full px-[3px] mt-[57px]">
                  {t("Save Food & Money")}
                </div>
                <div className="flex-none text-body text-white text-center w-full px-[3px]">
                  {t("End Of Day Unsold Food And Beverages")}
                </div>
              </div>
            </div>
          </div>
        : null}
        {categories.map((c, i) =>
          <div
            onClick={() => props.onSelect(c.absoluteSlug, false)}
            key={c.absoluteSlug}
            className="cursor-pointer w-1/2 pb-3 pr-3">
            <div className="relative bg-cover"
              style={{height: "139px", backgroundImage: `url(${c.absoluteSlug.split(".").length > 1 ? c.imageUrl : c.allImageUrl})`}}>
              <div className="absolute inset-0 bg-black bg-opacity-20 z-10"></div>
              <div className="absolute inset-0 z-20">
                <div className="text-title-xbold text-white text-center w-full px-[3px] mt-[57px]">
                  {c.absoluteSlug.split(".").length > 1 ? c.name : t("All Categories")}
                </div>
                <div className="flex-none text-body text-white text-center w-full px-[3px]">
                  {c.count}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Categories;