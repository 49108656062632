import { LatLng } from "../model";
import { ConsumerApi } from "../ConsumerApi.dto";

export class PlaceLocation {

  public readonly name: string;
  public readonly locationType: ConsumerApi.RecentLocationType;
  public readonly icon: JSX.Element;
  public readonly extendedName?: string;
  public readonly description?: string;
  public readonly placeId?: string;
  public readonly invalidLocation?: boolean; // Used when Current Location is not set

  constructor(init: PlaceLocation) {
    Object.assign(this, init);
  }

}