import { apiServiceState, loadingState } from '../atoms';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ConsumerApi } from "../ConsumerApi.dto";

const ExpandedPlace = React.lazy(() => import('./ExpandedPlace'));

type PlaceShoppingCenterProps = {
  place: ConsumerApi.PlaceDto
}

export const PlaceShoppingCenter: React.FC<PlaceShoppingCenterProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;
  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);

  const [expandedPlace, setExpandedPlace] = useState<ConsumerApi.PlaceDto>();
  const [expandedPlaceAdMessages, setExpandedPlaceAdMessages] = useState<ConsumerApi.AdMessageDto[]>();

  const [tab, setTab] = useState("categories")

  const showPlace = useCallback((placeGuid: string) => {
    setLoading(true);
    const request = new ConsumerApi.GetPlaceRequest({ placeGuid: placeGuid });
    apiService.get(request)
      .then((response) => {
        setExpandedPlace(response.placeDto);
        setExpandedPlaceAdMessages(response.adMessages);
      })
      .finally(() => setLoading(false))
  }, [apiService, setExpandedPlace, setLoading]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex">
          {[
            ["categories", t("Categories")],
            ["brands", t("Brands")],
            ["places", t("Places")],
          ].map(([key, title]) =>
            <div
              key={key}
              onClick={() => setTab(key)}
              className="flex flex-col h-full cursor-pointer"
              style={{flexGrow: 1, flexBasis: 0}}>
              <div className="flex-auto flex justify-center">
                <div className={`flex flex-col pt-[24px] ${key === tab ? 'text-blue pb-[20px]' : 'pb-[23px]'}`}>
                  <div className="text-body-bold">
                    {title}
                  </div>
                </div>
              </div>
              <div className={`flex-none ${key === tab ? 'bg-blue h-[4px]' : 'bg-gray-light h-[1px]'}`}></div>
            </div>
          )}
        </div>
        { tab === "categories" ?
          <Categories place={place} onClickPlace={(place) => showPlace(place.guid as string)} />
        : tab === "places" ?
          <Places place={place} onClickPlace={(place) => showPlace(place.guid as string)} />
        : tab === "brands" ?
          <Brands place={place} onClickPlace={(place) => showPlace(place.guid as string)} />
        : null}
      </div>
      {expandedPlace ?
        <ExpandedPlace
          place={expandedPlace}
          adMessages={expandedPlaceAdMessages}
          onBack={() => setExpandedPlace(undefined)} />
      : null}
    </>
  )
}

type CategoriesProps = {
  place: ConsumerApi.PlaceDto
  onClickPlace: (place: ConsumerApi.PlaceBasicDto) => void
}

type CategorySelector = {
  dto: ConsumerApi.CategoryTreeDto,
  categories?: CategorySelector[],
  places?: ConsumerApi.PlaceBasicDto[]
  expanded: boolean
}

const Categories: React.FC<CategoriesProps> = (props) => {

  const { t } = useTranslation();

  const places = useMemo<ConsumerApi.PlaceBasicDto[]>(() =>
    props.place.places ? props.place.places : [], [props.place]);

  const [categories, setCategories] = useState<CategorySelector[]>(() => {

    // Convert Categories DTO to CategorySelector.
    const selectors = places.flatMap<CategorySelector>(p => {
      return p.categories.map<CategorySelector>(c => ({
        dto: c,
        categories: c.children && c.children.length > 0 ? c.children.map(c => ({
          dto: c,
          places: [p], // One place by category. This changes with the merge function
          expanded: false
        })) : undefined,
        expanded: false,
        places: !c.children || c.children.length === 0 ? [p] : undefined
      } as CategorySelector))
    });

    // Normalize Categories DTO, with children categories and places
    const merge = (selectors: CategorySelector[]): CategorySelector[] => {
      return selectors.reduce<CategorySelector[]>((_selectors, s) => {
        let selector = _selectors.find(_s => _s.dto.absoluteSlug === s.dto.absoluteSlug)
        if (selector) { // If selector already was added, then proceed to add the Place if it's required.
          if (s.places && s.places.length > 0) {
            if (!selector.places) {
              selector.places = [];
            }
            // The current CategorySelector only contains one place
            const placeToInsert = (s.places as ConsumerApi.PlaceBasicDto[])[0];
            const containsPlace = selector.places.some(p => p.id === placeToInsert.id);
            // If place to insert doesn't exist then add it alphabetically
            if (!containsPlace) {
              const indexAfter = selector.places.findIndex(_p => _p.name > placeToInsert.name);
              if (indexAfter === -1) {
                selector.places.push(placeToInsert);
              } else {
                selector.places.splice(indexAfter, 0, placeToInsert);
              }
            }
          }
        } else { // If selector is not added yet, then add it using the position for the order
          selector = s;
          const indexAfter = _selectors.findIndex(_s => (_s.dto.position as number) > ((selector as CategorySelector).dto.position as number));
          if (indexAfter === -1) {
            _selectors.push(selector);
          } else {
            _selectors.splice(indexAfter, 0, selector)
          }
        }

        // Merge children categories
        if (s.categories && s.categories.length > 0) {
          if (!selector.categories) {
            selector.categories = [];
          }
          selector.categories = merge([...selector.categories, ...s.categories])
        }

        return _selectors;
      }, [])
    }
    return merge(selectors);
  });

  const [displayFloor, setDisplayFloor] = useState(false);

  const [otherExpanded, setOtherExpanded] = useState(false);

  const toggleCategory = useCallback((category: CategorySelector) => {

    const newCategories = [...categories];
    newCategories.splice(
      newCategories.findIndex(c => c.dto.absoluteSlug === category.dto.absoluteSlug),
      1,
      {...category, expanded: !category.expanded} as CategorySelector)
    setCategories(newCategories);
  }, []);

  const toggleSubCategory = useCallback((category: CategorySelector, subCategory: CategorySelector) => {
    const newCategories = [...categories];
    const categoryIndex = newCategories.findIndex(c => c.dto.absoluteSlug === category.dto.absoluteSlug);

    const newSubCategories = [...newCategories[categoryIndex].categories as CategorySelector[]];
    newSubCategories.splice(
      newSubCategories.findIndex(c => c.dto.absoluteSlug === subCategory.dto.absoluteSlug),
      1,
      {...subCategory, expanded: !subCategory.expanded} as CategorySelector)

    newCategories.splice(categoryIndex, 1, {...category, categories: newSubCategories} as CategorySelector)
    setCategories(newCategories);
  }, []);

  useEffect(() => {
    const hasExpandedWithFloor = (categories: CategorySelector[]): boolean => {
      const expanded = categories.find(c => c.expanded);
      if (expanded) {
        if (expanded.places && expanded.places.length > 0) {
          return true;
        } else if (expanded.categories) {
          return hasExpandedWithFloor(expanded.categories);
        }
      }
      return false;
    }

    setDisplayFloor(hasExpandedWithFloor(categories));
  }, [categories])

  return (
    <div>
      <div className="flex justify-between py-[15px]">
        <div className="text-body-bold">
          {t("Categories")}
        </div>
        {displayFloor ?
          <div className="text-body-bold">
            {t("Floor")}
          </div>
        : null}
      </div>
      {categories.map((c, i) =>
        <div key={i} className="py-[8px]">
          <Item
            title={c.dto.name}
            onClick={() => toggleCategory(c)}
            count={c.expanded ? undefined : c.categories?.length || c.places?.length} />
          {c.expanded ?
            c.categories ?
              c.categories.map((sc, j) =>
                <div key={`${i}_${j}`} className="pl-[10px] py-[8px]">
                  <Item
                    title={sc.dto.name}
                    onClick={() => toggleSubCategory(c, sc)}
                    count={sc.expanded ? undefined : sc.places?.length} />
                  {sc.expanded && sc.places ?
                    sc.places.map((p, k) =>
                      <div key={`${i}_${j}_${k}`} className="pl-[10px] py-[8px]">
                        <Item
                          title={p.name + ' ' + p.id}
                          floor={p.floor}
                          onClick={() => props.onClickPlace(p)} />
                      </div>
                    )
                  : null
                  }
                </div>
              )
            : c.places ?
              c.places.map((p, j) =>
                <div key={`${i}_${j}`} className="pl-[10px] py-[8px]">
                  <Item
                    title={p.name}
                    floor={p.floor}
                    onClick={c.dto.absoluteSlug !== "other" ? () => props.onClickPlace(p) : undefined} />
                </div>
              )
            : null
          : null}
        </div>
      )}
      {props.place.otherServices && props.place.otherServices.length > 0 ?
        <div className="py-[8px]">
          <Item
            title={t("Other")}
            onClick={() => setOtherExpanded(!otherExpanded)}
            count={otherExpanded ? undefined : props.place.otherServices.length} />
          {otherExpanded ?
            props.place.otherServices.map((os, i) =>
              <div key={i} className="pl-[10px] py-[8px]">
                <Item
                  title={os.serviceName}
                  floor={os.floor} />
              </div>
            )
          : null}
        </div>
      : null}
    </div>
  )
}

type BrandSelector = {
  dto: ConsumerApi.BrandDto | ConsumerApi.PlaceCustomBrandDto,
  places?: ConsumerApi.PlaceBasicDto[]
  expanded: boolean
}

type BrandsProps = {
  place: ConsumerApi.PlaceDto
  onClickPlace: (place: ConsumerApi.PlaceBasicDto) => void
}

const Brands: React.FC<BrandsProps> = (props) => {

  const { t } = useTranslation();

  const [displayFloor, setDisplayFloor] = useState(false);

  const [brands, setBrands] = useState<BrandSelector[]>(() => props.place.places?.reduce((selectors, p) => {

    p.brands.forEach(b => {

      const brandName = b.brand ? b.brand?.name : b.placeCustomBrand ? b.placeCustomBrand.name : undefined;
      if (brandName === undefined) {
        throw `A brand or placeCustomBrand is required ${JSON.stringify(p)}`;
      }

      let selector = selectors.find(s => s.dto.name === brandName)
      if (selector) { // If selector already was added, then proceed to add the Place if it's required.
        if (selector.places && selector.places.length > 0) {
          if (!selector.places) {
            selector.places = [];
          }
          const containsPlace = selector.places.some(_p => _p.id === p.id);
          // If the inserting place doesn't exist then add it alphabetically
          if (!containsPlace) {
            const indexAfter = selector.places.findIndex(_p => _p.name > p.name);
            if (indexAfter === -1) {
              selector.places.push(p);
            } else {
              selector.places.splice(indexAfter, 0, p);
            }
          }
        }
      } else { // If selector is not added yet, then add it using the position for the order
        selector = {
          dto: b.brand || b.placeCustomBrand,
          places: [p],
          expanded: false
        } as BrandSelector;
        const indexAfter = selectors.findIndex(_s => _s.dto.name > brandName);
        if (indexAfter === -1) {
          selectors.push(selector);
        } else {
          selectors.splice(indexAfter, 0, selector)
        }
      }

    })

    return selectors
  }, [] as BrandSelector[]) || []);

  const toggleBrand = useCallback((brand: BrandSelector) => {
    const newBrands = [...brands];
    newBrands.splice(
      newBrands.findIndex(c => c.dto.name === brand.dto.name),
      1,
      {...brand, expanded: !brand.expanded} as BrandSelector)
    setBrands(newBrands);
  }, []);

  const renderBrands = useCallback(() => {
    let letter = "";
    return brands.map((b, i) =>
      <React.Fragment key={i}>
        {letter !== b.dto.name[0] ? (() => {
          letter = b.dto.name[0];
          return (
            <div className="style-subheading uppercase pt-[10px]">
              {letter}
            </div>
          )
        })() : null}
        <div className="py-[8px]">
          <Item
            title={b.dto.name}
            onClick={() => toggleBrand(b)} />
          {b.expanded && b.places ?
            b.places.map((p, j) =>
              <div key={`${i}-${j}`} className="pl-[10px] py-[8px]">
                <Item
                  title={p.name}
                  floor={p.floor}
                  onClick={() => props.onClickPlace(p)} />
              </div>
            )
          : null}
        </div>
      </React.Fragment>
    )
  }, [brands]);

  useEffect(() =>
    setDisplayFloor(brands.some(b => b.expanded && b.places && b.places.length > 0))
  , [brands])

  return (
    <div>
      <div className="flex justify-between py-[15px]">
        <div className="text-body-bold">
          {t("Brands")}
        </div>
        {displayFloor ?
          <div className="text-body-bold">
            {t("Floor")}
          </div>
        : null}
      </div>
      {renderBrands()}
    </div>
  )

}

type PlacesProps = {
  place: ConsumerApi.PlaceDto
  onClickPlace: (place: ConsumerApi.PlaceBasicDto) => void
}

const Places: React.FC<PlacesProps> = (props) => {

  const { t } = useTranslation();

  const places = useMemo<ConsumerApi.PlaceBasicDto[]>(() =>
    props.place.places ? props.place.places : [], [props.place]);

  return (
    <div>
      <div className="flex justify-between py-[15px]">
        <div className="text-body-bold">
          {t("Places")}
        </div>
        <div className="text-body-bold">
          {t("Floor")}
        </div>
      </div>
      {places.map((p, i) =>
        <div key={p.guid} className="py-[8px]">
          <Item
            title={p.name}
            onClick={() => props.onClickPlace(p)}
            floor={p.floor} />
        </div>
      )}
      {props.place.otherServices && props.place.otherServices.length > 0 ?
        props.place.otherServices.map((os, i) =>
          <div key={i} className="py-[8px]">
            <Item
              title={os.serviceName}
              floor={os.floor} />
          </div>
        )
      : null}
    </div>
  )

}

type ItemProps = {
  title: string
  count?: number
  floor?: string
  onClick?: () => void
}

const Item: React.FC<ItemProps> = (props) => {

  return (
    <div onClick={props.onClick} className={`h-[24px] flex items-center ${props.onClick ? "cursor-pointer" : ""}`}>
      <div className={`flex-none text-body ${props.onClick ? "text-blue" : ""}`}>
        {props.title}
      </div>
      <div className="flex-auto">
        {props.count !== undefined ?
          <div className="rounded-full h-[20px] w-[20px] ml-[12px] border text-blue text-body flex items-center justify-center">
            {props.count}
          </div>
        : null}
      </div>
      <div className="flex-none text-body">
        {props.floor}
      </div>
    </div>
  )

}