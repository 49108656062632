import React from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../overlay';

type ConfirmUnbookmarkProps = {
  onClose: () => void
  onClickConfirm: () => void
}

export const ConfirmUnbookmark: React.FC<ConfirmUnbookmarkProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z" stroke="#D2D2D2" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M38 17V40L29.001 33L20 40V17H38Z" fill="#262525"/>
          </svg>
        </div>
        <div
          className="font-bold mt-[25px] text-20 text-center">
          {t("Are you sure you want to Unbookmark")}
        </div>
        <button
          onClick={props.onClickConfirm}
          className="bg-blue text-white mt-[25px] style-subtitle h-11 w-full rounded">
          {t("Confirm")}
        </button>
      </div>
    </Overlay>
  )
}