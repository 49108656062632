import React, { useMemo } from "react";
import { ConsumerApi } from "ConsumerApi.dto";


type PlaceLogoProps = {
  onClick?: () => void
  place: ConsumerApi.PlaceBasicDto
}

export const PlaceLogo: React.FC<PlaceLogoProps> = (props) => {

  const initials = useMemo(() => {
    let initials = "";
    for (const name of props.place.name.split(" ")) {
      initials += name.charAt(0).toUpperCase();
      if (initials.length === 2) {
        break;
      }
    }
    return initials;
  }, [props.place])

  return (
    <div
      className={`rounded-full border-gray border-2 w-8 h-8 flex-none flex items-center justify-center ${props.onClick ? "cursor-pointer" : ""}`}
      onClick={props.onClick}>
      {props.place.logoUrl ?
        <img alt={props.place.name} src={props.place.logoUrl.small} className="rounded-full" />
      :
        <div className="style-body-large text-near-black ">
          {initials}
        </div>
      }
    </div>
  )
}