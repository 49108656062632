import React from "react";

export const Visa = () => {
  return (
    <svg width="52" height="37" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66667 0.125H49.3333C50.8061 0.125 52 1.4346 52 3.05V33.7625C52 35.378 50.8061 36.6875 49.3333 36.6875H2.66667C1.19393 36.6875 0 35.378 0 33.7625V3.05C0 1.4346 1.19393 0.125 2.66667 0.125Z" fill="#0E4595"/>
      <path d="M19.5467 24.5821L21.7707 11.5314H25.3281L23.1024 24.5821H19.5467ZM35.954 11.8128C35.2493 11.5484 34.1449 11.2646 32.7659 11.2646C29.2509 11.2646 26.7749 13.0346 26.7539 15.5715C26.7339 17.4468 28.5215 18.493 29.8707 19.1172C31.2554 19.7569 31.7208 20.1649 31.7143 20.7361C31.7055 21.6109 30.6085 22.0105 29.5861 22.0105C28.1623 22.0105 27.4059 21.8127 26.2377 21.3255L25.7793 21.1181L25.28 24.0396C26.1109 24.4039 27.6473 24.7195 29.2425 24.7359C32.9819 24.7359 35.4092 22.9861 35.4369 20.277C35.4502 18.7924 34.5025 17.6626 32.4502 16.7312C31.2069 16.1275 30.4454 15.7246 30.4535 15.1133C30.4535 14.5708 31.098 13.9907 32.4906 13.9907C33.6539 13.9727 34.4966 14.2264 35.1531 14.4907L35.4719 14.6414L35.954 11.8128V11.8128ZM45.108 11.5312H42.3592C41.5077 11.5312 40.8704 11.7637 40.4965 12.6135L35.2135 24.5735H38.9489C38.9489 24.5735 39.5596 22.9653 39.6977 22.6123C40.1061 22.6123 43.7347 22.6179 44.2536 22.6179C44.36 23.0748 44.6863 24.5735 44.6863 24.5735H47.9872L45.108 11.5308V11.5312ZM40.7468 19.9586C41.0409 19.2067 42.1641 16.3104 42.1641 16.3104C42.1431 16.3452 42.4561 15.5548 42.6357 15.0648L42.8761 16.19C42.8761 16.19 43.5573 19.3053 43.6997 19.9586H40.7468ZM16.5268 11.5312L13.0441 20.4313L12.673 18.6226C12.0247 16.5377 10.0047 14.2789 7.74646 13.148L10.9309 24.5614L14.6946 24.5571L20.2949 11.5311H16.5268" fill="white"/>
      <path d="M9.79468 11.5308H4.05861L4.01321 11.8023C8.47575 12.8826 11.4285 15.4932 12.6545 18.6297L11.4072 12.6323C11.1919 11.806 10.5674 11.5594 9.79481 11.5305" fill="#F2AE14"/>
    </svg>
  )
}