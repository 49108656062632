import React from "react";

export const AmericanExpress = () => {
  return (
    <svg width="52" height="37" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4.1875C0 1.94384 1.81884 0.125 4.0625 0.125H47.9375C50.1812 0.125 52 1.94384 52 4.1875V18.768C52 19.4722 51.7373 20.1512 51.2632 20.672L51.1134 20.8366C50.172 21.8709 50.2184 23.4649 51.2184 24.4427C51.7182 24.9314 52 25.601 52 26.3001V32.625C52 34.8687 50.1812 36.6875 47.9375 36.6875H4.0625C1.81884 36.6875 0 34.8687 0 32.625V18.6481C0 18.2895 0.162046 17.95 0.440911 17.7245L0.469442 17.7015C1.05096 17.2313 1.04133 16.3415 0.44979 15.884C0.166092 15.6646 0 15.3261 0 14.9675V4.1875Z" fill="#016FD0"/>
      <path d="M10.0693 28.2351V20.0711H18.6919L19.6171 21.2802L20.5728 20.0711H51.8709V27.6721C51.8709 27.6721 51.0524 28.2269 50.1058 28.2351H32.7753L31.7323 26.9482V28.2351H28.3144V26.0383C28.3144 26.0383 27.8475 26.3449 26.8381 26.3449H25.6747V28.2351H20.4996L19.5758 27.0002L18.6379 28.2351H10.0693Z" fill="white"/>
      <path d="M0 13.918L1.94444 9.37355H5.30715L6.41065 11.9192V9.37355H10.5908L11.2477 11.2134L11.8846 9.37355H30.6492V10.2985C30.6492 10.2985 31.6356 9.37355 33.2567 9.37355L39.3452 9.39488L40.4296 11.9072V9.37355H43.9278L44.8906 10.8167V9.37355H48.4208V17.5375H44.8906L43.9679 16.0897V17.5375H38.8283L38.3114 16.2506H36.9297L36.4213 17.5375H32.9358C31.5409 17.5375 30.6492 16.6314 30.6492 16.6314V17.5375H25.3939L24.3508 16.2506V17.5375H4.80899L4.29249 16.2506H2.91519L2.40234 17.5375H0V13.918Z" fill="white"/>
      <path d="M2.63266 10.38L0.0100291 16.4929H1.71749L2.2014 15.2688H5.01458L5.49598 16.4929H7.24106L4.62094 10.38H2.63266ZM3.60298 11.8026L4.46047 13.9417H2.74298L3.60298 11.8026Z" fill="#016FD0"/>
      <path d="M7.42159 16.4919V10.379L9.84799 10.388L11.2593 14.3292L12.6368 10.379H15.0438V16.4919H13.5193V11.9876L11.9034 16.4919H10.5665L8.94602 11.9876V16.4919H7.42159Z" fill="#016FD0"/>
      <path d="M16.0868 16.4919V10.379H21.0613V11.7463H17.6273V12.7919H20.981V14.0789H17.6273V15.1647H21.0613V16.4919H16.0868Z" fill="#016FD0"/>
      <path d="M21.9438 10.38V16.4929H23.4683V14.3212H24.1101L25.9379 16.4929H27.8009L25.795 14.2408C26.6182 14.1711 27.4674 13.4628 27.4674 12.3632C27.4674 11.0768 26.4602 10.38 25.3362 10.38H21.9438ZM23.4683 11.7473H25.2108C25.6288 11.7473 25.9329 12.0751 25.9329 12.3908C25.9329 12.7969 25.5389 13.0343 25.2334 13.0343H23.4683V11.7473Z" fill="#016FD0"/>
      <path d="M29.6462 16.4919H28.0897V10.379H29.6462V16.4919Z" fill="#016FD0"/>
      <path d="M33.337 16.4919H33.001C31.3754 16.4919 30.3884 15.208 30.3884 13.4606C30.3884 11.67 31.3643 10.379 33.4172 10.379H35.1021V11.8268H33.3556C32.5222 11.8268 31.9329 12.4787 31.9329 13.4756C31.9329 14.6595 32.6068 15.1567 33.5777 15.1567H33.9788L33.337 16.4919Z" fill="#016FD0"/>
      <path d="M36.6541 10.38L34.0315 16.4929H35.739L36.2229 15.2688H39.036L39.5174 16.4929H41.2625L38.6424 10.38H36.6541ZM37.6244 11.8026L38.4819 13.9417H36.7644L37.6244 11.8026Z" fill="#016FD0"/>
      <path d="M41.4405 16.4919V10.379H43.3787L45.8534 14.2196V10.379H47.3778V16.4919H45.5024L42.965 12.5507V16.4919H41.4405Z" fill="#016FD0"/>
      <path d="M11.1123 27.1895V21.0766H16.0868V22.4439H12.6528V23.4896H16.0066V24.7765H12.6528V25.8623H16.0868V27.1895H11.1123Z" fill="#016FD0"/>
      <path d="M35.4872 27.1895V21.0766H40.4617V22.4439H37.0277V23.4896H40.3654V24.7765H37.0277V25.8623H40.4617V27.1895H35.4872Z" fill="#016FD0"/>
      <path d="M16.2799 27.1895L18.7019 24.1707L16.2222 21.0766H18.1428L19.6196 22.9894L21.1014 21.0766H22.9467L20.4996 24.133L22.9261 27.1895H21.0058L19.5719 25.3068L18.1729 27.1895H16.2799Z" fill="#016FD0"/>
      <path d="M23.1072 21.0776V27.1905H24.6718V25.2601H26.2764C27.6342 25.2601 28.6634 24.538 28.6634 23.1337C28.6634 21.9703 27.8562 21.0776 26.4745 21.0776H23.1072ZM24.6718 22.46H26.3617C26.8003 22.46 27.1139 22.7295 27.1139 23.1638C27.1139 23.5718 26.8019 23.8676 26.3567 23.8676H24.6718V22.46Z" fill="#016FD0"/>
      <path d="M29.3253 21.0766V27.1895H30.8497V25.0178H31.4916L33.3194 27.1895H35.1823L33.1765 24.9374C33.9997 24.8677 34.8489 24.1594 34.8489 23.0597C34.8489 21.7734 33.8417 21.0766 32.7177 21.0766H29.3253ZM30.8497 22.4439H32.5923C33.0103 22.4439 33.3144 22.7717 33.3144 23.0874C33.3144 23.4935 32.9204 23.7309 32.6149 23.7309H30.8497V22.4439Z" fill="#016FD0"/>
      <path d="M41.1677 27.1895V25.8623H44.2186C44.67 25.8623 44.8655 25.6178 44.8655 25.3496C44.8655 25.0926 44.6707 24.8328 44.2186 24.8328H42.84C41.6416 24.8328 40.9742 24.1008 40.9742 23.0019C40.9742 22.0218 41.5854 21.0766 43.3661 21.0766H46.3348L45.6929 22.452H43.1254C42.6347 22.452 42.4836 22.7101 42.4836 22.9567C42.4836 23.2101 42.6703 23.4896 43.0452 23.4896H44.4894C45.8253 23.4896 46.405 24.2492 46.405 25.244C46.405 26.3135 45.759 27.1895 44.4167 27.1895H41.1677Z" fill="#016FD0"/>
      <path d="M46.7628 27.1895V25.8623H49.8136C50.2651 25.8623 50.4605 25.6178 50.4605 25.3496C50.4605 25.0926 50.2657 24.8328 49.8136 24.8328H48.435C47.2366 24.8328 46.5692 24.1008 46.5692 23.0019C46.5692 22.0218 47.1804 21.0766 48.9612 21.0766H51.9298L51.2879 22.452H48.7205C48.2297 22.452 48.0786 22.7101 48.0786 22.9567C48.0786 23.2101 48.2653 23.4896 48.6402 23.4896H50.0844C51.4203 23.4896 52 24.2492 52 25.244C52 26.3135 51.354 27.1895 50.0117 27.1895H46.7628Z" fill="#016FD0"/>
    </svg>
  )
}
