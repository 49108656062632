import React from "react";

export const CuisinesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4705 5.448C16.7504 5.39265 17.0378 5.36101 17.3328 5.36101C19.9123 5.36101 22 7.54369 22 10.2404C22 12.5022 20.5325 14.4002 18.5356 14.9537V17.7691H5.46445V14.9537C3.47504 14.4002 2 12.5022 2 10.2404C2 7.54369 4.09531 5.36101 6.67474 5.36101C6.96974 5.36101 7.25719 5.39265 7.53707 5.448C8.12708 3.44721 9.89713 2 12 2C14.1029 2 15.8805 3.44721 16.4705 5.448Z" fill="currentColor"/>
      <path d="M5.46441 19.9043H18.5355V22H5.46441V19.9043Z" fill="currentColor"/>
    </svg>


  )
}
