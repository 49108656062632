{
  "Starts": "Starts",
  "Ends": "Ends",
  "View Ad": "View Ad",
  "More Information": "More Information",
  "View": "View",
  "Bookmark your Search": "Bookmark your Search",
  "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.": "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.",
  "Name your search result": "Name your search result",
  "Save Bookmark": "Save Bookmark",
  "Bookmarks": "Bookmarks",
  "Save": "Save",
  "Edit": "Edit",
  "Search name": "Search name",
  "Keep Bookmark": "Keep Bookmark",
  "Remove Bookmark": "Remove Bookmark",
  "Please enable location services for {{browserName}} and Website.": "Please enable location services for {{browserName}} and Website.",
  "Current Location": "Current Location",
  "Change Location": "Change Location",
  "Search Location": "Search Location",
  "Couldn't find your location": "Couldn't find your location",
  "Show me how to fix it": "Show me how to fix it",
  "Ok": "Ok",
  "Enable Location": "Enable Location",
  "Please make sure to enable location services for Safari and Website.": "Please make sure to enable location services for Safari and Website.",
  "Enable for Safari": "Enable for Safari",
  "Open Phone Settings": "Open Phone Settings",
  "Open Privacy": "Open Privacy",
  "Privacy": "Privacy",
  "Open Location Services": "Open Location Services",
  "Location Services": "Location Services",
  "Off": "Off",
  "Activate Location Services": "Activate Location Services",
  "Scroll Down and Tap Safari Websites": "Scroll Down and Tap Safari Websites",
  "Safari Websites": "Safari Websites",
  "Never": "Never",
  "Activate While Using the App": "Activate While Using the App",
  "While Using the App": "While Using the App",
  "Activate Precise Location": "Activate Precise Location",
  "Precise Location": "Precise Location",
  "Did it": "Did it",
  "Enable for Website": "Enable for Website",
  "Scroll Down and Tap Safari": "Scroll Down and Tap Safari",
  "Safari": "Safari",
  "Scroll Down and Tap Location": "Scroll Down and Tap Location",
  "Location": "Location",
  "When LOCATION ACCESS ON": "When LOCATION ACCESS ON",
  "has the entry below": "has the entry below",
  "Select it and Allow Location": "Select it and Allow Location",
  "Allow": "Allow",
  "OTHER/ALL WEBSITES": "OTHER/ALL WEBSITES",
  "Allow Location": "Allow Location",
  "Please make sure to enable location services for Website and Chrome.": "Please make sure to enable location services for Website and Chrome.",
  "Open Chrome Menu": "Open Chrome Menu",
  "Tap Settings ": "Tap Settings ",
  "Settings": "Settings",
  "Scroll Down and Tap Site settings": "Scroll Down and Tap Site settings",
  "Site Settings": "Site Settings",
  "Tap Location": "Tap Location",
  "Ask first": "Ask first",
  "Ask before allowing sites to know your location (Recomended)": "Ask before allowing sites to know your location (Recomended)",
  "Enable for Chrome": "Enable for Chrome",
  "Tap App permissions": "Tap App permissions",
  "App permissions": "App permissions",
  "Scroll Down and Tap Chrome": "Scroll Down and Tap Chrome",
  "Chrome": "Chrome",
  "Activate Allow": "Activate Allow",
  "Allow all the time": "Allow all the time",
  "or allow while using the app": "or allow while using the app",
  "Allow while using the app": "Allow while using the app",
  "The OS is not supported": "The OS is not supported",
  "Well done! That was a chore, but now you can enjoy the App!": "Well done! That was a chore, but now you can enjoy the App!",
  "Use the App": "Use the App",
  "Invalid Link. Contact Customer Service.": "Invalid Link. Contact Customer Service.",
  "Verified E-mail": "Verified E-mail",
  "Your E-mail {{email}} has been verified.": "Your E-mail {{email}} has been verified.",
  "Are you sure you want to Unbookmark": "Are you sure you want to Unbookmark",
  "Confirm": "Confirm",
  "Accept to create a profile.": "Accept to create a profile.",
  "Create Profile": "Create Profile",
  "To BROVS ads, products and services you need to create a profile.": "To BROVS ads, products and services you need to create a profile.",
  "Gender *": "Gender *",
  "Female": "Female",
  "Male": "Male",
  "Create Profile Now": "Create Profile Now",
  "Required": "Required",
  "Invalid E-mail.": "Invalid E-mail.",
  "Minimum password length is 8 characters.": "Minimum password length is 8 characters.",
  "Repeat Password doesn't match.": "Repeat Password doesn't match.",
  "Accept to create a Profile.": "Accept to create a Profile.",
  "Address is required.": "Address is required.",
  "First Name": "First Name",
  "First Name *": "First Name *",
  "Last Name": "Last Name",
  "Last Name *": "Last Name *",
  "Mobile Number *": "Mobile Number *",
  "Continue": "Continue",
  "Type your E-mail": "Type your E-mail",
  "E-mail": "E-mail",
  "Don't have an E-mail?": "Don't have an E-mail?",
  "Type Password": "Type Password",
  "Create Password *": "Create Password *",
  "Repeat Password": "Repeat Password",
  "Home Location *": "Home Location *",
  "Home address is used to display nearby Businesses and Ads.": "Home address is used to display nearby Businesses and Ads.",
  "Are you sure you want to cancel Sign Up?": "Are you sure you want to cancel Sign Up?",
  "Verify Mobile Number": "Verify Mobile Number",
  "You will receive a SMS with a Short Code to verify your Mobile Number.": "You will receive a SMS with a Short Code to verify your Mobile Number.",
  "Mobile Number": "Mobile Number",
  "Is your Mobile Number below correct?": "Is your Mobile Number below correct?",
  "Edit if not correct.": "Edit if not correct.",
  "Check your SMS for Short Code.": "Check your SMS for Short Code.",
  "Short Code": "Short Code",
  "Type Short Code": "Type Short Code",
  "Didn't receive a Short Code?": "Didn't receive a Short Code?",
  "Resend Code": "Resend Code",
  "Save Food & Money": "Save Food & Money",
  "Ads": "Ads",
  "Places": "Places",
  "Searches": "Searches",
  "End Of Day Unsold Food And Beverages": "End Of Day Unsold Food And Beverages",
  "All Categories": "All Categories",
  "Choose <1>Brands</1> from the list below.": "Choose <1>Brands</1> from the list below.",
  "Filters": "Filters",
  "Apply": "Apply",
  "Search Brands": "Search Brands",
  "Brands": "Brands",
  "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.": "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.",
  "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.": "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.",
  "Brands Nearby": "Brands Nearby",
  "Selected Brands": "Selected Brands",
  "Merchandise": "Merchandise",
  "Cuisines": "Cuisines",
  "Show All": "Show All",
  "Meals": "Meals",
  "Price Level": "Price Level",
  "Takeaway": "Takeaway",
  "Type of Bar": "Type of Bar",
  "Specialties": "Specialties",
  "Profile": "Profile",
  "Opening Hour": "Opening Hour",
  "Payment Options": "Payment Options",
  "Parking": "Parking",
  "Bookmark": "Bookmark",
  "Filter": "Filter",
  "MAP": "MAP",
  "Shopping Malls": "Shopping Malls",
  "Search Places, Categories, Brands, etc.": "Search Places, Categories, Brands, etc.",
  "Shared With You": "Shared With You",
  "Selected Shopping Center": "Selected Shopping Center",
  "Selected Place": "Selected Place",
  "Free WiFi": "Free WiFi",
  "Outdoor Seating": "Outdoor Seating",
  "Wheelchair Accessible": "Wheelchair Accessible",
  "Open Now": "Open Now",
  "Open At": "Open At",
  "Search": "Search",
  "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you signed up with the referral link.": "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you signed up with the referral link.",
  "Check out Rewards in the menu <1></1> to spend the points!": "Check out Rewards in the menu <1></1> to spend the points!",
  "<0>You made an invite and tried to use it yourself.</0>": "<0>You made an invite and tried to use it yourself.</0>",
  "Invite someone else to earn more points. Find the link in the menu <1></1>.": "Invite someone else to earn more points. Find the link in the menu <1></1>.",
  "<0>The invite from {{referringUser}} is only for new users</0>, you were already signed up.": "<0>The invite from {{referringUser}} is only for new users</0>, you were already signed up.",
  "<0>The invite from {{referringUser}} has expired</0>, you were already signed up.": "<0>The invite from {{referringUser}} has expired</0>, you were already signed up.",
  "<0>The invite has expired</0>, and you were already signed up.": "<0>The invite has expired</0>, and you were already signed up.",
  "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you claimed a business with the referral link.": "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you claimed a business with the referral link.",
  "<0>You tried to use your own business referral for {{placeName}}</0>, instead send it to someone else. Then the both of you earn points that can be used in our lottery.": "<0>You tried to use your own business referral for {{placeName}}</0>, instead send it to someone else. Then the both of you earn points that can be used in our lottery.",
  "Check out Rewards in the menu <1></1> to earn and spend points!": "Check out Rewards in the menu <1></1> to earn and spend points!",
  "<0>{{referringUser}} invited you to manage {{placeName}}</0>, but that business is already claimed by someone else.": "<0>{{referringUser}} invited you to manage {{placeName}}</0>, but that business is already claimed by someone else.",
  "If that is incorrect contact support!. Find the link in the menu <1></1>.": "If that is incorrect contact support!. Find the link in the menu <1></1>.",
  "<0>The invite from {{referringUser}} has expired.</0>": "<0>The invite from {{referringUser}} has expired.</0>",
  "<0>The invite has expired.</0>": "<0>The invite has expired.</0>",
  "<0>{{referringUser}} got points</0> because you looked at the place shared. You can also share and win points that can be used in our lottery.": "<0>{{referringUser}} got points</0> because you looked at the place shared. You can also share and win points that can be used in our lottery.",
  "Check out Rewards in the menu <1></1> to learn more!": "Check out Rewards in the menu <1></1> to learn more!",
  "<0>You shared and tried to use it yourself</0>. Invite someone else to earn more points.": "<0>You shared and tried to use it yourself</0>. Invite someone else to earn more points.",
  "Find the link in the menu <1></1>.": "Find the link in the menu <1></1>.",
  "<0>The invite from {{referringUser}} has expired</0>. Invite someone else to earn more points.": "<0>The invite from {{referringUser}} has expired</0>. Invite someone else to earn more points.",
  "<0>The invite has expired</0>. Invite someone else to earn more points.": "<0>The invite has expired</0>. Invite someone else to earn more points.",
  "<0>{{referringUser}} got points</0> because you looked at the AD Message shared. You can also share and win points that can be used in our lottery.": "<0>{{referringUser}} got points</0> because you looked at the AD Message shared. You can also share and win points that can be used in our lottery.",
  "Got It": "Got It",
  "Type your Home Address": "Type your Home Address",
  "Street": "Street",
  "Number": "Number",
  "My address doesn't have a Street Number.": "My address doesn't have a Street Number.",
  "Postal Code": "Postal Code",
  "City": "City",
  "Change Home Address": "Change Home Address",
  "Search Address": "Search Address",
  "Type your home address": "Type your home address",
  "Happy Hour": "Happy Hour",
  "Invalid": "Invalid",
  "Number or Postal Code is not correct.": "Number or Postal Code is not correct.",
  "Home": "Home",
  "Tap <1><0></0></1> then Scroll Down": "Tap <1><0></0></1> then Scroll Down",
  "Tap <1>Add to Home Screen</1> <3><0></0></3>": "Tap <1>Add to Home Screen</1> <3><0></0></3>",
  "Tap <1>Add</1>": "Tap <1>Add</1>",
  "Tap BROVS Icon on Home Screen": "Tap BROVS Icon on Home Screen",
  "Add BROVS icon to Home Screen for easy access.": "Add BROVS icon to Home Screen for easy access.",
  "Done": "Done",
  "Norwegian": "Norwegian",
  "English US": "English US",
  "Set new password <1>here</1>.": "Set new password <1>here</1>.",
  "Create password <1>here</1>.": "Create password <1>here</1>.",
  "Sign In": "Sign In",
  "Password": "Password",
  "Sign In Now": "Sign In Now",
  "Forgot your password?": "Forgot your password?",
  "To Sign In with Mobile Number you need to create a Password.": "To Sign In with Mobile Number you need to create a Password.",
  "You have previously Signed In with Vipps.": "You have previously Signed In with Vipps.",
  "OR": "OR",
  "Continue with Mobile Number": "Continue with Mobile Number",
  "How many points do you want to<1></1>convert to Lottery tickets?": "How many points do you want to<1></1>convert to Lottery tickets?",
  "Get Lottery Tickets": "Get Lottery Tickets",
  "My Reward Points": "My Reward Points",
  "You don't have Reward Points to get Lottery tickets.": "You don't have Reward Points to get Lottery tickets.",
  "See how to Earn Reward Points.": "See how to Earn Reward Points.",
  "One point = One Lottery Ticket": "One point = One Lottery Ticket",
  "Get Now": "Get Now",
  "Cancel": "Cancel",
  "Congratulations!<1></1>You are now participating in the Lottery.": "Congratulations!<1></1>You are now participating in the Lottery.",
  "Lottery": "Lottery",
  "My Tickets In This Lottery": "My Tickets In This Lottery",
  "Get More Lottery Tickets": "Get More Lottery Tickets",
  "Previous Lottery": "Previous Lottery",
  "Ended on": "Ended on",
  "Congratulations, you are the winner of this Lottery!": "Congratulations, you are the winner of this Lottery!",
  "Drawn Winner": "Drawn Winner",
  "New Version Available": "New Version Available",
  "Update Now": "Update Now",
  "Options": "Options",
  "Unbookmark": "Unbookmark",
  "Report Problem": "Report Problem",
  "Set New Password": "Set New Password",
  "Send Short Code": "Send Short Code",
  "Forgot your password?. No problem. You can create a new password below.": "Forgot your password?. No problem. You can create a new password below.",
  "Check SMS for sent Short Code.": "Check SMS for sent Short Code.",
  "Type New Password": "Type New Password",
  "New Password": "New Password",
  "Access {{placeName}}": "Access {{placeName}}",
  "Expires in {{claimBusinessLinkExpirationTime}} seconds.": "Expires in {{claimBusinessLinkExpirationTime}} seconds.",
  "Do you work at<br/>{{placeName}}?": "Do you work at<br/>{{placeName}}?",
  "Do you know someone who works at<br/>{{placeName}}?": "Do you know someone who works at<br/>{{placeName}}?",
  "Access Now": "Access Now",
  "Get access and manage the Business Profile for FREE. Update business name, opening hours, contact information, etc. Advertise to a large user audience to get new customers.": "Get access and manage the Business Profile for FREE. Update business name, opening hours, contact information, etc. Advertise to a large user audience to get new customers.",
  "Manage Now": "Manage Now",
  "Invite the owner or someone working here to manage the Business Profile for FREE. When they get access you will receive BROVS Reward Points that you can use in the BROVS lottery.": "Invite the owner or someone working here to manage the Business Profile for FREE. When they get access you will receive BROVS Reward Points that you can use in the BROVS lottery.",
  "Invite by SMS": "Invite by SMS",
  "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.\n{{url}}": "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.\n{{url}}",
  "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.": "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.",
  "Send Referral": "Send Referral",
  "SMS": "SMS",
  "Other": "Other",
  "Open": "Open",
  "Closes": "Closes",
  "Closed": "Closed",
  "Opens": "Opens",
  "Report a problem related to this business?": "Report a problem related to this business?",
  "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.": "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.",
  "This profile is managed by<br/>.": "This profile is managed by<br/>.",
  "Verified Profile": "Verified Profile",
  "Overview": "Overview",
  "Menu": "Menu",
  "Contact": "Contact",
  "Share": "Share",
  "Interested in Ads?": "Interested in Ads?",
  "You have expressed interest in Ads from this business.": "You have expressed interest in Ads from this business.",
  "Let Us Know": "Let Us Know",
  "Brands not added.": "Brands not added.",
  "Categories and Brands": "Categories and Brands",
  "Shopping Mall": "Shopping Mall",
  "Website": "Website",
  "Webshop": "Webshop",
  "Not added": "Not added",
  "WiFi": "WiFi",
  "Free": "Free",
  "Yes": "Yes",
  "No": "No",
  "Suitability": "Suitability",
  "Accessibility": "Accessibility",
  "Managed by": "Managed by",
  "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.": "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.",
  "Invite or Manage": "Invite or Manage",
  "Menu has not been added.": "Menu has not been added.",
  "Opening Hours": "Opening Hours",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
  "Categories": "Categories",
  "Floor": "Floor",
  "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.": "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.",
  "Privacy Policy": "Privacy Policy",
  "Terms of Service": "Terms of Service",
  "Date of Birth": "Date of Birth",
  "Day": "Day",
  "Month": "Month",
  "Year": "Year",
  "Photo": "Photo",
  "Invite Friends to join": "Invite Friends to join",
  "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}",
  "BROVS": "BROVS",
  "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.": "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.",
  "When your friends Sign's Up you will receive BROVS Points that you can use in the BROVS lottery. Invite as many friends as you like.": "When your friends Sign's Up you will receive BROVS Points that you can use in the BROVS lottery. Invite as many friends as you like.",
  "Business Referrals": "Business Referrals",
  "Text Message / SMS": "Text Message / SMS",
  "(Only Norwegian Numbers)": "(Only Norwegian Numbers)",
  "Points": "Points",
  "Invite Business Owner or Employee": "Invite Business Owner or Employee",
  "Congratulations!": "Congratulations!",
  "You earned BROVS Points just by signing up.<br/><br/>You can use your reward points for tickets in the BROVS lottery.": "You earned BROVS Points just by signing up.<br/><br/>You can use your reward points for tickets in the BROVS lottery.",
  "Earn Reward Points": "Earn Reward Points",
  "Friend Referrals": "Friend Referrals",
  "Shared Places": "Shared Places",
  "Shared AD Messages": "Shared AD Messages",
  "Sign Up Bonus": "Sign Up Bonus",
  "Invite Friends": "Invite Friends",
  "When you share and refer you get points. These points can be used to participate in our monthly lotteries.": "When you share and refer you get points. These points can be used to participate in our monthly lotteries.",
  "Number of Contributions": "Number of Contributions",
  "Expiration of Points by 31. December": "Expiration of Points by 31. December",
  "My Rewards": "My Rewards",
  "Use AD Messages Share Icon": "Use AD Messages Share Icon",
  "Use Place Share Icon": "Use Place Share Icon",
  "Use Reward Points": "Use Reward Points",
  "Account": "Account",
  "Add your E-mail for communication and promotions.": "Add your E-mail for communication and promotions.",
  "Verified": "Verified",
  "Not Verified.": "Not Verified.",
  "Send Short Code Verification to E-mail.": "Send Short Code Verification to E-mail.",
  "Short Code Verification sent to above E-mail.": "Short Code Verification sent to above E-mail.",
  "Have you not received an E-mail with the Short Code Verification?": "Have you not received an E-mail with the Short Code Verification?",
  "Language": "Language",
  "Change Password": "Change Password",
  "Add Password": "Add Password",
  "Are you sure you want to change Password?": "Are you sure you want to change Password?",
  "Personal Information *": "Personal Information *",
  "Gender": "Gender",
  "Invalid Mobile Number.": "Invalid Mobile Number.",
  "Change if not correct.": "Change if not correct.",
  "Verified.": "Verified.",
  "Check your SMS for Short Code": "Check your SMS for Short Code",
  "Confirm Mobile Number": "Confirm Mobile Number",
  "Crop width or height is not set": "Crop width or height is not set",
  "Could not get canvas context": "Could not get canvas context",
  "Canvas is empty": "Canvas is empty",
  "Image not found.": "Image not found.",
  "Crop dimensions not set.": "Crop dimensions not set.",
  "Error decoding image.": "Error decoding image.",
  "Profile Photo": "Profile Photo",
  "Upload New": "Upload New",
  "Upload": "Upload",
  "Delete": "Delete",
  "Crop Photo": "Crop Photo",
  "Sure you want to delete?": "Sure you want to delete?",
  "Sign Out": "Sign Out",
  "Delete Account": "Delete Account",
  "Are you sure you want to delete Your Account?": "Are you sure you want to delete Your Account?",
  "Delete My Account": "Delete My Account",
  "Hi :-) you might have an interest in this ad {{adTitle}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hi :-) you might have an interest in this ad {{adTitle}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}",
  "Hi :-) you might have an interest in this ad {{adTitle}} {{adDescription}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hi :-) you might have an interest in this ad {{adTitle}} {{adDescription}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}",
  "Share this AD Message": "Share this AD Message",
  "Hi :-) check out {{name}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hi :-) check out {{name}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}",
  "Share this profile": "Share this profile",
  "Manage Business": "Manage Business",
  "Rewards": "Rewards",
  "Invite a friend": "Invite a friend",
  "Business": "Business",
  "The invite has expired.": "The invite has expired.",
  "The invite from {{firstName}} {{lastName}} has expired.": "The invite from {{firstName}} {{lastName}} has expired.",
  "Sign up NOW and the both of you earn points to WIN our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Sign up NOW and the both of you earn points to WIN our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.",
  "Sign up NOW to claim and manage your business and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Sign up NOW to claim and manage your business and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.",
  "Sign up NOW to see it and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Sign up NOW to see it and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.",
  "Sign up NOW to view the AD Message.<br/><br/>You and the person inviting you will both earn Reward Points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Sign up NOW to view the AD Message.<br/><br/>You and the person inviting you will both earn Reward Points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.",
  "Browse ads, products and<br/>services at local businesses": "Browse ads, products and<br/>services at local businesses",
  "invites you to use this App.": "invites you to use this App.",
  "invites you to manage your business {{businessName}}.": "invites you to manage your business {{businessName}}.",
  "wants to share with you": "wants to share with you",
  "Fashion": "Fashion",
  "Food & Drink": "Food & Drink",
  "Easy and FREE Sign In": "Easy and FREE Sign In"
}
