import React from "react";

export const Nightclub = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.06348 4.82544H22L14.2056 14.0244V19.9155H18.52C19.9674 19.9155 19.9798 22 18.52 22H7.75763C6.2979 22 6.2979 19.9155 7.75763 19.9155H11.9989V13.9791L4.06348 4.82544Z" fill="currentColor"/>
      <path d="M14.4625 11.3166C15.231 11.3166 15.8542 10.7213 15.8542 9.9869C15.8542 9.25258 15.231 8.65712 14.4625 8.65712C13.6938 8.65712 13.0707 9.25258 13.0707 9.9869C13.0707 10.7213 13.6938 11.3166 14.4625 11.3166Z" fill="currentColor"/>
      <path d="M16.3351 8.85543C17.1888 8.85543 17.8809 8.19415 17.8809 7.37843C17.8809 6.5627 17.1888 5.90142 16.3351 5.90142C15.4813 5.90142 14.7892 6.5627 14.7892 7.37843C14.7892 8.19415 15.4813 8.85543 16.3351 8.85543Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.335 5.94483C15.5063 5.94483 14.8346 6.58667 14.8346 7.37843C14.8346 8.17018 15.5063 8.81203 16.335 8.81203C17.1637 8.81203 17.8355 8.17018 17.8355 7.37843C17.8355 6.58667 17.1637 5.94483 16.335 5.94483ZM14.7437 7.37843C14.7437 6.53873 15.4562 5.85802 16.335 5.85802C17.2139 5.85802 17.9263 6.53873 17.9263 7.37843C17.9263 8.21813 17.2139 8.89884 16.335 8.89884C15.4562 8.89884 14.7437 8.21813 14.7437 7.37843Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.9246 2L8.96689 5.2874L8.50402 5.67844L5.46173 2.39104L5.9246 2Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.91351 2.60301H2V2.01268H5.91351V2.60301Z" fill="currentColor"/>
    </svg>

  )
}
