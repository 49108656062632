import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useFinderParams } from '../hooks/UseFinderParams';
import { FindMode } from 'model';
import { FinderSelected } from './FinderSelected';
import { FinderNavigate } from './FinderNavigate';
import { FinderFiltered } from './FinderFiltered';
import { FinderSearch } from './FinderSearch';

export const Finder: React.FC = () => {

  const { paramPlaceGuid, paramAdMessageGuid } = useFinderParams();
  const { paramFindMode } = useParams<any>();

  const findMode = useMemo<FindMode>(() => {
    if (!paramFindMode) {
      if (paramPlaceGuid || paramAdMessageGuid) {
        return "selected";
      } else {
        return "navigate";
      }
    } else if (paramFindMode === "search") {
      return "search";
    } else {
      return "filtered"
    }
  }, [paramFindMode, paramPlaceGuid, paramAdMessageGuid]);

  return (
    <>
      { findMode === "navigate" ?
        <FinderNavigate />
      : findMode === "filtered" ?
        <FinderFiltered />
      : findMode === "search" ?
        <FinderSearch />
      : findMode === "selected" ?
        <FinderSelected />
      : null}
    </>
  );
};
