import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from "../ConsumerApi.dto";
import { CategoryIcon } from '../icons/category';

type PlaceCategoriesProps = {
  place: ConsumerApi.PlaceDto
}

class CategoryAndBrand {
  constructor(public readonly category: ConsumerApi.CategoryDto, public readonly brandNames: string[]) {}
}

export const PlaceCategories: React.FC<PlaceCategoriesProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;

  const [brandNames, setBrandNames] = useState<string[]>();
  const [categoriesAndBrands, setCategoriesAndBrands] = useState<CategoryAndBrand[]>();

  const rootCategories = useMemo(() => place.categories.flatMap(c => c.children || []), [place])

  const [selectedRootCategory, setSelectedRootCategory] = useState<ConsumerApi.CategoryTreeDto | undefined>(
    rootCategories && rootCategories.length > 0 ? rootCategories[0] : undefined);

  useEffect(() => {
    if (selectedRootCategory) {
      const brandNames = place.brands
        .filter(b => b.categoryAbsoluteSlugs?.some(slug =>
          slug === selectedRootCategory.absoluteSlug ||
          slug.startsWith(selectedRootCategory.absoluteSlug + ".")))
        .map(b => b.brand ? b.brand.name : b.placeCustomBrand ? b.placeCustomBrand.name : "")
        .filter((v, i, self) => self.indexOf(v) === i); // unique

      setBrandNames(brandNames);

      const categoryAndBrands = (selectedRootCategory.children || [])
        .map(c => new CategoryAndBrand(c,
          place.brands
            .filter(b =>
              b.categoryAbsoluteSlugs?.some(slug => slug === c.absoluteSlug ||
                slug.startsWith(c.absoluteSlug + ".")))
            .map(b => b.brand ? b.brand.name : b.placeCustomBrand ? b.placeCustomBrand.name : "")
            .filter((v, i, self) => self.indexOf(v) === i) // unique
          ));

      setCategoriesAndBrands(categoryAndBrands);
    }
  }, [place, selectedRootCategory]);

  return (
    <div className="flex flex-col px-[12px]">
      <div className="flex py-[15px] overflow-x-scroll">
        {rootCategories.map((c, i) =>
          <div
            onClick={() => setSelectedRootCategory(c)}
            key={c.absoluteSlug}
            className={`flex px-[12px] py-[10px] mr-[10px] items-center cursor-pointer rounded border whitespace-nowrap ${selectedRootCategory === c ? 'bg-blue text-white border-blue' : 'border-gray-lighther'}`}>
            <div><CategoryIcon slug={c.absoluteSlug} /></div>
            <div className="text-title-bold pl-[7px]">{c.name}</div>
          </div>
        )}
      </div>
      <div className="flex flex-col border-b border-gray-lighther pb-[15px]">
        <div className="text-title-bold">
          {t("Brands")}
        </div>
        <div className="mt-[10px] text-body whitespace-pre-wrap">
          {brandNames && brandNames.length > 0 ? brandNames.join("   |   ") : t("Brands not added.")}
        </div>
      </div>
      <div className="flex flex-col pt-[15px]">
        <div className="text-title-bold pb-[15px]">
          {t("Categories and Brands")}
        </div>
        {categoriesAndBrands && categoriesAndBrands.length > 0 ? categoriesAndBrands.map((cb, i) =>
          <div key={cb.category.absoluteSlug} className="flex flex-col text-body pb-[15px]">
            <div className="text-body-bold">
              {cb.category.name}
            </div>
            <div className="mt-[5px] text-body whitespace-pre-wrap">
              {cb.brandNames.length > 0 ? cb.brandNames.join("   |   ") : t("Brands not added.")}
            </div>
          </div>)
        :
          <div className="flex flex-col text-body pb-[15px]">
            {t("Brands not added.")}
          </div>
        }
      </div>
    </div>
  )
}