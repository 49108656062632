import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { Overlay } from '../overlay';
import { Button } from '../button';
import { earnedPointsBySignupState } from 'atoms/earnedPointsBySignup';

export const EarnedPointsBySignup: React.FC = () => {

  const { t } = useTranslation()
  const setEarnedPointsBySignupState = useSetRecoilState(earnedPointsBySignupState);

  return (
    <Overlay onClose={() => setEarnedPointsBySignupState(false)}>
      <div className="flex flex-col items-center">
        <div className="font-bold text-20 text-center max-w-280px line-clamp-3 mt-[25px]">
          <Trans>Congratulations!</Trans>
        </div>
        <div className="center mt-[15px] style-body-copy text-center">
          <Trans>You earned BROVS Points just by signing up.<br /><br />You can use your reward points for tickets in the BROVS lottery.</Trans>
        </div>
        <div className="mt-[25px] w-full">
          <Button
            onClick={() => setEarnedPointsBySignupState(false)}
            type="button"
            title={t("Continue")}
            target="_blank" />
        </div>
      </div>
    </Overlay>
  )
}