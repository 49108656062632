import React from "react";

export const AdMessageInterestIcon = () => {
  return (
    <svg width="59" height="91" viewBox="0 0 59 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.8275 0.962158V17.5345H26.8275V0.962158H24.8275ZM49.0577 61.7798C49.0577 59.2012 51.1582 57.0942 53.7288 57.0942C56.2995 57.0942 58.3686 59.2012 58.3059 61.8112V75.6162V85.9936C58.3059 85.9936 58.3999 90.9936 52.4748 90.9936H24.2602C22.4419 91.0879 20.655 90.1446 19.7772 88.415L6.48494 61.8427C5.325 59.5156 6.26549 56.7169 8.55401 55.5534C10.8739 54.3898 13.664 55.3332 14.8239 57.6288L21.0312 70.0502V31.4968C21.0312 28.8867 23.1316 26.8112 25.7023 26.8112C28.2729 26.8112 30.3734 28.9181 30.3734 31.4968V52.6603C30.3734 50.0502 32.4738 47.9747 35.0444 47.9747C37.6465 47.9747 39.7155 50.0817 39.7155 52.6603V57.0313C39.7155 54.4213 41.816 52.3458 44.3866 52.3458C46.9573 52.3458 49.0577 54.4527 49.0577 57.0313V61.7798ZM49.2694 14.4202L35.9144 24.9548L34.6758 23.3846L48.0307 12.85L49.2694 14.4202ZM15.2865 23.3217L1.93152 12.7871L0.692871 14.3574L14.0478 24.892L15.2865 23.3217Z" fill="currentColor"/>
    </svg>

  );
}

export default AdMessageInterestIcon;
