import { atom, selector } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";

export const placeBookmarksState = atom<ConsumerApi.PlaceDto[] | undefined>({
  key: 'placeBookmarksState',
  default: undefined
});

export const bookmarkPlaceState = selector<ConsumerApi.PlaceDto | undefined>({
  key: 'bookmarkPlaceState',
  get: () => undefined,
  set: ({ set, get }, place) => {
    if (place) {
      const p = place as ConsumerApi.PlaceDto;
      const places = [...(get(placeBookmarksState) || [])];
      const currentIndex = places.findIndex(_p => _p.id === p.id);

      if (currentIndex === -1) {
        set(placeBookmarksState, [p, ...places]);
      }
    }
  }
});

export const unbookmarkPlaceState = selector<ConsumerApi.PlaceDto | undefined>({
  key: 'unbookmarkPlaceState',
  get: () => undefined,
  set: ({ set, get }, place) => {
    if (place) {
      const p = place as ConsumerApi.PlaceDto;
      const places = [...(get(placeBookmarksState) || [])];
      const currentIndex = places.findIndex(_p => _p.id === p.id);

      if (currentIndex > -1) {
        places.splice(currentIndex, 1);
        set(placeBookmarksState, places);
      }
    }
  }
});