import { Trans, useTranslation } from "react-i18next"
import { isAndroid, isIOS } from 'react-device-detect';
import { CancelIcon } from "./icons";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiServiceState, skipSuggestPwaInstallationState, suggestPwaInstallationState } from "./atoms";
import { ConsumerApi } from "./ConsumerApi.dto";
import { logErrorOnServerAndConsole } from "./helpers/ErrorHelper";
import { Button } from "button";

type InstallAppInstructionsProps = {
  onDone: () => void
}

export const InstallAppInstructions: React.FC<InstallAppInstructionsProps> = (props) => {

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const arrowStartRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const setSuggestPwaInstallation = useSetRecoilState(suggestPwaInstallationState);
  const setSkipSuggestPwaInstallation = useSetRecoilState(skipSuggestPwaInstallationState);

  const apiService = useRecoilValue(apiServiceState);

  const androidInstallPromptEvent = useMemo(() => window.androidInstallPromptEvent, []);

  const installAndroid = useCallback(() => {
    androidInstallPromptEvent.prompt();
    androidInstallPromptEvent.userChoice.then((choice) => {
      const request = new ConsumerApi.AndroidPwaInstallPromptOutcomeRequest();
      if (choice.outcome == "accepted") {
        request.outcome = ConsumerApi.AndroidPwaInstallPromptOutcome.Accepted;
      } else if (choice.outcome == "dismissed") {
        request.outcome = ConsumerApi.AndroidPwaInstallPromptOutcome.Dismissed;
        setSkipSuggestPwaInstallation(true);
      } else {
        throw new Error("Unknown install outcome");
      }

      apiService.post(request).catch(err =>
        logErrorOnServerAndConsole(err.message, undefined, undefined, undefined, err));
    });
    setSuggestPwaInstallation(false);
  }, [setSuggestPwaInstallation]);

  useEffect(() => {
    if (messageBoxRef.current && arrowStartRef.current) {
      const messageBox = messageBoxRef.current.getBoundingClientRect();
      const arrowTopPosition = messageBox.bottom + window.scrollY;
      arrowStartRef.current.style.top = `${arrowTopPosition}px`;
    }
  }, []);

  return (
    isIOS ?
      <div className="z-999999 flex flex-col fixed left-0 right-0 top-0 bottom-0 px-[25px] pt-[70px]">
        <div className="w-full mx-auto max-w-[315px] bg-white flex flex-col items-center rounded-[4px] shadow-md py-[20px] px-[20px]">
          <div className="style-title text-center w-full">
            {t("Add BROVS icon to Home Screen for easy access.")}
          </div>
          {[
            <Trans>Tap <span className="px-[6px]"><IconShare></IconShare></span> then Scroll Down</Trans>,
            <Trans>Tap <span className="font-bold px-[6px]">Add to Home Screen</span> <span className="inline-block align-baseline"><IconPlus /></span></Trans>,
            <Trans>Tap <span className="font-bold px-[6px] text-[#007AFF]">Add</span></Trans>,
            <div className="flex">
              <div className="flex-auto">
                <Trans>Tap BROVS Icon on Home Screen</Trans>
              </div>
              <div className="pl-[10px] flex-none">
                <IconApp />
              </div>
            </div>
          ].map((instruction,i) =>
            <div key={i} className="flex align-start mt-[12px] w-full">
              <div className="flex-none w-[20px] style-subtext">{i + 1}</div>
              <div className="flex-wrap style-subtext flex items-center">{instruction}</div>
            </div>
          )}
          <div className="mt-[12px] flex justify-end w-full">
            <div
              onClick={props.onDone}
              className="style-body text-blue cursor-pointer">{t("Done")}</div>
          </div>
        </div>
        <div className="flex-auto w-[20px] m-auto bg-white shadow-md">
        </div>
        <div className="flex-none m-auto">
          <svg width="57" height="38" viewBox="0 0 57 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2110_8200)">
              <path d="M28.5 30L4.5 -3.50753e-06L52.5 6.88765e-07L28.5 30Z" fill="#FFFFFF"/>
            </g>
            <defs>
              <filter id="filter0_d_2110_8200" x="0.5" y="0" width="56" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2110_8200"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2110_8200" result="shape"/>
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    : isAndroid && androidInstallPromptEvent ?
      <div className="z-999999 fixed left-0 right-0 bottom-0 px-[25px] pb-[5px]">
        <div className="w-full mx-auto max-w-[325px] rounded-[4px] shadow-md pt-[15px] pb-[20px] px-[20px] bg-white">

          <div className="flex items-center">
            <div className="flex-auto">
              <IconApp />
            </div>
            <div
              onClick={(e) => { e.stopPropagation(); props.onDone(); }}
              className="flex-none cursor-pointer">
              <CancelIcon />
            </div>

          </div>
          <div className="flex items-center mt-[20px]">
            <div className="flex-auto style-subtitle">
              {t("Add BROVS icon to Home Screen for easy access.")}
            </div>
            <div
              onClick={(e) => { e.stopPropagation(); props.onDone(); }}
              className="flex-none pl-[15px]">
              <Button title="Add" onClick={installAndroid} />
            </div>
          </div>
        </div>
      </div>
    :
      null
  )
}

const IconShare: React.FC = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.85999 13.0616C8.24103 13.0616 8.5689 12.7426 8.5689 12.3704V3.26983L8.51573 1.94063L9.10944 2.56978L10.4564 4.00532C10.5804 4.1471 10.7576 4.21799 10.9349 4.21799C11.2982 4.21799 11.5817 3.95215 11.5817 3.58883C11.5817 3.40275 11.502 3.26097 11.3691 3.12805L8.37395 0.239256C8.19672 0.0620292 8.04608 0 7.85999 0C7.68277 0 7.53212 0.0620292 7.34604 0.239256L4.35091 3.12805C4.21799 3.26097 4.1471 3.40275 4.1471 3.58883C4.1471 3.95215 4.41294 4.21799 4.78511 4.21799C4.95348 4.21799 5.14843 4.1471 5.27249 4.00532L6.61055 2.56978L7.21312 1.94063L7.15995 3.26983V12.3704C7.15995 12.7426 7.47895 13.0616 7.85999 13.0616ZM2.78245 20H12.9464C14.7984 20 15.7288 19.0784 15.7288 17.253V8.40939C15.7288 6.58396 14.7984 5.66238 12.9464 5.66238H10.4741V7.08906H12.9198C13.7971 7.08906 14.3022 7.56757 14.3022 8.48915V17.1732C14.3022 18.0948 13.7971 18.5733 12.9198 18.5733H2.80018C1.91405 18.5733 1.42667 18.0948 1.42667 17.1732V8.48915C1.42667 7.56757 1.91405 7.08906 2.80018 7.08906H5.25476V5.66238H2.78245C0.930439 5.66238 0 6.58396 0 8.40939V17.253C0 19.0784 0.930439 20 2.78245 20Z" fill="#007AFF"/>
    </svg>
  )
}

const IconPlus: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5206 20H16.4794C18.8015 20 20 18.8015 20 16.5115V3.4885C20 1.1985 18.8015 0 16.4794 0H3.5206C1.2092 0 0 1.1878 0 3.4885V16.5115C0 18.8015 1.2092 20 3.5206 20ZM3.65971 17.8705C2.67523 17.8705 2.12948 17.3569 2.12948 16.3189V3.68111C2.12948 2.64312 2.67523 2.12948 3.65971 2.12948H16.3403C17.3141 2.12948 17.8705 2.64312 17.8705 3.68111V16.3189C17.8705 17.3569 17.3141 17.8705 16.3403 17.8705H3.65971ZM6.05672 10.9898H8.99946V13.9326C8.99946 14.5318 9.4168 14.9492 9.99465 14.9492C10.5939 14.9492 11.0219 14.5318 11.0219 13.9326V10.9898H13.9647C14.5532 10.9898 14.9706 10.5725 14.9706 9.99465C14.9706 9.3954 14.5532 8.96736 13.9647 8.96736H11.0219V6.03531C11.0219 5.42536 10.5939 5.01873 9.99465 5.01873C9.4168 5.01873 8.99946 5.43606 8.99946 6.03531V8.96736H6.05672C5.45746 8.96736 5.04013 9.3954 5.04013 9.99465C5.04013 10.5725 5.45746 10.9898 6.05672 10.9898Z" fill="#1C1C1E"/>
    </svg>
  )
}

const IconApp: React.FC = () => {
  return (
    <img src={(new URL('./images/ios/144.png', import.meta.url)).toString()} className="w-[48px] h-[48px] border border-gray-light rounded-[12px]" />
  )
}
