import { apiServiceState, loadingState } from '../atoms';
import { sendSMS } from '../helpers/ShareHelper';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ConsumerApi } from '../ConsumerApi.dto';
import { Overlay } from '../overlay';

type SharePlaceProps = {
  place: ConsumerApi.PlaceDto,
  onClose: () => void
}

export const SharePlace: React.FC<SharePlaceProps> = (props) => {
  const { t } = useTranslation()

  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const share = useCallback((sms?: boolean) => {
    setLoading(true);

    apiService.get(new ConsumerApi.SharePlaceLinkRequest({
        placeId: props.place.id
      }))
      .then((res) => {
        if (sms) {
          sendSMS(
            t(`Hi :-) check out {{name}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.
{{url}}`,
              {
                name: props.place.name,
                url: res.linkWithSharePlaceToken
              }
            ))
        } else {
          navigator.share({
            title: props.place.name,
            text: t(`Hi :-) check out {{name}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.
{{url}}`,{name: props.place.name}),
            url: res.linkWithSharePlaceToken
          })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [apiService, props.place.id, props.place.name, setLoading, t])

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z" stroke="#D2D2D2" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M39.625 16H19.375L16 23.5H43L39.625 16ZM16 25H43V41.5H16V25ZM31.75 29.4H19.375V38.2H31.75V29.4ZM39.625 29.4H35.125V38.2H39.625V29.4Z" fill="currentColor"/>
          </svg>
        </div>
        <div
          style={{marginTop: "25px"}}
          className="font-bold text-20">
          {t("Share this profile")}
        </div>
        <button
          onClick={() => share(true)}
          style={{marginTop: "25px"}}
          className="bg-blue text-white style-subtitle h-11 w-full rounded">
          {t("SMS")}
        </button>
        {/* TODO: Hide until we have tested and fixed the preview in Twitter, FB and other media. */}
        {/* {navigator.share !== undefined ?
          <button
            onClick={() => share()}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Other")}
          </button>
        : null} */}
      </div>
    </Overlay>
  )
}