import React from "react";

export const FilterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 16H16V18H7V16Z" fill="currentColor"/>
      <path d="M4 11H19V13H4V11Z" fill="currentColor"/>
      <path d="M2 6H21V8H2V6Z" fill="currentColor"/>
    </svg>
  )
}
