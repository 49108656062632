import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ProfilePhotoProps = {
  lengthInPx: number
  profilePhotoUrl?: string
  firstName?: string
  lastName?: string
}

export const ProfilePhoto: React.FC<ProfilePhotoProps> = (props) => {

  const { t } = useTranslation();
  const length = `${props.lengthInPx}px`;

  const initials = useMemo(() =>
    props.firstName && props.lastName ?
      `${props.firstName.charAt(0)}${props.lastName.charAt(0)}`
    : ""
  , [props.firstName, props.lastName]);

  return (
    props.profilePhotoUrl ?
      <img
        src={props.profilePhotoUrl}
        className="object-cover rounded-full"
        style={{"width": length, "height": length}}
        alt={t("Photo")} />
    : <div
        style={{"width": length, "height": length, "fontSize": `calc(${length} - (${length} * 0.6))`}}
        className="rounded-full w-full h-full bg-gray-light flex items-center justify-center">
        {initials}
      </div>
  );
}