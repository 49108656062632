import React from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../overlay';

type PlaceReportProblemProps = {
  onClose: () => void
}

export const PlaceReportProblem: React.FC<PlaceReportProblemProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z" stroke="#D2D2D2" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1833 18.326C21.305 16.109 24.0858 15 26.8667 15C29.6486 15 32.4305 16.1101 34.5532 18.326C38.5009 22.4501 38.7778 28.9583 35.3847 33.4158L35.4047 33.3954L43 41.1152L40.4702 43.6875L32.8749 35.9667L33.3852 35.4481C31.4605 36.9579 29.1648 37.7125 26.8698 37.7125C24.0879 37.7125 21.305 36.6046 19.1833 34.3876C14.9389 29.9536 14.9389 22.7611 19.1833 18.326ZM32.2477 20.7352C30.8102 19.2342 28.8985 18.4079 26.8667 18.4079C24.8359 18.4079 22.9252 19.2342 21.4888 20.7352C18.5231 23.8353 18.5231 28.8784 21.4888 31.9785C22.9252 33.4783 24.8369 34.3046 26.8698 34.3046C28.9017 34.3046 30.8123 33.4783 32.2487 31.9785C35.2134 28.8784 35.2134 23.8353 32.2477 20.7352Z" fill="currentColor"/>
          </svg>
        </div>
        <div
          style={{marginTop: "25px"}}
          className="font-bold text-20 text-center">
          {t("Report a problem related to this business?")}
        </div>
        <div
          style={{marginTop: "10px"}}
          className="text-body">
          {t("We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.")}
        </div>
        <button
          style={{marginTop: "25px"}}
          className="bg-red text-white style-subtitle h-11 w-full rounded">
          {t("Report Problem")}
        </button>
      </div>
    </Overlay>
  )
}