import { Country } from "react-phone-number-input";
import { ConsumerApi } from "./ConsumerApi.dto";
import { LatLng } from "./model";

export const apiBaseUrl = process.env.API_BASE_URL;
export const businessWebUrl =  `https://${process.env.NODE_ENV === 'production' ? '' : process.env.NODE_ENV + "-"}business.brovs.com`
export const consumerBaseUrl = `${window.location.protocol}//${window.location.host}`;
export const apiAuthorizationHeader = "Authorization";
export const apiGooglePlaceKey = "AIzaSyBycde7yfQqRt3PZDNE2pYfBnJGHNZYvOs"
export const apiGooglePlaceBaseUrl = "https://maps.googleapis.com/maps/api/place";
export const googleMapsNavigationBaseUrl = "https://www.google.com/maps/dir/?api=1";
export const mapTilerBaseUrl = "https://api.maptiler.com/maps/streets/style.json";
export const defaultSearchRadiusInKm = 100;
export const defaultLocation = new LatLng(59.9127189, 10.7318201) // Oslo, Norway
export const defaultCountryCode = "NO";
export const allowedCountryCodes: Country[] = ["NO", "US", "PE"];
export const versionHeader = "X-Deployed-Version";

export const industryFoodAndDrink = "food_and_drink";
export const industryFashion = "fashion";
export const industryShoppingCentre = "shopping_center";
export const defaultLanguage = ConsumerApi.Locale.EnUs;

export const savedSearchNameMaxLength = 50;
export const regexpPhoneNumber = /^\+?[0-9][0-9\s]+$/;
export const regexpEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const localStorageLanguageKey = "language";

export const initialPageSize = 3;
export const pageSize = 10;

export const currentPositionOptions = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 10000
} as PositionOptions;
