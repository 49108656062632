import React from "react";

export const ChildrenswearGirl = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7483 9.14659C16.7483 9.14659 16.2458 14.6729 21.6265 14.6806C21.9899 7.94923 16.7483 9.14659 16.7483 9.14659Z" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
      <path d="M7.89629 9.14659C7.89629 9.14659 8.3988 14.6729 3.01805 14.6806C2.6547 7.94923 7.89629 9.14659 7.89629 9.14659Z" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
      <path d="M19.6937 22.3253C19.6937 22.3253 19.8406 19.7694 16.5318 19.2782C14.6686 19.0019 14.599 17.5205 14.599 15.6707H10.3393C10.3393 15.7091 10.3393 15.7475 10.3393 15.7858C10.3083 17.5742 10.231 19.0019 8.40653 19.2705C5.09768 19.7694 5.24457 22.3176 5.24457 22.3176H19.6937V22.3253Z" fill="currentColor"/>
      <path d="M17.3049 9.06215V6.97443L16.7792 5.16302H7.58705V8.04131V9.06215H6.30371C6.30371 9.40754 6.30371 10.6433 6.30371 10.6433C6.30371 10.6433 6.30371 12.1016 7.58705 12.1016V12.1784C7.58705 12.1784 7.6489 13.7825 8.01998 14.5501C8.3988 15.3176 8.54569 15.5479 9.12551 15.97C9.70533 16.3845 10.8109 17.0369 10.8109 17.0369C10.8109 17.0369 11.6072 17.4284 12.4421 17.4284C13.277 17.4284 14.0733 17.0369 14.0733 17.0369C14.0733 17.0369 15.1789 16.3845 15.7587 15.97C16.3385 15.5556 16.4854 15.3176 16.8642 14.5501C17.243 13.7825 17.2971 12.1784 17.2971 12.1784V12.1016C18.6037 12.1016 18.6037 10.9196 18.6037 10.6433C18.6037 10.2518 18.6037 9.40754 18.6037 9.06215H17.3049Z" fill="currentColor"/>
      <path d="M12.4576 2.00074C12.4576 2.00074 12.1329 9.05447 6.30374 9.06214C5.90946 1.77815 12.4576 2.00074 12.4576 2.00074Z" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
      <path d="M12.4575 2.00074C12.4575 2.00074 12.7822 9.05447 18.6114 9.06214C19.0134 1.77815 12.4575 2.00074 12.4575 2.00074Z" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
    </svg>
  )
}
