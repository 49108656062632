import React from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../overlay';

type PlaceOptionsMenuProps = {
  onClose: () => void
  onClickBookmark?: () => void
  onClickUnbookmark?: () => void
  onClickReportProblem?: () => void
}

export const OptionsMenu: React.FC<PlaceOptionsMenuProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div
          className="font-bold text-20" style={{marginBottom: "10px"}}>
          {t("Options")}
        </div>
        {props.onClickUnbookmark ?
          <button
            onClick={props.onClickUnbookmark}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Unbookmark")}
          </button>
        : null}
        {props.onClickBookmark ?
          <button
            onClick={props.onClickBookmark}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Bookmark")}
          </button>
        : null}
        
        {/*
        // TODO: Disable until the behavior is decided
        {props.onClickReportProblem ?
          <button
            onClick={props.onClickReportProblem}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Report Problem")}
          </button>
        : null} */}
      </div>
    </Overlay>
  )
}