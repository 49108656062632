import React from "react";

export const ClaimedPlaceIcon = () => {
  return (
    <span className="text-green">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="currentColor"/>
        <path d="M8.89698 4.11765L9.88238 4.85219L6.43953 9.88235L4.11768 7.45926L4.97396 6.56564L6.285 7.93385L8.89698 4.11765Z" fill="white"/>
      </svg>
    </span>
  )
}
