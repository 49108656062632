import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { apiServiceState, loadingState } from '../atoms';
import { regexpPhoneNumber } from '../Constants';
import { ConsumerApi } from '../ConsumerApi.dto';
import { ArrowBackIcon } from '../icons';
import 'react-phone-number-input/style.css'
import { InputPhone } from '../input-phone';
import { Header } from '../header';
import { Button } from '../button';
import { logErrorOnServerAndConsole } from "../helpers/ErrorHelper";

const CreateProfile = React.lazy(() => import('../create-profile/CreateProfile'));
const Login = React.lazy(() => import('../login/Login'));
const LoginWithVipps = React.lazy(() => import('../login/LoginWithVipps'));


type ContinueWithPhoneProps = {
  onBack: () => void
}

export const ContinueWithPhone: React.FC<ContinueWithPhoneProps> = (props) => {

  const { t } = useTranslation();

  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);
  const [phone, setPhone] = useState<string>();
  const [errorPhone, setErrorPhone] = useState<string>();
  const [loginNextStepResponse, setLoginNextStepResponse] = useState<ConsumerApi.LoginNextStepResponse>();

  const onChangePhone = useCallback((phone: string) => {
    setErrorPhone(undefined);
    setPhone(phone);
  }, [])

  const checkPhoneRegistered = useCallback(() => {
    if (!phone) {
      setErrorPhone(t("Required"))
    } else if (!phone.match(regexpPhoneNumber)) {
      setErrorPhone(t("Invalid Mobile Number."))
    } else {
      setLoading(true);
      apiService.post(new ConsumerApi.LoginNextStepRequest({
        phoneNumber: phone
      }))
      .then((res) => {
        if (res.nextStep === ConsumerApi.LoginNextStep.FixInvalidPhoneNumber) {
          setErrorPhone(t("Invalid Mobile Number."));
        } else {
          setLoginNextStepResponse(res);
        }
      })
      .catch((err) => {
        if (err.responseStatus?.message){
          setErrorPhone(err.responseStatus.message);
        } else {
          logErrorOnServerAndConsole(err.message, undefined, undefined, undefined, err);
        }
      })
      .finally(() => setLoading(false));
    }
  }, [apiService, phone, setLoading, t])

  return (
    <>
      <div className="fixed inset-0 z-20 bg-white overflow-y-auto mx-auto max-w-md">
        <div className="fixed top-0 left-0 right-0 z-30 bg-white">
          <Header
            leftIcon={<ArrowBackIcon />}
            onClickLeftIcon={props.onBack}
            title={t("Continue with Mobile Number")} />
        </div>

        <div className="px-[15px] py-[60px]">
          <div className="mt-[30px]">
            {!loginNextStepResponse ?
              <form
                onSubmit={(e) => {e.preventDefault(); checkPhoneRegistered(); }}>
                <InputPhone
                  autoFocus={true}
                  placeholder="12345678"
                  value={phone}
                  label={t("Mobile Number")}
                  error={errorPhone}
                  onChange={(value) => onChangePhone(value)} />

                <div className="mt-[30px]">
                  <Button title={t("Continue")} type="submit" />
                </div>
              </form>
            : loginNextStepResponse.nextStep === ConsumerApi.LoginNextStep.Login ?
                loginNextStepResponse.hasVippsAccount ?
                  <LoginWithVipps
                    phone={phone as string}
                    loginNextStepResponse={loginNextStepResponse}
                    onBack={() => setLoginNextStepResponse(undefined)} />
                :
                  <Login
                    phone={phone as string}
                    loginNextStepResponse={loginNextStepResponse}
                    onBack={() => setLoginNextStepResponse(undefined)} />
            : loginNextStepResponse.nextStep === ConsumerApi.LoginNextStep.Signup ?
                <CreateProfile
                  phone={phone as string}
                  onBack={(backToWelcome) => backToWelcome ? props.onBack() : setLoginNextStepResponse(undefined)} />
            : <div> ERROR: Unsupported LoginNextStep: {loginNextStepResponse.nextStep} </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}