import React from "react";

export const CircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 11.5C1 5.701 5.701 1 11.5 1S22 5.701 22 11.5 17.299 22 11.5 22 1 17.299 1 11.5zM11.5 2a9.5 9.5 0 100 19 9.5 9.5 0 000-19z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}