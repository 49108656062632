import React, { HTMLAttributeAnchorTarget, Ref } from 'react';
import { Link } from 'react-router-dom';

type buttonType = "button" | "submit";

type ButtonProps = {
  reference?: Ref<HTMLInputElement>
  onClick?: () => void
  title: string
  disabled?: boolean
  styleType?: "primary" | "secondary" | "alert"
  type?: buttonType | "link" | "externalLink"
  href?: string
  target?: HTMLAttributeAnchorTarget
}

export const Button: React.FC<ButtonProps> = (props) => {

  const styleType = props.styleType ?? "primary";

  let className = "flex items-center px-[20px] justify-center text-white text-title-bold h-[45px] w-full";
  if (props.disabled) {
    if (props.type === "link" || props.type === "externalLink") {
      className += " text-gray-light"
    } else {
      className += " bg-gray-light";
    }
  } else {
    className += " cursor-pointer";
    switch (styleType) {
      case "primary":
        className += " bg-blue";
        break;
      case "secondary":
        className += " bg-black";
        break;
      case "alert":
        className += " bg-red";
        break;
    }
  }

  return (
    props.type === "link" ?
      <Link
        to={props.href || ""}
        onClick={props.disabled ? undefined : props.onClick}
        className={className}
        style={{borderRadius: "3px"}}
        target={props.target}>
        {props.title}
      </Link>
    : props.type === "externalLink" ?
      <a
        href={props.href || ""}
        onClick={props.disabled ? undefined : props.onClick}
        className={className}
        style={{borderRadius: "3px"}}
        target={props.target}>
        {props.title}
      </a>
    :
    <button
      onClick={props.disabled ? undefined : props.onClick}
      type={props.type ?? "button"}
      className={className}
      style={{borderRadius: "3px"}}>
      {props.title}
    </button>
  )
}