import React from "react";

export const Menswear = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2007 11.5772H16.4744C17.1033 11.5772 17.2735 10.3587 17.2735 10.0763C17.2735 9.67511 17.2735 9.16245 17.2735 8.80581L15.52 8.34515L7.49236 8.54576L7.19641 8.85039C7.19641 9.1996 7.19641 9.67511 7.19641 10.0763C7.19641 10.3661 7.34439 11.5772 7.97328 11.5772H8.25443" fill="currentColor"/>
      <path d="M8.74272 9.68254L7.81787 8.47889C7.81787 8.47889 7.81787 8.24113 7.81787 5.49206C7.81787 2.73556 10.7256 2 12.6345 2C13.9144 2 16.6594 2.89159 16.6594 5.64066C16.6594 8.38973 16.6594 8.44174 16.6594 8.44174L15.7197 9.01385"/>
      <path d="M8.74272 9.68254L7.81787 8.47889C7.81787 8.47889 7.81787 8.24113 7.81787 5.49206C7.81787 2.73556 10.7256 2 12.6345 2C13.9144 2 16.6594 2.89159 16.6594 5.64066C16.6594 8.38973 16.6594 8.44174 16.6594 8.44174L15.7197 9.01385" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
      <path d="M21.9939 23.3462C21.9939 23.3462 22.1862 19.9507 17.8358 19.2969C15.3868 18.9254 15.2906 16.9565 15.2906 14.5046H9.69714C9.69714 14.5566 9.69714 14.6086 9.69714 14.6606C9.66015 17.0382 9.54917 18.9328 7.15937 19.2969C2.8089 19.9507 3.00127 23.3462 3.00127 23.3462H21.9939Z" fill="currentColor"/>
      <path d="M16.2007 6.29452V11.6589C16.2007 11.6589 16.1563 13.3084 15.8455 14.0959C15.5348 14.8835 15.4164 15.1213 14.9429 15.5522C14.4693 15.9831 13.5593 16.6518 13.5593 16.6518C13.5593 16.6518 12.9082 17.0605 12.2275 17.0605C11.5394 17.0605 10.8957 16.6518 10.8957 16.6518C10.8957 16.6518 9.9931 15.9831 9.51218 15.5522C9.03866 15.1213 8.92028 14.8835 8.60953 14.0959C8.29879 13.3084 8.25439 11.6515 8.25439 11.6515V7.40158V6.31681L9.51218 4.61536L16.2746 6.42826L16.2007 6.29452Z" fill="currentColor"/>
      <path d="M7.81787 8.62011L9.58617 6.51001C9.58617 6.51001 14.1734 7.49819 16.652 7.56506" stroke="currentColor" strokeWidth="1.7552" strokeMiterlimit="10"/>
    </svg>
  )
}
