import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowRightSmallIcon, PlaceOpenIcon } from "../icons";
import { PlaceOpenHourInfo } from "model";


type PlaceOpeningHoursDescriptionProps = {
  onClickOpeningHours?: () => void
  openHourInfo: PlaceOpenHourInfo
}

export const PlaceOpeningHoursDescription: React.FC<PlaceOpeningHoursDescriptionProps> = (props) => {

  const openHourInfo = props.openHourInfo;
  const { t } = useTranslation();

  return (
    <div
      onClick={props.onClickOpeningHours}
      className={`flex flex-auto items-center ${openHourInfo.initialized && props.onClickOpeningHours ? 'cursor-pointer' : ''}`}>
      <div>
        { !openHourInfo.initialized ?
          <span className="text-near-black">
            <PlaceOpenIcon />
          </span>
        : openHourInfo.isOpen ?
          <span className="text-green">
            <PlaceOpenIcon />
          </span>
        :
          <span className="text-red">
            <PlaceOpenIcon />
          </span>
        }
      </div>
      {openHourInfo.initialized ?
        <>
          <div className="text-body whitespace-pre ml-[5px] mr-[3px]">
            {openHourInfo.isOpen ?
              `${t("Open")}${openHourInfo.nextCloseAt ? `  |  ${t("Closes")} ${openHourInfo.nextCloseAt}` : ""}`
            :
              `${t("Closed")}${openHourInfo.nextOpenAt ? `  |  ${t("Opens")} ${openHourInfo.nextOpenAt}` : ""}`
            }
          </div>
          {props.onClickOpeningHours ?
            <div>
              <ArrowRightSmallIcon />
            </div>
          : null}
        </>
      : null}
    </div>
  )
}