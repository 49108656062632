import { useQueryString } from './hooks/UseQueryString';
import React, { useEffect } from 'react';
import { Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Main from './Main';
import { Spinner } from './spinner';
import { useSetRecoilState } from 'recoil';
import { referBusinessTokenState, referFriendTokenState, shareAdMessageTokenState, sharePlaceTokenState } from './atoms';
import { VippsCallback } from './vipps-callback/VippsCallback';

const PrivacyPolicy = React.lazy(() => import('./policy/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./policy/TermsOfService'));

function App() {

  const navigate = useNavigate();
  const location = useLocation();
  const queryString = useQueryString();
  const setReferFriendToken = useSetRecoilState(referFriendTokenState);
  const setReferBusinessToken = useSetRecoilState(referBusinessTokenState);
  const setSharePlaceToken = useSetRecoilState(sharePlaceTokenState);
  const setShareAdMessageToken = useSetRecoilState(shareAdMessageTokenState);

  useEffect(() => {
    let modifyQuery = false;

    const tokensToKeep: ("referFriendToken" | "referBusinessToken" | "sharePlaceToken" | "shareAdMessageToken")[] = [];

    if (queryString.has("referFriendToken")) {
      setReferFriendToken(queryString.get("referFriendToken") as string);
      queryString.delete("referFriendToken");
      tokensToKeep.push("referFriendToken");
      modifyQuery = true;
    }

    if (queryString.has("referBusinessToken")) {
      setReferBusinessToken(queryString.get("referBusinessToken") as string);
      queryString.delete("referBusinessToken");
      tokensToKeep.push("referBusinessToken");
      modifyQuery = true;
    }

    if (queryString.has("sharePlaceToken")) {
      setSharePlaceToken(queryString.get("sharePlaceToken") as string);
      queryString.delete("sharePlaceToken");
      queryString.set("shared", "true");
      tokensToKeep.push("sharePlaceToken");
      modifyQuery = true;
    }

    if (queryString.has("shareAdMessageToken")) {
      setShareAdMessageToken(queryString.get("shareAdMessageToken") as string);
      queryString.delete("shareAdMessageToken");
      queryString.set("shared", "true");
      tokensToKeep.push("shareAdMessageToken");
      modifyQuery = true;
    }

    if (modifyQuery) {
      if (!tokensToKeep.includes("referFriendToken")) { setReferFriendToken(undefined); }
      if (!tokensToKeep.includes("referBusinessToken")) { setReferBusinessToken(undefined); }
      if (!tokensToKeep.includes("sharePlaceToken")) { setSharePlaceToken(undefined); }
      if (!tokensToKeep.includes("shareAdMessageToken")) { setShareAdMessageToken(undefined); }

      navigate({pathname: location.pathname, search: queryString.toString()}, {replace: true});
    }
  }, [queryString, history, setReferFriendToken, setReferBusinessToken, setSharePlaceToken, setShareAdMessageToken])

  return (
    <div className="mx-auto max-w-md relative h-full">
      <Suspense fallback={<Spinner fullScreen={true} />}>
        <Routes>
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/vipps/login/callback" element={<VippsCallback />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;