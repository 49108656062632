import React from 'react';

type SpinnerProps = {
  fullScreen: boolean
}

export const Spinner: React.FC<SpinnerProps> = (props) => {

  const renderSpinner = () => {
    return (
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    )
  }

  if (props.fullScreen) {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center"
        style={{zIndex: 99999}}>
        {renderSpinner()}
      </div>
    )
  }

  return renderSpinner();
}