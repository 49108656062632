import { ConsumerApi } from '../ConsumerApi.dto';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { apiServiceState, loadingState } from '../atoms';
import { useQueryString } from '../hooks/UseQueryString';
import { useSetRecoilState } from 'recoil';

export const VippsCallback: React.FC = () => {

  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);
  const redirectParams = useQueryString();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    apiService.get(new ConsumerApi.VippsLoginCallbackRequest({
      state: redirectParams.get("state") as string,
      code: redirectParams.get("code") as string,
      scope: redirectParams.get("scope") as string,
      error: redirectParams.get("error") as string,
      errorDescription: redirectParams.get("errorDescription") as string,
      errorHint: redirectParams.get("errorHint") as string
    }))
    .then((response) => window.location.href = "/")
    .catch((err) => setError(err.responseStatus.message))
  }, [redirectParams, apiService])

  return (
    <div className="absolute inset-0 z-40 bg-white overflow-y-scroll">
      {error ?
        <div className="flex items-center justify-center py-3 px-3">
          <div className="style-caption text-center text-red my-8">
            {error}
          </div>
        </div>
      : null}
    </div>
  )
}