import { shareAdMessageLinkUsedState } from "./shareAdMessageLinkUsed";
import { atom, selector } from "recoil";

const localStorageKey = "shareAdMessageToken";

const _shareAdMessageTokenState = atom<string | undefined>({
  key: '_shareAdMessageTokenState',
  default: localStorage.getItem(localStorageKey) ?? undefined
});

export const shareAdMessageTokenState = selector<string | undefined>({
  key: 'shareAdMessageTokenState',
  get: ({ get }) => get(_shareAdMessageTokenState),
  set: ({ set }, token) => {
    if (token) {
      localStorage.setItem(localStorageKey, token as string)
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_shareAdMessageTokenState, token);
    if (!token) {
      set(shareAdMessageLinkUsedState, undefined);
    }
  }
});