import { atom, selector } from "recoil";
import { apiServiceState } from ".";
import { ConsumerApi } from "../ConsumerApi.dto";

const _saveFoodAndMoneyImageState = selector<string | undefined>({
  key: '_saveFoodAndMoneyImageState',
  get: async ({ get }) => {
    const apiService = get(apiServiceState);

    const url = await apiService.get(new ConsumerApi.CustomImageGetRequest({
      slug: ConsumerApi.CustomImageSlug.SaveFoodAndMoney
    })).then(response =>
      response.imageUrl
    ).catch((err) => err);

    return url;
  },
});

export const saveFoodAndMoneyImageState = atom<string | undefined>({
  key: 'saveFoodAndMoneyImageState',
  default: _saveFoodAndMoneyImageState
});