import React from "react";

export const TakeawayIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.1618 2L15.7198 6.53008H2L2.65888 2H16.1618Z" fill="currentColor"/>
      <path d="M15.945 21H22L19.99 2H17.7882L15.945 21Z" fill="currentColor"/>
      <path d="M15.5697 8.13328H3.57634L2.0167 21H14.3186L15.5697 8.13328Z" fill="currentColor"/>
    </svg>
  )
}
