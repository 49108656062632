import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from "../ConsumerApi.dto";
import { PlaceMenu } from './PlaceMenu';

type PlaceMenusProps = {
  place: ConsumerApi.PlaceDto
}

export const PlaceMenus: React.FC<PlaceMenusProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;
  const [menu, setMenu] = useState<ConsumerApi.MenuDto>();

  return (
    <>
      <div className="flex flex-col pt-[15px] px-[12px]">
        {place.menus && place.menus.length > 0 ?
          <div className="flex flex-col">
            {place.menus.map((m, i) =>
              <div
                key={i}
                onClick={() => setMenu(m)}
                className="flex-none mb-[15px] w-full flex relative cursor-pointer h-[160px]">
                <img
                  className="absolute h-full w-full inset-0 z-0 object-cover"
                  alt={m.name}
                  src={place.imageUrl?.medium} />
                <div className="absolute inset-0 bg-[#262525] bg-opacity-50 z-10"></div>
                <div className="absolute inset-0 z-20 flex justify-center items-center">
                  <div className="font-bold text-h6 text-white">
                    {m.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        :
          <div className="h-[290px] mt-[15px] flex flex-col justify-center items-center">
            <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.5672 8.625C21.7148 8.625 28.009 12.1028 31.7001 17.3728V60.7919C28.009 55.5219 21.7148 52.0441 14.5672 52.0441C10.2146 52.0441 6.20344 53.3242 2.875 55.5219V12.1028C6.20344 9.92651 10.2146 8.625 14.5672 8.625ZM52.197 8.625C45.0494 8.625 38.7552 12.1028 35.064 17.3728V60.7919C38.7552 55.5219 45.0494 52.0441 52.197 52.0441C56.5495 52.0441 60.5607 53.3242 63.8892 55.5219V12.1028C60.5607 9.92651 56.5282 8.625 52.197 8.625Z" fill="#262525"/>
            </svg>
            <div className="text-body-bold mt-[20px]">
              {t("Menu has not been added.")}
            </div>
          </div>}
      </div>
      {menu ?
        <PlaceMenu
          menu={menu}
          onBack={() => setMenu(undefined)} />
      : null}
    </>
  )
}

