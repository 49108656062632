import React from "react";

export const PlaceDetailIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.137 6.12342H2V4H6.137V6.12342Z" fill="currentColor"/>
    <path d="M22.5687 6.12342H8.64731V4H22.5687V6.12342Z" fill="currentColor"/>
    <path d="M2 10.4623H6.137V8.33892H2V10.4623Z" fill="currentColor"/>
    <path d="M18.195 10.4623H8.64731V8.33892H18.195V10.4623Z" fill="currentColor"/>
    <path d="M2 14.8013H6.137V12.6778H2V14.8013Z" fill="currentColor"/>
    <path d="M22.5687 14.8013H8.64731V12.6778H22.5687V14.8013Z" fill="currentColor"/>
    <path d="M2 19.1402H6.137V17.0168H2V19.1402Z" fill="currentColor"/>
    <path d="M13.4216 19.1402H8.64731V17.0168H13.4216V19.1402Z" fill="currentColor"/>
  </svg>  
}
