import React from "react";
import { Accessories } from "./Accessories";
import { Babywear } from "./Babywear";
import { Bakery } from "./Bakery";
import { BarAndPub } from "./BarAndPub";
import { Cafe } from "./Cafe";
import { ChildrensShoes } from "./ChildrensShoes";
import { ChildrenswearBoy } from "./ChildrenswearBoy";
import { ChildrenswearGirl } from "./ChildrenswearGirl";
import { EyewearAndSunglasses } from "./EyewearAndSunglasses";
import { Fashion } from "./Fashion";
import { Fastfood } from "./Fastfood";
import { FoodAndDrink } from "./FoodAndDrink";
import { HandbagsAndPurses } from "./HandbagsAndPurses";
import { LingerieAndUnderwear } from "./LingerieAndUnderwear";
import { MensShoes } from "./MensShoes";
import { Menswear } from "./Menswear";
import { Nightclub } from "./Nightclub";
import { PerfumesAndCosmetics } from "./PerfumesAndCosmetics";
import { Restaurant } from "./Restaurant";
import { TravelAndBags } from "./TravelAndBags";
import { WatchesAndJewelry } from "./WatchesAndJewelry";
import { WomensShoes } from "./WomensShoes";
import { Womenswear } from "./Womenswear";

type CategoryProps = {
  slug: string
}

export const CategoryIcon: React.FC<CategoryProps> = (props) => {
  
  switch (props.slug) {
    case "fashion":
      return <Fashion />;
    case "fashion.womenswear":
      return <Womenswear />;
    case "fashion.menswear":
      return <Menswear />;
    case "fashion.childrenswear_girl":
      return <ChildrenswearGirl />;
    case "fashion.childrenswear_boy":
      return <ChildrenswearBoy />;
    case "fashion.babywear":
      return <Babywear />;
    case "fashion.lingerie_and_underwear":
      return <LingerieAndUnderwear />;
    case "fashion.womens_shoes":
      return <WomensShoes />;
    case "fashion.mens_shoes":
      return <MensShoes />;
    case "fashion.childrens_shoes":
      return <ChildrensShoes />;
    case "fashion.accessories":
      return <Accessories />;
    case "fashion.perfumes_and_cosmetics":
      return <PerfumesAndCosmetics />;
    case "fashion.handbags_and_purses":
      return <HandbagsAndPurses />;
    case "fashion.travel_and_bags":
      return <TravelAndBags />;
    case "fashion.eyewear_and_sunglasses":
      return <EyewearAndSunglasses />;
    case "fashion.watches_and_jewelry":
      return <WatchesAndJewelry />;

    case "food_and_drink":
      return <FoodAndDrink />
    case "food_and_drink.bakeries":
      return <Bakery />
    case "food_and_drink.bars_and_pubs":
      return <BarAndPub />
    case "food_and_drink.cafes":
      return <Cafe />
    case "food_and_drink.coffees_and_teas":
      return <Cafe />
    case "food_and_drink.fast_food":
      return <Fastfood />
    case "food_and_drink.nightclubs":
      return <Nightclub />
    case "food_and_drink.restaurants":
      return <Restaurant />
    default:
      return <div>Missing icon</div>;
  }
}
