import { ConsumerApi } from "../ConsumerApi.dto";
import { Filter } from "../finder/Filter";

export function convertFiltersToUrl(filters: { [key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined }, categoryAbsoluteSlug?: string): string | undefined {
  let filtersUrl: string | undefined = undefined;

  // prepareSubCategory removes from the subCategory slug the parent category in order to be used
  // in the URL.
  // Example: Remove the parent category to use this URL places/fashion.womenswear/SubCategories=jeans
  // instead of the URL places/fashion.womenswear/SubCategories=fashion.womenswear.jeans
  const prepareSubCategory = (filter: Filter) => {
    if (categoryAbsoluteSlug) {
      const subCategory = filter.data as string;
      const subCategoryArray = subCategory.split(".");
      const categoryAbsoluteSlugArray = categoryAbsoluteSlug.split(".");
      let data = "";
      if (subCategoryArray.length > categoryAbsoluteSlugArray.length && subCategory.startsWith(`${categoryAbsoluteSlug}.`)) {
        subCategoryArray.splice(0, categoryAbsoluteSlugArray.length);
        data = subCategoryArray.join(".");
      } else {
        data = "";
      }
      return {...filter, data: data}
    } else {
      throw `It's not possible to use Subcategories filters when the categoryAbsoluteSlug is undefined`
    }
  }

  const _filtersUrl = Object.keys(filters)
    .filter(featureType =>
      filters[featureType as ConsumerApi.FeatureType] !== undefined
      && (filters[featureType as ConsumerApi.FeatureType] as Filter[]).length > 0
      && (filters[featureType as ConsumerApi.FeatureType] as Filter[]).some(f => f.selected)
    )
    .map(featureType => `${featureType}=${(filters[featureType as ConsumerApi.FeatureType] as Filter[])
      .map(f => f.featureType !== ConsumerApi.FeatureType.SubCategories ? f : prepareSubCategory(f))
      .filter(f => f.selected && (f.featureType !== ConsumerApi.FeatureType.SubCategories || f.data !== "") )
      .map(f => encodeURIComponent(f.data)).join(",")}`)
    .join(";")

  if (_filtersUrl.length > 0) {
    filtersUrl = _filtersUrl;
  }

  return filtersUrl;
}

export function buildQueryString(
  paramLocation?: string,
  paramPlaceGuid?: string,
  paramAdMessageGuid?: string,
  paramShared?: string): string {

  let queryString = paramLocation ? `location=${paramLocation}` : "";

  if (paramPlaceGuid) {
    if (queryString) {
      queryString = `&${queryString}`;
    }
    queryString = `placeGuid=${paramPlaceGuid}${queryString}`;
    if (paramAdMessageGuid) {
      queryString = `adMessageGuid=${paramAdMessageGuid}&${queryString}`;
    }
    if (paramShared) {
      queryString = `${queryString}&shared=true`;
    }
  }

  return queryString;
}

export function buildUrl(
  base: "finder" | "finder-map",
  paramTab: string,
  paramLocation: string,
  paramFindMode?: string,
  paramPlaceGuid?: string,
  paramAdMessageGuid?: string,
  filters?: { [key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined } | string | undefined,
  paramShared?: string,
): string {
  const url: string[] = [base, paramTab];

  if (paramFindMode) {
    url.push(paramFindMode);

    if (filters) {
      if (typeof filters === 'string') {
        url.push(filters);
      } else {
        const filtersUrl = convertFiltersToUrl(
          filters as { [key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] }, paramFindMode);
        if (filtersUrl) {
          url.push(filtersUrl);
        }
      }
    }
    // If paramCategory is present these parameters are logically impossible
    paramPlaceGuid = undefined;
    paramAdMessageGuid = undefined;
    paramShared = undefined
  }

  const queryString = buildQueryString(paramLocation, paramPlaceGuid, paramAdMessageGuid, paramShared);

  return `/${url.join("/")}/?${queryString}`
}