import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiServiceState, showReferFriendsState, userProfileState, userStatusState, referFriendTokenState, referFriendLinkUsedState, referBusinessTokenState, referBusinessLinkUsedState, sharePlaceLinkUsedState, sharePlaceTokenState, shareAdMessageTokenState, shareAdMessageLinkUsedState, suggestPwaInstallationState, skipSuggestPwaInstallationState } from './atoms';
import { loadingState } from './atoms/loading';
import { Welcome } from './welcome';
import { Sidebar } from './sidebar';
import { Spinner } from './spinner';
import { pathBeforeLoginState } from './atoms/pathBeforeLogin';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ConsumerApi } from './ConsumerApi.dto';
import { useInitUserStatus } from './hooks/UseInitUserStatus';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Finder } from './finder';
import { versionHeader } from './Constants';
import { placeBookmarksState } from './atoms/placeBookmarks';
import { adMessageBookmarksState } from './atoms/adMessageBookmarks';
import { Rewards } from './rewards';
import { PhoneIcon } from './icons';
import { Button } from './button';
import { InstallAppInstructions } from './InstallAppInstructions';
import { Lottery } from './lottery';
import { earnedPointsBySignupState } from 'atoms/earnedPointsBySignup';
import { EarnedPointsBySignup } from 'rewards/EarnedPointBySignup';

const CompleteProfile = React.lazy(() => import('./create-profile/CompleteProfile'));
const ConfirmEmail = React.lazy(() => import('./confirm-email/ConfirmEmail'));
const Bookmarks = React.lazy(() => import('./bookmarks/Bookmarks'));
const Settings = React.lazy(() => import('./settings/Settings'));
const Account = React.lazy(() => import('./settings/Account'));
const ReferFriends = React.lazy(() => import('./refer-friends/ReferFriends'));
const FinderMap = React.lazy(() => import('./finder-map/FinderMap'));
const ExpandedPlace = React.lazy(() => import('./place/ExpandedPlace'));
const ExpandedAdMessage = React.lazy(() => import('./ad-message/ExpandedAdMessage'));

function Main() {

  const { i18n, t } = useTranslation();

  const apiService = useRecoilValue(apiServiceState);
  const [ newVersionAvailable, setNewVersionAvailable ] = useState(false);
  const [ suggestPwaInstallation, setSuggestPwaInstallation ] = useRecoilState(suggestPwaInstallationState);
  const setSkipSuggestPwaInstallation = useSetRecoilState(skipSuggestPwaInstallationState);
  const versionRef = useRef<string>();

  const userStatus = useRecoilValue(userStatusState);
  const userProfile = useRecoilValue(userProfileState);
  const showReferFriends = useRecoilValue(showReferFriendsState);
  const setLoading = useSetRecoilState(loadingState);
  const earnedPointsBySignup = useRecoilValue(earnedPointsBySignupState);
  const initUserStatus = useInitUserStatus();
  const referFriendToken = useRecoilValue(referFriendTokenState);
  const setReferFriendUsedLink = useSetRecoilState(referFriendLinkUsedState);
  const referBusinessToken = useRecoilValue(referBusinessTokenState);
  const setReferBusinessUsedLink = useSetRecoilState(referBusinessLinkUsedState);

  const sharePlaceToken = useRecoilValue(sharePlaceTokenState);
  const setSharePlaceUsedLink = useSetRecoilState(sharePlaceLinkUsedState);
  const shareAdMessageToken = useRecoilValue(shareAdMessageTokenState);
  const setShareAdMessageUsedLink = useSetRecoilState(shareAdMessageLinkUsedState);

  const loading = useRecoilValue(loadingState);
  const [pathBeforeLogin, setPathBeforeLogin] = useRecoilState(pathBeforeLoginState);
  const [placeBookmarks, setPlaceBookmarks] = useRecoilState(placeBookmarksState);
  const [adMessagesBookmarks, setAdMessagesBookmarks] = useRecoilState(adMessageBookmarksState);
  const location = useLocation();
  const navigate = useNavigate();

  // Saving the location search allow retrieve the sharing params such as placeGuid
  const path = useMemo(() =>
    `${location.pathname}${location.search ? location.search : ""}`,
    [ location.pathname, location.search ]);

  useEffect(() => {
    if (userStatus) {
      if (userStatus.status !== ConsumerApi.UserStatus.Ready && path !== "/login" && path !== "/login/reset-password" && path !== "/login/confirm-email") {

        setPathBeforeLogin(path);
      } else if (userStatus.status === ConsumerApi.UserStatus.Ready && pathBeforeLogin && path !== pathBeforeLogin) {
        setPathBeforeLogin(undefined);
        navigate(pathBeforeLogin);
      }
    }
  }, [path, pathBeforeLogin, setPathBeforeLogin, navigate, userStatus])


  useEffect(() => {
    setLoading(true);
    initUserStatus(() => setLoading(false))
  }, [initUserStatus, setLoading])

  useEffect(() => {
    if (userProfile && userProfile.locale) {
      i18n.changeLanguage(userProfile.locale);
    }
  }, [i18n, userProfile])

  useEffect(() => {
    if (userProfile && !apiService.responseFilter) {
      apiService.responseFilter = (res) => {
        if (res.headers.has(versionHeader)) {
          let versionFromHeader = res.headers.get(versionHeader);
          if (versionFromHeader) {
            // Set newVersionAvailable only if the atom already had a version set and if it is
            // different to the verion coming from the header
            if (versionRef.current && versionRef.current !== versionFromHeader) {
              setNewVersionAvailable(true);
            }
            // Since this responseFilter is bound only one time, reading the version
            // from a React state always will be outdated, so a React ref is used instead
            versionRef.current = versionFromHeader;
          }
        }
      }
    }
  }, [apiService, setNewVersionAvailable, userProfile])

  useEffect(() => {
    if (apiService.responseFilter !== undefined) {
      (apiService as any).responseFilter = undefined;
    }
  }, [apiService])

  useEffect(() => {
    if (userStatus && userStatus.status === ConsumerApi.UserStatus.Ready) {
      if (referFriendToken) {
        setLoading(true);
        apiService.post(new ConsumerApi.ReferFriendLinkUsedRequest({
            referFriendToken: referFriendToken
          }))
          .then((res) => setReferFriendUsedLink(res))
          .finally(() => setLoading(false));
      }

      if (referBusinessToken) {
        setLoading(true);
        apiService.post(new ConsumerApi.ReferBusinessLinkUsedRequest({
            referBusinessToken: referBusinessToken
          }))
          .then((res) => setReferBusinessUsedLink(res))
          .finally(() => setLoading(false));
      }

      if (sharePlaceToken) {
        setLoading(true);
        apiService.post(new ConsumerApi.SharePlaceLinkUsedRequest({
            sharePlaceToken: sharePlaceToken
          }))
          .then((res) => setSharePlaceUsedLink(res))
          .finally(() => setLoading(false));
      }

      if (shareAdMessageToken) {
        setLoading(true);
        apiService.post(new ConsumerApi.ShareAdMessageLinkUsedRequest({
            shareAdMessageToken: shareAdMessageToken
          }))
          .then((res) => setShareAdMessageUsedLink(res))
          .finally(() => setLoading(false));
      }
    }
  }, [apiService, referBusinessToken, referFriendToken, setLoading, setReferBusinessUsedLink, setReferFriendUsedLink, setShareAdMessageUsedLink, setSharePlaceUsedLink, shareAdMessageToken, sharePlaceToken, userStatus])

  useEffect(() => {
    if (!placeBookmarks && userStatus && userStatus.status === ConsumerApi.UserStatus.Ready) {
      const requestBookmarks = new ConsumerApi.GetFollowingListRequest();
      apiService.get(requestBookmarks).then((response) => {
        setPlaceBookmarks(response.places);
      })
    }
  }, [placeBookmarksState, apiService, userStatus])

  useEffect(() => {
    if (!adMessagesBookmarks && userStatus && userStatus.status === ConsumerApi.UserStatus.Ready) {
      const requestBookmarks = new ConsumerApi.GetStarredAdMessageListRequest();
      apiService.get(requestBookmarks).then((response) => {
        setAdMessagesBookmarks(response.starredList.map(s => s.adMessage));
      })
    }
  }, [adMessagesBookmarks, apiService, userStatus])

  return (
    <>
      {userStatus ?
        userStatus.status === ConsumerApi.UserStatus.Ready ?
          <>
            <Sidebar />
            <Routes>
              <Route path="/login/confirm-email" element={<ConfirmEmail />} />
              <Route path="/login" element={<Navigate to="/" />} />

              <Route path="/account" element={<Account />} />
              <Route path="/finder" element={<Navigate to="/finder/places" />} />
              <Route path="/finder/:paramTab" element={<Finder />} />
              <Route path="/finder/:paramTab/:paramFindMode" element={<Finder />} />
              <Route path="/finder/:paramTab/:paramFindMode/:paramFilter" element={<Finder />} />

              <Route path="/finder-map" element={<Navigate to="/finder-map/places" />} />
              <Route path="/finder-map/:paramTab" element={<FinderMap />} />
              <Route path="/finder-map/:paramTab/:paramFindMode" element={<FinderMap />} />
              <Route path="/finder-map/:paramTab/:paramFindMode/:paramFilter" element={<FinderMap />} />

              <Route path="/place/:placeGuid" element={<ExpandedPlace />} />
              <Route path="/admessage/:adMessageGuid" element={<ExpandedAdMessage />} />

              <Route path="/bookmarks" element={<Navigate to="/bookmarks/places" />} />
              <Route path="/bookmarks/:paramTab" element={<Bookmarks />} />

              <Route path="/rewards" element={<Rewards />} />
              <Route path="/lottery" element={<Lottery />} />

              <Route path="/settings" element={<Settings />} />

              <Route path="*" element={<Navigate to="/finder/places" />} />
            </Routes>
            {showReferFriends ? <ReferFriends /> : null}
            {earnedPointsBySignup ?
              <EarnedPointsBySignup />
            : newVersionAvailable ?
              <div className="z-999999 fixed left-0 right-0 top-0">
                <div className="w-full mx-auto max-w-[375px] px-[20px] py-[30px]">
                  <div className="bg-white flex flex-col items-center p-4 rounded-[4px] shadow-md py-[30px] px-[30px]">
                    <div className="w-[56px] h-[56px] border rounded-full border-gray-light flex items-center justify-center">
                      <PhoneIcon />
                    </div>
                    <div className="mt-20px font-bold text-[20px]">{t("New Version Available")}</div>
                    <div className="mt-20px w-full">
                      <Button onClick={() => window.location.reload()} title={t("Update Now")} />
                    </div>
                  </div>
                </div>
              </div>
            : suggestPwaInstallation ?
              <InstallAppInstructions onDone={() => {
                setSuggestPwaInstallation(false);
                setSkipSuggestPwaInstallation(true);
              }} />
            : null}
          </>
        : userStatus.status === ConsumerApi.UserStatus.CompleteProfile ?
          <Routes>
            <Route path="/login/complete-profile" element={<CompleteProfile />} />
            <Route path="*" element={<Navigate to="/login/complete-profile" />} />
          </Routes>
        :
          <Routes>
            <Route path="/login" element={<Welcome />} />
            <Route path="/login/confirm-email" element={<ConfirmEmail />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
      : null
      }
      {loading ? <Spinner fullScreen={true} /> : null}
    </>
  );
}

export default Main;