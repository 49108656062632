import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ConsumerApi } from "../ConsumerApi.dto";
import { AdMessageInterestIcon, AdMessageThankYouIcon } from '../icons';
import { PlaceFooter } from './PlaceFooter';
import { apiServiceState } from '../atoms';
import { AdMessage } from '../ad-message';

type PlaceAdMessagesProps = {
  adMessages?: ConsumerApi.AdMessageDto[]
  place: ConsumerApi.PlaceDto
}

export const PlaceAdMessages: React.FC<PlaceAdMessagesProps> = (props) => {

  const apiService = useRecoilValue(apiServiceState);
  const [interested, setInterested] = useState(false)
  const { t } = useTranslation();
  const place = props.place;

  const placePoke = useCallback(() => {
    setInterested(true);
    const request = new ConsumerApi.PlacePokeRequest({placeGuid: place.guid});
    apiService.post(request).catch(() => setInterested(false));
  }, [apiService, place.guid])

  return (
    <>
      {props.adMessages && props.adMessages.length > 0 ?
        props.adMessages.map((a, i) =>
          <>
            <AdMessage key={a.id} adMessage={a} type="place" />
          </>
        )
      :
        <div className="flex flex-col px-[12px]">
          <div className="flex flex-col items-center justify-start h-[320px]">
            <div className={`mb-[20px] ${interested ? 'mt-[117px]' : 'mt-[90px]'}`}>
              {!interested ?
                <AdMessageInterestIcon /> : <AdMessageThankYouIcon />
              }
            </div>
            <div className="text-body-bold mb-[20px] text-center">
              {!interested ? t("Interested in Ads?") : t("You have expressed interest in Ads from this business.")}
            </div>
            {!interested &&
              <button onClick={() => placePoke()} className="w-full text-blue text-title-bold h-[45px] border border-blue rounded-[3px]">
                {t("Let Us Know")}
              </button>
            }
          </div>
        </div>
      }
    </>
  )
}