import React, { useEffect } from 'react';

type OverlayProps = {
  onClose: () => void
  zIndexClass?: string
  noPadding?: boolean
  verticalAlignment?: "top" | "center" | "bottom"
}

export const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = (props) => {

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden")
    };
  }, [])

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-20 ${props.zIndexClass ?? 'z-70'} p-[20px] overflow-scroll`}>
      <div className={`flex ${props.verticalAlignment === "top" ? "items-start" : props.verticalAlignment === "bottom" ? "items-end" : "items-center"} justify-center min-h-full`}>
        <div className="max-w-sm w-full bg-white">
          <div className="flex justify-end pt-[15px] px-[15px]">
            <div onClick={props.onClose} className="cursor-pointer">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.707107 0L0 0.707107L5.89645 6.60355L0 12.5L0.707107 13.2071L6.60355 7.31066L12.5 13.2071L13.2071 12.5L7.31066 6.60355L13.2071 0.707107L12.5 0L6.60355 5.89645L0.707107 0Z" fill="currentColor"/>
              </svg>
            </div>
          </div>
          <div className={`${props.noPadding ? "" : "px-[15px] pt-[15px] pb-[15px]"}`}>
            { props.children }
          </div>
        </div>
      </div>
    </div>
  );
}