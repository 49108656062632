import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from "../ConsumerApi.dto";
import { formatAddress } from '../helpers/PlaceHelper';
import { PlaceContactIcon, PlaceWebshopIcon, PlaceWebsiteIcon } from '../icons';
import { PlaceMap } from '../place-map';
import maplibregl, { Map } from 'maplibre-gl'
import findOnMapSelectedImage from '../images/find-on-map-selected.svg';
import { mapTilerBaseUrl } from '../Constants';
import { isPlaceDto } from 'model/Place';

type PlaceContactProps = {
  place: ConsumerApi.PlaceBasicDto | ConsumerApi.PlaceDto
}

export const PlaceContact: React.FC<PlaceContactProps> = (props) => {

  const { t } = useTranslation();
  const place = props.place;

  const industry = useMemo(() => place.categories[0].absoluteSlug.split(".")[0], [place]);

  const [expanded, setExpanded] = useState(false);

  const mapRef = useRef<Map>();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current && place.longitude && place.latitude) {
      mapRef.current = new maplibregl.Map({
        // React Ref instead of element ID to make the component cohesive
        // and avoid ensuring unique map IDs in the project.
        container: mapContainerRef.current,
        style: `${mapTilerBaseUrl}?key=${process.env.REACT_APP_MAPTILER_KEY || "PQqhWYuzCE3UImKAKhVn"}`, // style URL
        center: [place.longitude, place.latitude], // starting position
        zoom: 16, // starting zoom,
        interactive: false,
        attributionControl: false
      });

      mapRef.current.addControl(new maplibregl.AttributionControl({
        compact: false
      }));

      const el = document.createElement('img');
      (el as HTMLImageElement).src = findOnMapSelectedImage;
      new maplibregl.Marker(el)
        .setLngLat([place.longitude, place.latitude])
        .addTo(mapRef.current as Map);
    }
  }, [place]);

  return (
    <>
      <div className="flex flex-col px-[12px]">
        <div className="py-[15px]">
          <div className="text-title-bold pb-[5px]">
            {place.name}
          </div>
          <div className="text-body" dangerouslySetInnerHTML={{__html: formatAddress(place)}} />
        </div>
        {isPlaceDto(place) && place.mall &&
          <div className="py-[15px] border-t border-gray-lighther">
            <div className="text-title-bold pb-[5px]">
              {t("Shopping Mall")}
            </div>
            <div className="text-body">
              {place.mall.name}
            </div>
          </div>
        }
        <div className="flex justify-between items-center py-[20px] border-t border-gray-lighther">
          {place.contact?.phone ?
            <a href={`tel:${place.contact.phone}`} className="flex-auto flex items-center">
              <div className="flex justify-center">
                <PlaceContactIcon />
              </div>
              <div className="text-body pl-[5px]">
                {place.contact?.phone}
              </div>
            </a>
          :
            <div className="flex justify-center text-disabled">
              <PlaceContactIcon />
            </div>}
          <div className="flex-none flex">
            {place.contact?.website ?
              <a href={place.contact.website} rel="noreferrer" target="_blank" className="flex items-center">
                <div className="flex justify-center">
                  <PlaceWebsiteIcon />
                </div>
                <div className="text-body pl-[5px]">
                  {t("Website")}
                </div>
              </a>
            :
              <div className="flex items-center text-disabled">
                <div className="flex justify-center">
                  <PlaceWebsiteIcon />
                </div>
                <div className="text-body pl-[5px]">
                  {t("Website")}
                </div>
              </div>
            }
            {industry === "fashion" ?
              place.contact?.webShop ?
                <a href={place.contact.webShop} rel="noreferrer" target="_blank" className="flex items-center ml-[10px]">
                  <div className="flex justify-center">
                    <PlaceWebshopIcon />
                  </div>
                  <div className="text-body pl-[5px]">
                    {t("Webshop")}
                  </div>
                </a>
              :
                <div className="flex items-center text-disabled ml-[10px]">
                  <div className="flex justify-center">
                    <PlaceWebshopIcon />
                  </div>
                  <div className="text-body pl-[5px]">
                    {t("Webshop")}
                  </div>
                </div>
            : null}
          </div>
        </div>
        <div
          onClick={() => setExpanded(true)}
          ref={mapContainerRef}
          className="h-[275px] cursor-pointer" />
      </div>

      {expanded ?
        <PlaceMap place={props.place} onBack={() => setExpanded(false)} />
      : null}

    </>
  )
}