import { Button } from '../button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumerApi } from '../ConsumerApi.dto';

type FriendReferralsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse
}

const FriendReferrals: React.FC <FriendReferralsProps>= (props) => {

  const { t } = useTranslation();


  return (
    <>
      <div className='style-heading'>
        {t("Friend Referrals")}
      </div>
      <div className="flex items-center mt-[10px] style-body-copy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      <div className="mt-[40px]">
        <div className="text-body flex justify-center text-center">
          {t("Text Message / SMS")}
        </div>
        <div className="text-body flex justify-center text-center">
          {t("(Only Norwegian Numbers)")}
        </div>
        <div className="text-[32px] font-bold flex justify-center">
          {props.rewardsStatus.referFriend.points}
        </div>
        <div className="text-body flex justify-center text-center">
          {t("Points")}
        </div>
      </div>
      <div className='mt-[40px]'>
        <Button title={t("Invite Friends")} />
      </div>
    </>
  )
}

export default FriendReferrals;