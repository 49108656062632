import { ConsumerApi } from '../ConsumerApi.dto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareIcon } from '../icons';

type SharePlacesProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse
}

export const SharedPlaces: React.FC<SharePlacesProps> = (props) => {

  const { t } = useTranslation();

  return (
    <>
      <div className='style-heading'>
        {t("Shared Places")}
      </div>
      <div className="flex items-center mt-[10px] style-body-copy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      <div className="mt-[40px]">
        <div className="text-body flex justify-center text-center">
          {t("Text Message / SMS")}
        </div>
        <div className="text-body flex justify-center text-center">
          {t("(Only Norwegian Numbers)")}
        </div>
        <div className="text-[32px] font-bold flex justify-center">
          {props.rewardsStatus.sharePlace.points}
        </div>
        <div className="text-body flex justify-center text-center">
          {t("Points")}
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="text-body flex justify-center text-center">
          {t("Use Place Share Icon")}
        </div>
        <div className="mt-[10px] flex justify-center">
          <ShareIcon />
        </div>
      </div>
    </>
  )
}

export default SharedPlaces;