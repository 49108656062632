import React, {  } from 'react';
import { ConsumerApi } from "../ConsumerApi.dto";
import { ArrowBackIcon } from '../icons';

type PlaceMenuProps = {
  menu: ConsumerApi.MenuDto
  onBack: () => void
}

export const PlaceMenu: React.FC<PlaceMenuProps> = (props) => {

  const menu = props.menu;

  return (
    <div className="fixed inset-0 z-40 bg-white overflow-y-auto mx-auto max-w-md">
      <div className="fixed top-0 left-0 right-0 z-50 bg-white">
        <div className="w-full mx-auto max-w-md border-b border-gray-lighther">
          <div className="flex items-center h-14 w-full bg-white">
            <div onClick={props.onBack} className="flex-none p-3 w-20 cursor-pointer">
              <ArrowBackIcon />
            </div>
            <div className="flex-auto text-center style-heading">
              {menu.name}
            </div>
            <div className="w-20"></div>
          </div>
        </div>
      </div>

      <iframe title={menu.name} className="mt-14 h-full w-full" src={menu.url}>
      </iframe>
    </div>
  )
}