import { ConsumerApi } from '../ConsumerApi.dto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeparatorHeader } from '../separator-header';
import { Button } from '../button';
import { useNavigate } from 'react-router-dom';

type EarnRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse
}

const EarnRewardPoints: React.FC<EarnRewardPointsProps> = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();


  return (
    <>
      <SeparatorHeader title={t("Use Reward Points")} />
      <div className="flex items-center mt-[15px] style-body-copy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      <div className='mt-[40px]'>
        <Button title={t("Use Reward Points")} onClick={() => navigate("/lottery")} />
      </div>
    </>
  )
}

export default EarnRewardPoints;