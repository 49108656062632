import React from "react";

export const AdMessageThankYouIcon = () => {
  return (
    <svg width="45" height="64" viewBox="0 0 45 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.4804 35.3014H39.7418C42.3562 35.3014 44.5 33.1444 44.5 30.5139C44.5 27.8571 42.3824 25.7264 39.7418 25.7264H21.8072H16.4739L16.4216 4.86673C16.4477 2.20994 14.3562 0.0266445 11.7157 0.000339761C9.07516 -0.025965 7.79412 1.47341 6.77451 5.10347L1.70261 22.2016C1.70261 22.2016 0.5 25.5686 0.5 32.4604C0.5 40.4571 0.5 53.8199 0.5 58.0024C0.5 64.1051 5.59804 63.9999 5.59804 63.9999H16.1601H30.1993C32.8137 63.9999 34.9575 61.8692 34.9575 59.2124C34.9575 56.5819 32.8137 54.4249 30.1993 54.4249H35.0098C37.6242 54.4249 39.768 52.2943 39.768 49.6375C39.768 47.007 37.6503 44.85 35.0098 44.85H39.4543C42.0686 44.85 44.2124 42.7193 44.2124 40.0625C44.2386 37.4584 42.1209 35.3014 39.4804 35.3014Z" fill="currentColor"/>
    </svg>
  );
}

export default AdMessageThankYouIcon;
