import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ConsumerApi } from '../ConsumerApi.dto';
import { MenuIcon, CancelIcon, FilterIcon, BookmarkIcon } from '../icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiServiceState, lastLocationState, showSidebarState } from '../atoms';
import { useTranslation } from 'react-i18next';
import { Filter } from './Filter';
import { FiltersSelected } from './FiltersSelected';
import { defaultSearchRadiusInKm, initialPageSize, pageSize } from '../Constants';
import { loadingState } from '../atoms/loading';
import { BookmarkSearch } from '../bookmark-search';
import { usePlaceLocation } from '../hooks/UsePlaceLocation';
import { buildUrl, convertFiltersToUrl } from "../helpers/FinderHelper";
import { FinderTab, TabType } from '../finder-tab';
import { Place } from '../place';
import { AdMessage } from '../ad-message';
import { copyFiltersUrlToQueryFilterDto, convertResponseToFilters } from './Helper';
import { Header } from '../header';
import { useFinderParams } from '../hooks/UseFinderParams';
import { useFinderScroll } from '../hooks/UseFinderScroll';
import { DateTime } from 'luxon';
import Categories from './Categories';
import { FinderLocation } from './FinderLocation';

const ChangeLocation = React.lazy(() => import('../change-location/ChangeLocation'));
const Filters = React.lazy(() => import('./Filters'));

export const FinderFiltered: React.FC = () => {

  const {t} = useTranslation();

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const setLoading = useSetRecoilState(loadingState);
  const lastLocation = useRecoilValue(lastLocationState);

  const apiService = useRecoilValue(apiServiceState);
  const [places, setPlaces] = useState<ConsumerApi.PlaceDto[]>();
  const [adMessages, setAdMessages] = useState<ConsumerApi.AdMessageDto[]>();

  const [adMessageCount, setAdMessageCount] = useState<number>();
  const [placeCount, setPlaceCount] = useState<number>();

  const [filters, setFilters] = useState<{[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined}>();
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>();

  const [selectedCategory, setSelectedCategory] = useState<ConsumerApi.CategoryFacetTreeDto>();
  const [parentCategory, setParentCategory] = useState<ConsumerApi.CategoryFacetTreeDto>();
  const [showCategories, setShowCategories] = useState(false);

  const [adMessageSaveFoodAndMoneyCount, setAdMessageSaveFoodAndMoneyCount] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isAddingBookmark, setIsAddingBookmark] = useState(false);
  
  const googleAttribution = useRef<HTMLDivElement>(null);
  const scrollableAreaRef = useRef<HTMLDivElement>(null);
  
  const [queryFilterDto, setQueryFilterDto] = useState<ConsumerApi.QueryFilterDto>();
  const [queryLocationDto, setQueryLocationDto] = useState<ConsumerApi.QueryLocationDto>();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<TabType>();
  const { offsetPlaces, offsetAdMessages, onScroll, resetScroll } = useFinderScroll(selectedTab, scrollableAreaRef);

  const { paramLocation } = useFinderParams();

  const { paramTab, paramFindMode, paramFilter } = useParams<any>();

  const categoryAbsoluteSlug = useMemo(() => paramFindMode, [paramFindMode]);

  const isSaveFoodAndMoney = useMemo(() =>
    selectedFilters?.some(f => f.data as ConsumerApi.FeatureType === ConsumerApi.FeatureType.SaveFoodAndMoney), [selectedFilters]);

  const [hasFilters, setHasFilters] = useState(false);

  const hasSubcategories = useMemo(() =>
    parentCategory || (selectedCategory && selectedCategory.children && selectedCategory.children.length > 0),
    [parentCategory, selectedCategory])

  const {
    showChangeLocation,
    setShowChangeLocation,
    onApplyChangeLocation} = usePlaceLocation(googleAttribution, "finder", paramTab, paramLocation, paramFindMode, undefined, undefined, paramFilter);

  const fetch = useCallback((cacheVersion?: number) => {
    if (queryFilterDto) {
      setIsFetching(true);
      resetScroll();
      apiService.post(new ConsumerApi.FindRequest({
        offset: 0,
        size: initialPageSize,
        resultAt: (new Date()).toUTCString(),
        cacheVersion: cacheVersion,
        location: queryLocationDto,
        filter: queryFilterDto
      })).then(response => {
        let parentCategory: ConsumerApi.CategoryFacetTreeDto | undefined;

        let selectedCategory: ConsumerApi.CategoryFacetTreeDto | undefined;
        for (const category of response.categoryFacetTrees) {
          if (category.absoluteSlug === categoryAbsoluteSlug) {
            selectedCategory = category;
            break;
          } else if (category.children) {
            selectedCategory = category.children.find(c => c.absoluteSlug === categoryAbsoluteSlug);
            if (selectedCategory) {
              parentCategory = category;
              break;
            }
          }
        }

        setParentCategory(parentCategory);

        setSelectedCategory(selectedCategory);
        setPlaces(response.places);
        setAdMessages(response.adMessages);
        setAdMessageCount(response.adMessageCount);
        setPlaceCount(response.placeCount);
        setAdMessageSaveFoodAndMoneyCount(response.adMessageSaveFoodAndMoneyCount ? response.adMessageSaveFoodAndMoneyCount : 0);

        scrollableAreaRef.current?.scrollTo({top: 0});

        const hasFilters = (categories?: ConsumerApi.CategoryFacetTreeDto[], level = 0): boolean =>
          categories ?
            categories.some(c =>
              level === 0 ?
                  c.featureTypes?.some(ft => ft === ConsumerApi.FeatureType.Filtering) &&
                  (c.type === ConsumerApi.CategoryFacetTreeType.Selected || hasFilters(c.children, level + 1))
                :
                  c.type === ConsumerApi.CategoryFacetTreeType.Selected || hasFilters(c.children, level + 1)
            )
          : false;

        setHasFilters(hasFilters(response.categoryFacetTrees));

      }).finally(() => setIsFetching(false));
    }
  }, [apiService, queryFilterDto, queryLocationDto]);

  const fetchMorePlaces = useCallback(() => {
    apiService.post(new ConsumerApi.FindPlaceRequest({
      offset: offsetPlaces,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto,
      filter: queryFilterDto
    })).then(response => {
      setPlaces(places => [...(places || []), ...response.places]);
    });
  }, [apiService, offsetPlaces, queryFilterDto, queryLocationDto]);

  const fetchMoreAdMessages = useCallback(() => {
    apiService.post(new ConsumerApi.FindAdMessageRequest({
      offset: offsetAdMessages,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto,
        filter: queryFilterDto
    })).then(response => {
      setAdMessages(adMessages => [...(adMessages || []), ...response.adMessages]);
    });
  }, [apiService, offsetAdMessages, queryFilterDto, queryLocationDto]);

  const removeFilter = useCallback((filter: Filter) => {
    const _filters = {...filters} as {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[]};
    const key = Object.keys(_filters).find(k => _filters[k as ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]?.some(f => f.featureType === filter.featureType && f.data === filter.data))
    const values = _filters[key as ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"] as Filter[];
    const index = values.findIndex(f => f.featureType === filter.featureType && f.data === filter.data);
    values[index] = {...values[index], selected: false};
    setFilters(_filters);
  }, [filters])

  const navigateToMap = useCallback(() => {
    if (lastLocation && paramLocation && paramTab) {
      navigate(buildUrl("finder-map",
        paramTab,
        paramLocation,
        paramFindMode,
        undefined,
        undefined,
        paramFilter))
    }
  }, [lastLocation, navigate, paramTab, paramLocation, paramFindMode, paramFilter]);

  const onClickTab = useCallback(() => {
    scrollableAreaRef.current?.scrollTo({top: 0});
  }, [scrollableAreaRef])

  useEffect(() => {
    setSelectedTab(paramTab as TabType);
  }, [paramTab]);

  useEffect(() => {
    if (offsetPlaces > 0) fetchMorePlaces()
  }, [offsetPlaces, fetchMorePlaces]);

  useEffect(() => {
    if (offsetAdMessages > 0) fetchMoreAdMessages()
  }, [offsetAdMessages, fetchMoreAdMessages]);

  useEffect(() => fetch(), [fetch, queryFilterDto, queryLocationDto]);

  useEffect(() => {
    if (lastLocation) {
      let queryLocationDto = new ConsumerApi.QueryLocationDto({
        latitude: lastLocation.location.lat,
        longitude: lastLocation.location.lng,
        searchRadiusInKm: defaultSearchRadiusInKm,
      });
      let queryFilterDto = new ConsumerApi.QueryFilterDto();

      queryFilterDto.categoryAbsoluteSlugs = categoryAbsoluteSlug ? [categoryAbsoluteSlug] : [];

      if (paramFilter) {
        queryFilterDto = copyFiltersUrlToQueryFilterDto(queryFilterDto, paramFilter, categoryAbsoluteSlug);
      }
      setQueryLocationDto(queryLocationDto);
      setQueryFilterDto(queryFilterDto);
    }
  }, [paramFilter, lastLocation, categoryAbsoluteSlug]);


  useEffect(() => {
    if (filters) {
      const filtersUrl = convertFiltersToUrl(filters, categoryAbsoluteSlug);
      const newUrl = `/finder/${paramTab}/${paramFindMode}/${filtersUrl ? `${filtersUrl}/` : ""}?location=${paramLocation}`;
      const currentUrl = `${window.location.pathname}${window.location.search}`;
      if (currentUrl !== newUrl) {
        navigate(newUrl, {replace: true});
      }
      setSelectedFilters(
        Object.keys(filters)
          .filter(k => filters[k as ConsumerApi.FeatureType])
          .flatMap(k => (filters[k as ConsumerApi.FeatureType] as Filter[]).filter(f => f.selected)));
    } else if (queryFilterDto && queryLocationDto && paramFilter && categoryAbsoluteSlug) {
      apiService.post(new ConsumerApi.FindAvailableFilterRequest({
        location: new ConsumerApi.QueryLocationDto({
          longitude: queryLocationDto.longitude,
          latitude: queryLocationDto.latitude,
          searchRadiusInKm: queryLocationDto.searchRadiusInKm,
        }),
        filter: new ConsumerApi.QueryFilterDto({
          categoryAbsoluteSlugs: [categoryAbsoluteSlug],
          saveFoodAndMoney: queryFilterDto.saveFoodAndMoney
        })
      })).then(response => {
        setFilters(convertResponseToFilters(response, queryFilterDto, t));
      })
    }
  }, [apiService, filters, navigate, paramFindMode, categoryAbsoluteSlug, paramTab, paramFilter, queryFilterDto, t, paramLocation, queryLocationDto])

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching, setLoading])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30">
        <div className="w-full mx-auto max-w-md bg-white">
          <Header
            leftIcon={<MenuIcon />}
            onClickLeftIcon={() => setShowSidebar(true)}
            onClickTitle={() => fetch(DateTime.now().toMillis())}
            title={"BROVS"} />

          <div className="bg-gray-lightest">
            <FinderLocation
              location={lastLocation}
              showNavigateToMap={true}
              onClickLocation={() => setShowChangeLocation(true)}
              onClickNavigateToMap={() => navigateToMap()} />
          </div>
        </div>
      </div>
      <div
        ref={scrollableAreaRef}
        className="h-full overflow-y-scroll overflow-x-hidden pt-[104px]"
        onScroll={onScroll}>
        <div
          className="bg-near-white sticky z-20 top-[-128px]">
          {selectedCategory ?
            <div className="flex h-[132px]">
              <div className="flex-none h-full w-full flex relative">
                <img className="absolute h-full w-full inset-0 z-0 object-cover" alt={selectedCategory.name} src={selectedCategory.imageUrl} />
                <div className="absolute inset-0 bg-blue bg-opacity-70 z-10"></div>
                <div className="absolute inset-0 z-20 px-[12px]">
                  <div className="flex justify-between items-center mt-[14px] text-white">
                    <Link className="cursor-pointer" to={`/finder/${paramTab}/?location=${paramLocation}`}>
                      <CancelIcon />
                    </Link>
                    {hasFilters ?
                      <div className="flex items-center">
                        {selectedFilters && selectedFilters.length > 0 && !isSaveFoodAndMoney ?
                          <div
                            className="flex-none flex flex-col items-center cursor-pointer px-[8px]"
                            onClick={() => setIsAddingBookmark(true)}>
                            <BookmarkIcon />
                            <div className="text-small">
                              {t("Bookmark")}
                            </div>
                          </div>
                        : null}
                        <div
                          className="flex-none flex flex-col items-center cursor-pointer pl-[8px]"
                          onClick={() => setShowFilters(true)}>
                          <FilterIcon />
                          <div className="text-small">
                            {t("Filter")}
                          </div>
                        </div>
                      </div>
                    : null}
                  </div>
                  <div
                    onClick={() => hasSubcategories ? setShowCategories(true) : null}
                    className={hasSubcategories ? "cursor-pointer" : ""}>
                    <div
                      className="flex-none text-white text-24 font-extrabold text-center w-full mt-[7px]">
                      { isSaveFoodAndMoney ?
                        t("Save Food & Money")
                      : parentCategory || !hasSubcategories ? selectedCategory.name : t("All Categories")}
                    </div>
                  </div>
                  {selectedFilters ?
                    <div className="mt-[7px]">
                      <FiltersSelected
                        onRemove={(filter) => removeFilter(filter)}
                        selectedFilters={selectedFilters} />
                    </div>
                  : null}
                </div>
              </div>
            </div>
          : null}
          <div className="mt-[12px] h-[40px] flex">
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.adMessages}
              selected={selectedTab === TabType.adMessages}
              count={adMessageCount} />
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.places}
              selected={selectedTab === TabType.places}
              count={placeCount} />
          </div>
        </div>

        {!places || !adMessages ?
            <div>Loading...</div>
          : paramTab === TabType.places ?
            places?.map((p, i) => <Place key={p.id} place={p} /> )
          :
            adMessages?.map((a, i) => <AdMessage key={a.id} adMessage={a} /> )
        }
      </div>

      <div ref={googleAttribution}></div>

      {showChangeLocation && lastLocation ?
        <ChangeLocation
          onApply={onApplyChangeLocation}
          onBack={() => setShowChangeLocation(false)} />
      : showFilters && queryFilterDto && queryLocationDto && selectedCategory ?
        <Filters
          category={selectedCategory}
          queryFilterDto={queryFilterDto}
          queryLocationDto={queryLocationDto}
          onApply={(filters) => {setShowFilters(false); setFilters(filters);}}
          onBack={() => setShowFilters(false)} />
      : showCategories && (parentCategory || selectedCategory) ?
        <Categories
          category={parentCategory || selectedCategory as ConsumerApi.CategoryDto}
          adMessageSaveFoodAndMoneyCount={adMessageSaveFoodAndMoneyCount}
          onSelect={(absoluteSlug, saveFoodAndMoney) => {
            setShowCategories(false);
            setFilters(undefined);
            setSelectedFilters(undefined);
            navigate(
              `/finder/${paramTab}/${encodeURI(absoluteSlug)}/${saveFoodAndMoney ? "Information=SaveFoodAndMoney/" : ""}?location=${paramLocation}`);
          }}
          onBack={() => setShowCategories(false)} />
      : null}

      {queryFilterDto && queryLocationDto && isAddingBookmark ? <BookmarkSearch queryFilterDto={queryFilterDto} queryLocationDto={queryLocationDto} onClose={() => setIsAddingBookmark(false)} /> : null}
    </>
  )
}