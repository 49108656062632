import React, { Ref } from 'react';

type InputProps = {
  reference?: Ref<HTMLInputElement>,
  autoFocus?: boolean,
  readOnly?: boolean,
  type?: string,
  placeholder?: string,
  autoComplete?: string,
  maxLength?: number,
  label?: string,
  error?: string,
  value?: string,
  pattern?: string,
  disabled?: boolean,
  required?: boolean,
  inputMode?: "none" | "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url",
  textAlign?: "left" | "center" | "right",
  onChange?: (value: string) => void,
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: () => void,
  centerError?: boolean
}

export const Input: React.FC<InputProps> = (props) => {

  const textClass = `text-${props.textAlign ? props.textAlign : "left"}`

  return (
    <div className="flex flex-col w-full">
      {props.label ?
        <div className="text-title-medium mb-10px">
          {props.label}
        </div>
      : null}
      <input
        type={props.type}
        ref={props.reference}
        autoFocus={props.autoFocus}
        onKeyPress={props.onKeyPress}
        autoComplete={props.autoComplete}
        style={{height: "50px"}}
        placeholder={props.placeholder}
        value={props.value}
        inputMode={props.inputMode}
        pattern={props.pattern}
        maxLength={props.maxLength}
        readOnly={props.readOnly}
        disabled={props.disabled}
        {...(props.required ? { required: true } : {})}
        onChange={(e) => props.onChange ? props.onChange(e.currentTarget.value) : null}
        onFocus={props.onFocus}
        className={`w-full style-input h-50px px-20px border focus:border-blue-light ${props.error ? "border-red" : "border-gray-light"} ${textClass} ${props.disabled ? "bg-near-white" : ""}`} />
      {props.error ?
        <div className={`text-red style-body-copy mt-10px ${props.centerError ? "text-center" : ""}`}>
          {props.error}
        </div>
      : null}
    </div>
  )
}