import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsumerApi } from '../ConsumerApi.dto';
import { MenuIcon, SearchIcon } from '../icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiServiceState, lastLocationState, showSidebarState } from '../atoms';
import { useTranslation } from 'react-i18next';
import { defaultSearchRadiusInKm, initialPageSize, pageSize } from '../Constants';
import { loadingState } from '../atoms/loading';
import { usePlaceLocation } from '../hooks/UsePlaceLocation';
import { FinderTab, TabType } from '../finder-tab';
import { Place } from '../place';
import { AdMessage } from '../ad-message';
import { Header } from '../header';
import { useFinderParams } from '../hooks/UseFinderParams';
import TextSearch from './TextSearch';
import { useFinderScroll } from '../hooks/UseFinderScroll';
import { DateTime } from 'luxon';
import { FinderLocation } from './FinderLocation';
import { throttle } from 'lodash';

const ChangeLocation = React.lazy(() => import('../change-location/ChangeLocation'));
const Categories = React.lazy(() => import('./Categories'));

export const FinderNavigate: React.FC = () => {

  const {t} = useTranslation();

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const setLoading = useSetRecoilState(loadingState);
  const lastLocation = useRecoilValue(lastLocationState);

  const apiService = useRecoilValue(apiServiceState);
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [places, setPlaces] = useState<ConsumerApi.PlaceDto[]>();
  const [adMessages, setAdMessages] = useState<ConsumerApi.AdMessageDto[]>();
  const [rootCategories, setRootCategories] = useState<ConsumerApi.CategoryFacetTreeDto[]>();

  const [adMessageCount, setAdMessageCount] = useState<number>();
  const [placeCount, setPlaceCount] = useState<number>();

  const [selectedCategory, setSelectedCategory] = useState<ConsumerApi.CategoryFacetTreeDto>();
  const [adMessageSaveFoodAndMoneyCount, setAdMessageSaveFoodAndMoneyCount] = useState(0);

  const [isFetching, setIsFetching] = useState(false);
  
  const googleAttribution = useRef<HTMLDivElement>(null);
  const scrollableAreaRef = useRef<HTMLDivElement>(null);
  
  const [queryLocationDto, setQueryLocationDto] = useState<ConsumerApi.QueryLocationDto>();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<TabType>();

  const { offsetPlaces, offsetAdMessages, onScroll, resetScroll } = useFinderScroll(selectedTab, scrollableAreaRef);
  const onScrollThrottled = useCallback(throttle(onScroll, 100), [onScroll]);

  const { paramLocation } = useFinderParams();

  const { paramTab, paramFindMode, paramFilter } = useParams<any>();

  const categoryAbsoluteSlug = useMemo(() => paramFindMode as string, [paramFindMode]);

  const {
    showChangeLocation,
    setShowChangeLocation,
    onApplyChangeLocation} =
    usePlaceLocation(googleAttribution, "finder", paramTab, paramLocation, paramFindMode, undefined, undefined, paramFilter);

  const fetch = useCallback((cacheVersion?: number) => {
    if (queryLocationDto) {
      setIsFetching(true);
      resetScroll();
      apiService.post(new ConsumerApi.FindRequest({
        offset: 0,
        size: initialPageSize,
        resultAt: (new Date()).toUTCString(),
        cacheVersion: cacheVersion,
        location: queryLocationDto
      })).then(response => {
        setRootCategories(response.categoryFacetTrees);
        setPlaces(response.places);
        setAdMessages(response.adMessages);
        setAdMessageCount(response.adMessageCount);
        setPlaceCount(response.placeCount);
        setAdMessageSaveFoodAndMoneyCount(response.adMessageSaveFoodAndMoneyCount ? response.adMessageSaveFoodAndMoneyCount : 0);

        scrollableAreaRef.current?.scrollTo({top: 0});
      }).finally(() => setIsFetching(false));
    }
  }, [apiService, queryLocationDto]);

  const fetchMorePlaces = useCallback(() => {
    apiService.post(new ConsumerApi.FindPlaceRequest({
      offset: offsetPlaces,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto
    })).then(response => {
      setPlaces(places => [...(places || []), ...response.places]);
    });
  }, [apiService, offsetPlaces, queryLocationDto]);

  const fetchMoreAdMessages = useCallback(() => {
    apiService.post(new ConsumerApi.FindAdMessageRequest({
      offset: offsetAdMessages,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto
    })).then(response => {
      setAdMessages(adMessages => [...(adMessages || []), ...response.adMessages]);
    });
  }, [apiService, offsetAdMessages, queryLocationDto]);

  const onClickTab = useCallback(() => {
    scrollableAreaRef.current?.scrollTo({top: 0});
  }, [scrollableAreaRef])

  const selectCategory = useCallback((absoluteSlug: string, saveFoodAndMoney: boolean) => {
    setSelectedCategory(undefined);
    navigate(
      `/finder/${paramTab}/${encodeURI(absoluteSlug)}/${saveFoodAndMoney ? "Information=SaveFoodAndMoney/" : ""}${paramLocation ? `?location=${paramLocation}` : ""}`);
  }, [history, paramTab, paramLocation]);

  const selectRootCategory = useCallback((category: ConsumerApi.CategoryFacetTreeDto) => {
    if (category.children && category.children.length > 0) {
      setSelectedCategory(category);
    } else {
      selectCategory(category.absoluteSlug, false);
    }
  }, [selectCategory]);

  useEffect(() => {
    setSelectedTab(paramTab as TabType);
  }, [paramTab]);

  useEffect(() => {
    if (offsetPlaces > 0) fetchMorePlaces()
  }, [offsetPlaces, fetchMorePlaces]);

  useEffect(() => {
    if (offsetAdMessages > 0) fetchMoreAdMessages()
  }, [offsetAdMessages, fetchMoreAdMessages]);

  useEffect(() => fetch(), [fetch, queryLocationDto]);

  useEffect(() => {
    if (lastLocation) {
      let queryLocationDto = new ConsumerApi.QueryLocationDto({
        latitude: lastLocation.location.lat,
        longitude: lastLocation.location.lng,
        searchRadiusInKm: defaultSearchRadiusInKm,
      });
      setQueryLocationDto(queryLocationDto);
    }
  }, [paramFilter, lastLocation, categoryAbsoluteSlug]);


  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching, setLoading])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30">
        <div className="w-full mx-auto max-w-md bg-white">
          <Header
            leftIcon={<MenuIcon />}
            onClickLeftIcon={() => setShowSidebar(true)}
            onClickTitle={() => fetch(DateTime.now().toMillis())}
            title={"BROVS"}
            rightIcon={<SearchIcon />}
            onClickRightIcon={() => setShowTextSearch(true)} />
          <div className="bg-near-white flex">
            <FinderLocation
              location={lastLocation}
              showNavigateToMap={false}
              onClickLocation={() => setShowChangeLocation(true)} />
          </div>
        </div>
      </div>
      <div
        ref={scrollableAreaRef}
        className="h-full overflow-y-scroll overflow-x-hidden pt-[104px]"
        onScroll={onScrollThrottled}>
        <div className="bg-near-white sticky z-20 top-[-110px]">
          <div className="pl-[12px] flex overflow-x-scroll">
            {rootCategories?.map((c, i) =>
              <div
                onClick={() => selectRootCategory(c)}
                key={c.absoluteSlug}
                className={`${i > 0 ? 'ml-[10px]' : ''} ${i === rootCategories.length ? 'mr-[12px]' : ''} cursor-pointer bg-cover flex-none w-[150px] flex relative h-[112px]`}
                style={{backgroundImage: `url(${c.imageUrl})`}}>
                <div className="absolute inset-0 bg-blue bg-opacity-70 z-10"></div>
                <div className="absolute inset-0 z-20">
                  <div className="text-title-xbold text-white text-center w-full px-1 mt-12">
                    {i == 2 ? t("Shopping Malls") : c.name}
                  </div>
                  <div className="flex-none text-body text-white text-center w-full px-1">
                    {c.count}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-[12px] h-[40px] flex">
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.adMessages}
              selected={selectedTab === TabType.adMessages}
              count={adMessageCount} />
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.places}
              selected={selectedTab === TabType.places}
              count={placeCount} />
          </div>
        </div>

        {!places || !adMessages ?
            <div>Loading...</div>
          : paramTab === TabType.places ?
            places?.map((p, i) => <Place key={"place:" + p.id} place={p} /> )
          :
            adMessages?.map((a, i) => <AdMessage key={"ad:" + a.id} adMessage={a} /> )
        }
      </div>

      <div ref={googleAttribution}></div>

      {showChangeLocation && lastLocation ?
        <ChangeLocation
          onApply={onApplyChangeLocation}
          onBack={() => setShowChangeLocation(false)} />
      : showTextSearch && lastLocation ?
        <TextSearch
          location={lastLocation.location}
          searchRadiusInKm={defaultSearchRadiusInKm}
          onApply={() => setShowTextSearch(false)}
          onBack={() => {
            setShowTextSearch(false);
            navigate(`/finder/${paramTab}/?location=${paramLocation}`)
          }} />
      : selectedCategory ?
        <Categories
          category={selectedCategory}
          adMessageSaveFoodAndMoneyCount={adMessageSaveFoodAndMoneyCount}
          onSelect={selectCategory}
          onBack={() => setSelectedCategory(undefined)} />
      : null}
    </>
  )
}