import { ISOTimeToLocalTime } from "../helpers/StringHelper";
import { DateTime } from "luxon";
import { ConsumerApi } from "../ConsumerApi.dto";

export class PlaceOpenHourInfo {
  public readonly initialized: boolean;
  public readonly isOpen: boolean
  public readonly nextOpenAt?: string
  public readonly nextCloseAt?: string

  constructor(placeDto: ConsumerApi.PlaceBasicDto, locale: ConsumerApi.Locale) {

    if (!placeDto.openingHours?.weekly || Object.keys(placeDto.openingHours?.weekly).length === 0) {
      this.initialized = false;
      return;
    }

    this.initialized = true;

    const dateTime = DateTime.fromISO(DateTime.now().toISO(), { zone: placeDto.timeZoneDto.id });

    const openingHours = [
      placeDto.openingHours.weekly.monday,
      placeDto.openingHours.weekly.tuesday,
      placeDto.openingHours.weekly.wednesday,
      placeDto.openingHours.weekly.thursday,
      placeDto.openingHours.weekly.friday,
      placeDto.openingHours.weekly.saturday,
      placeDto.openingHours.weekly.sunday
    ];

    let openingHour: ConsumerApi.OpeningHour | undefined = openingHours[dateTime.weekday - 1];
    if (openingHour) {
      const timeNow = dateTime.hour + (dateTime.minute / 60);

      const openAtHour = openingHour.openAtHour || 0;
      const openAtMinute = openingHour.openAtMinute || 0;
      const closeAtHour = openingHour.closeAtHour || 0;
      const closeAtMinute = openingHour.closeAtMinute || 0;

      if (openingHour) {
        const timeOpening = openAtHour + (openAtMinute / 60);
        let timeClosing = closeAtHour + (closeAtMinute / 60);

        if (timeClosing < timeOpening) {
          timeClosing += 24;
        }

        this.isOpen = timeNow >= timeOpening && timeNow <= timeClosing;
      }

      if (this.isOpen) {
        this.nextCloseAt = ISOTimeToLocalTime(DateTime.now().set({ hour: closeAtHour, minute: closeAtMinute }).toISO(), locale)
      } else {
        openingHour = openingHours.find((h, i) => i > dateTime.weekday - 1 && h);
        if (!openingHour) {
          openingHour = openingHours.find(h => h);
        }
        if (openingHour) {
          this.nextOpenAt = ISOTimeToLocalTime(DateTime.now().set({ hour: openAtHour, minute: openAtMinute }).toISO(), locale)
        }
      }
    }
  }
}