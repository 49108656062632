
/*
  Based on:
  https://stackoverflow.com/a/57920600/2517261 and https://stackoverflow.com/a/52695341/2517261
*/
export const isPwa = () => {
  return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://')
  );
}