import React from "react";

export const BarSpecialtiesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.335 5.94483C15.5063 5.94483 14.8346 6.58667 14.8346 7.37843C14.8346 8.17018 15.5063 8.81202 16.335 8.81202C17.1637 8.81202 17.8355 8.17018 17.8355 7.37843C17.8355 6.58667 17.1637 5.94483 16.335 5.94483ZM14.7437 7.37843C14.7437 6.53873 15.4562 5.85801 16.335 5.85801C17.2139 5.85801 17.9263 6.53873 17.9263 7.37843C17.9263 8.21813 17.2139 8.89884 16.335 8.89884C15.4562 8.89884 14.7437 8.21813 14.7437 7.37843Z" fill="currentColor"/>
      <path d="M8.96689 5.2874L5.9246 2L5.90959 2.01268H2V2.60301H5.6579L8.50402 5.67844L8.96689 5.2874Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22 4.82544H4.06345L11.9988 13.9791V19.9155H7.75761C6.29788 19.9155 6.29788 22 7.75761 22H18.52C19.9797 22 19.9674 19.9155 18.52 19.9155H14.2056V14.0244L22 4.82544ZM16.335 5.94483C15.5063 5.94483 14.8346 6.58667 14.8346 7.37843C14.8346 8.17018 15.5063 8.81202 16.335 8.81202C17.1637 8.81202 17.8355 8.17018 17.8355 7.37843C17.8355 6.58667 17.1637 5.94483 16.335 5.94483Z" fill="currentColor"/>
    </svg>
  )
}