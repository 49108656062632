import React from 'react';

type SeparatorHeaderProps = {
  title: string
}

export const SeparatorHeader: React.FC<SeparatorHeaderProps> = (props) => {
  return (
    <div className="text-h7 border-b-2 w-full pb-[6px]">
      {props.title}
    </div>
  );
}