import React from "react";
import { LatLng } from "../model";
import { atom, selector } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { LocationIcon } from "../icons/location";
import { PlaceLocation } from "../model";
import { defaultLocation } from "../Constants";
import { userStatusState } from "./userStatus";

export const homeLocationState = selector<PlaceLocation>({
  key: 'homeLocationState',
  get: ({get}) => {
    const userStatus = get(userStatusState);
    return new PlaceLocation({
      name: "Home",
      locationType: ConsumerApi.RecentLocationType.Home,
      location: new LatLng(
        userStatus?.userProfile?.home?.latitude || defaultLocation.lat,
        userStatus?.userProfile?.home?.longitude || defaultLocation.lng),
      icon: <LocationIcon type={ConsumerApi.RecentLocationType.Home} />
    })
  }
});