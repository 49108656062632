﻿import React from "react";

export const Restaurant = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2088 6.49855V7.7401C17.7187 8.12148 21.262 11.8963 21.262 16.5051H3.65704C3.65704 11.8963 7.20036 8.12148 11.7102 7.7401V6.49855H10.387V5H14.4706V6.49855H13.2088ZM7.77206 11.5726C8.60226 10.7709 9.62876 10.1872 10.7377 9.88752C10.9775 9.82308 11.1206 9.57507 11.0554 9.3353C10.9909 9.09479 10.7429 8.95317 10.5039 9.01761C9.24663 9.35628 8.08601 10.0156 7.14492 10.9245C6.96659 11.0968 6.96134 11.3823 7.13443 11.5614C7.22284 11.6528 7.34048 11.6993 7.45811 11.6993C7.57051 11.6993 7.68365 11.6566 7.77206 11.5726Z" fill="currentColor"/>
      <path d="M2.8302 19.5782L2 17.4181H22.919L21.2586 19.5782H2.8302Z" fill="currentColor"/>
    </svg>

  )
}
