import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsumerApi } from '../ConsumerApi.dto';
import { MenuIcon } from '../icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiServiceState, lastLocationState, showSidebarState } from '../atoms';
import { useTranslation } from 'react-i18next';
import { defaultSearchRadiusInKm, initialPageSize, pageSize } from '../Constants';
import { loadingState } from '../atoms/loading';
import { usePlaceLocation } from '../hooks/UsePlaceLocation';
import { buildUrl } from "../helpers/FinderHelper";
import { FinderTab, TabType } from '../finder-tab';
import { Place } from '../place';
import { AdMessage } from '../ad-message';
import { copyFiltersUrlToQueryFilterDto } from './Helper';
import { InputSearch } from '../input-search';
import { Header } from '../header';
import { useFinderParams } from '../hooks/UseFinderParams';
import { useFinderScroll } from '../hooks/UseFinderScroll';
import { DateTime } from 'luxon';
import { FinderLocation } from './FinderLocation';

const ChangeLocation = React.lazy(() => import('../change-location/ChangeLocation'));
const TextSearch = React.lazy(() => import('./TextSearch'));

export const FinderSearch: React.FC = () => {

  const {t} = useTranslation();

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const setLoading = useSetRecoilState(loadingState);
  const lastLocation = useRecoilValue(lastLocationState);

  const apiService = useRecoilValue(apiServiceState);
  const [places, setPlaces] = useState<ConsumerApi.PlaceDto[]>();
  const [adMessages, setAdMessages] = useState<ConsumerApi.AdMessageDto[]>();

  const [adMessageCount, setAdMessageCount] = useState<number>();
  const [placeCount, setPlaceCount] = useState<number>();


  const [showTextSearch, setShowTextSearch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  
  const googleAttribution = useRef<HTMLDivElement>(null);
  const scrollableAreaRef = useRef<HTMLDivElement>(null);
  
  const [queryFilterDto, setQueryFilterDto] = useState<ConsumerApi.QueryFilterDto>();
  const [queryLocationDto, setQueryLocationDto] = useState<ConsumerApi.QueryLocationDto>();
  const searchTerm = useMemo(
    () => queryFilterDto && queryFilterDto.term && queryFilterDto.term.trim().length > 0 ? queryFilterDto.term : undefined, [queryFilterDto]);

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<TabType>();
  const { offsetPlaces, offsetAdMessages, onScroll, resetScroll } = useFinderScroll(selectedTab, scrollableAreaRef);

  const { paramLocation } = useFinderParams();

  const { paramTab, paramFindMode, paramFilter } = useParams<any>();

  const {
    showChangeLocation,
    setShowChangeLocation,
    onApplyChangeLocation} = usePlaceLocation(googleAttribution, "finder", paramTab, paramLocation, paramFindMode, undefined, undefined, paramFilter);

  const fetch = useCallback((cacheVersion?: number) => {
    if (queryFilterDto) {
      setIsFetching(true);
      resetScroll();
      apiService.post(new ConsumerApi.FindRequest({
        offset: 0,
        size: initialPageSize,
        resultAt: (new Date()).toUTCString(),
        cacheVersion: cacheVersion,
        location: queryLocationDto,
        filter: queryFilterDto
      })).then(response => {
        setPlaces(response.places);
        setAdMessages(response.adMessages);
        setAdMessageCount(response.adMessageCount);
        setPlaceCount(response.placeCount);

        scrollableAreaRef.current?.scrollTo({top: 0});
      }).finally(() => setIsFetching(false));
    }
  }, [apiService, queryFilterDto, queryLocationDto]);

  const fetchMorePlaces = useCallback(() => {
    apiService.post(new ConsumerApi.FindPlaceRequest({
      offset: offsetPlaces,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto,
      filter: queryFilterDto
    })).then(response => {
      setPlaces(places => [...(places || []), ...response.places]);
    });
  }, [apiService, offsetPlaces, queryFilterDto, queryLocationDto]);

  const fetchMoreAdMessages = useCallback(() => {
    apiService.post(new ConsumerApi.FindAdMessageRequest({
      offset: offsetAdMessages,
      size: pageSize,
      resultAt: (new Date()).toUTCString(),
      location: queryLocationDto,
        filter: queryFilterDto
    })).then(response => {
      setAdMessages(adMessages => [...(adMessages || []), ...response.adMessages]);
    });
  }, [apiService, offsetAdMessages, queryFilterDto, queryLocationDto]);

  const navigateToMap = useCallback(() => {
    if (lastLocation && paramLocation && paramTab) {
      navigate(buildUrl("finder-map",
        paramTab,
        paramLocation,
        paramFindMode,
        undefined,
        undefined,
        paramFilter))
    }
  }, [lastLocation, history, paramTab, paramLocation, paramFindMode, paramFilter]);

  const onClickTab = useCallback(() => {
    scrollableAreaRef.current?.scrollTo({top: 0});
  }, [scrollableAreaRef]);

  useEffect(() => {
    setSelectedTab(paramTab as TabType);
  }, [paramTab]);

  useEffect(() => {
    if (offsetPlaces > 0) fetchMorePlaces()
  }, [offsetPlaces, fetchMorePlaces]);

  useEffect(() => {
    if (offsetAdMessages > 0) fetchMoreAdMessages()
  }, [offsetAdMessages, fetchMoreAdMessages]);

  useEffect(() => fetch(), [fetch, queryFilterDto, queryLocationDto]);

  useEffect(() => {
    if (lastLocation) {
      let queryLocationDto = new ConsumerApi.QueryLocationDto({
        latitude: lastLocation.location.lat,
        longitude: lastLocation.location.lng,
        searchRadiusInKm: defaultSearchRadiusInKm,
      });
      let queryFilterDto = new ConsumerApi.QueryFilterDto();
      if (paramFilter) {
        queryFilterDto = copyFiltersUrlToQueryFilterDto(queryFilterDto, paramFilter);
      }
      setQueryLocationDto(queryLocationDto);
      setQueryFilterDto(queryFilterDto);
    }
  }, [paramFilter, lastLocation]);


  useEffect(() => setLoading(isFetching), [isFetching, setLoading])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30">
        <div className="w-full mx-auto max-w-md bg-near-white">
          <Header
            leftIcon={<MenuIcon />}
            onClickLeftIcon={() => setShowSidebar(true)}
            onClickTitle={() => fetch(DateTime.now().toMillis())}
            title={"BROVS"}
            hideBottomBorder={true} />
          {searchTerm ?
            <div className="px-[12px] pt-[15px] pb-20px relative">
              <InputSearch
                value={searchTerm}
                hideClear={true}
                placeholder={t("Search Places, Categories, Brands, etc.")}
                onClick={() => setShowTextSearch(true)} />
              <div
                className="right-[5px] top-[7px] z-40 absolute cursor-pointer"
                onClick={() => navigate(`/finder/${paramTab}/?location=${paramLocation}`)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" rx="8" fill="black"/>
                  <path d="M6.06066 5L5 6.06066L7.12133 8.18199L5.00002 10.3033L6.06068 11.364L8.18199 9.24265L10.3033 11.364L11.364 10.3033L9.24265 8.18199L11.364 6.06066L10.3033 5L8.18199 7.12133L6.06066 5Z" fill="white"/>
                </svg>
              </div>
            </div>
          : null}
          <div className="bg-near-white">
            <FinderLocation
              location={lastLocation}
              showNavigateToMap={true}
              onClickLocation={() => setShowChangeLocation(true)}
              onClickNavigateToMap={() => navigateToMap()} />
          </div>
        </div>
      </div>
      <div
        ref={scrollableAreaRef}
        className="h-full overflow-y-scroll overflow-x-hidden pt-[192px]"
        onScroll={onScroll}>
        <div className="bg-near-white sticky z-20 top-0 pt-[8px]">
          <div className="mt-2.5 h-9 flex">
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.adMessages}
              selected={selectedTab === TabType.adMessages}
              count={adMessageCount} />
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.places}
              selected={selectedTab === TabType.places}
              count={placeCount} />
          </div>
        </div>

        {!places || !adMessages ?
            <div>Loading...</div>
          : paramTab === TabType.places ?
            places?.map((p, i) => <Place key={p.id} place={p} /> )
          :
            adMessages?.map((a, i) => <AdMessage key={a.id} adMessage={a} /> )
        }
      </div>

      <div ref={googleAttribution}></div>

      {showChangeLocation && lastLocation ?
        <ChangeLocation
          onApply={onApplyChangeLocation}
          onBack={() => setShowChangeLocation(false)} />
      : showTextSearch && lastLocation ?
        <TextSearch
          term={searchTerm}
          location={lastLocation.location}
          searchRadiusInKm={defaultSearchRadiusInKm}
          onApply={() => setShowTextSearch(false)}
          onBack={() => setShowTextSearch(false)} />
      : null}
    </>
  )
}