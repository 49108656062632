import React from "react";

type RewardsIconProps = {
  className?: string
}

export const RewardsIcon: React.FC<RewardsIconProps> = (props) => {
  return (
    <svg className={props.className} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.27295 20.5816H16.1417V15.9683H6.27295V20.5816Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9425 15.7691H6.47234H6.07422V16.1672V20.3829V20.78H6.47234H15.9425H16.3406V20.3829V16.1672V15.7691H15.9425ZM6.47234 20.3824H15.9425V16.1672H6.47234V20.3824Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.98267 15.2557H12.4323V8.40196H9.98267V15.2557Z" fill="currentColor"/>
      <path d="M5.94798 2.50252H2.44434V5.73616C2.44434 6.35081 2.61269 8.6564 7.13069 8.6564H15.2842C19.8016 8.6564 19.9705 6.35081 19.9705 5.73616V2.50252H16.4669" stroke="black" strokeWidth="3.677"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.64136 1.54449V7.74814C5.64136 10.8224 8.1333 13.3154 11.2081 13.3154C14.2814 13.3154 16.7733 10.8224 16.7733 7.74814V1.54449H5.64136Z" fill="currentColor"/>
    </svg>
  )
}
