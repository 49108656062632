import { atom, selector } from "recoil";

const localStorageKey = "currentLocationWorked";

const _currentLocationWorkedState = atom<boolean>({
  key: '_currentLocationWorkedState',
  default: localStorage.getItem(localStorageKey) === "true" ?? false
});

export const currentLocationWorkedState = selector<boolean>({
  key: 'currentLocationWorkedState',
  get: ({ get }) => get(_currentLocationWorkedState),
  set: ({ set }, worked) => {
    if (worked) {
      localStorage.setItem(localStorageKey, "true")
    } else {
      localStorage.removeItem(localStorageKey);
    }
    set(_currentLocationWorkedState, worked)
  }
});