import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { homeLocationState } from "../atoms";
import { PlaceLocation } from "../model";
import { useTranslation } from "react-i18next";

// Since i18n can't be not used in Recoil atom states, this Hook returns the Home Location with the
// translated homeLocation.name
export const useHomeLocation = (): PlaceLocation => {

  const { t, i18n } = useTranslation();

  const gl = useRecoilValue(homeLocationState);

  const homeLocation = useMemo(() => {
    return new PlaceLocation({...gl, name: t("Home")});
  }, [i18n.language, gl]);

  return homeLocation;
}