import { ConsumerApi } from '../ConsumerApi.dto';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeparatorHeader } from '../separator-header';

type MyRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse
}

const MyRewardPoints: React.FC<MyRewardPointsProps> = (props) => {

  const { t } = useTranslation();

  return (
    <>
      <SeparatorHeader title={t("My Reward Points")} />
      <div className="flex items-center mt-[15px]">
        {t("When you share and refer you get points. These points can be used to participate in our monthly lotteries.")}
      </div>
      <div className="mt-[30px]">
        <div className="text-body flex justify-center">
          {t("My Reward Points")}
        </div>
        <div className="text-[64px] font-bold flex justify-center">
          {props.rewardsStatus.pointsInTotal}
        </div>
      </div>
      <div className="flex justify-center style-subheading mt-[40px]">
        {t("Number of Contributions")}
      </div>
      <div className="flex mt-[25px]">
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">
            {t("Friend Referrals")}
          </div>
          <div className="text-[24px] font-bold">
            {props.rewardsStatus.referFriend.count}
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">
            {t("Business Referrals")}
          </div>
          <div className="text-[24px] font-bold">
            {props.rewardsStatus.referBusiness.count}
          </div>
        </div>
      </div>
      <div className="flex mt-[25px]">
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body mt-[30px]">
            {t("Shared Places")}
          </div>
          <div className="text-[24px] font-bold">
            {props.rewardsStatus.sharePlace.count}
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body mt-[30px]">
            {t("Shared AD Messages")}
          </div>
          <div className="text-[24px] font-bold">
            {props.rewardsStatus.shareAdMessage.count}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-[25px]">
        <div className="text-body">
          {t("Expiration of Points by 31. December")} {DateTime.now().year}
        </div>
        <div className="text-[24px] font-bold">
          {props.rewardsStatus.pointsExpiringAtEndOfThisYear}
        </div>
      </div>
    </>
  )
}

export default MyRewardPoints;