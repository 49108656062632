import React from "react";

export const FeedIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="6" rx="1" fill="#262525"/>
      <rect y="8.88892" width="16" height="6" rx="1" fill="#262525"/>
    </svg>
  )
}
