import { LatLng } from '../model';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { apiServiceState } from '../atoms';
import { ConsumerApi } from '../ConsumerApi.dto';
import { Filter } from './Filter';
import { buildUrl } from "../helpers/FinderHelper";
import { useDebounce } from '../hooks/UseDebounce';

import { ArrowBackIcon } from '../icons';
import { InputSearch } from '../input-search';
import { useFinderParams } from '../hooks/UseFinderParams';
import { useNavigate } from 'react-router-dom';

type TextSearchProps = {
  term?: string
  onBack: () => void
  onApply: () => void
  location: LatLng
  searchRadiusInKm: number
  callerIsMap?: boolean
}

const TextSearch: React.FC<TextSearchProps> = (props) => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { paramLocation } = useFinderParams();
  const apiService = useRecoilValue(apiServiceState);
  const [searchTerm, setSearchTerm] = useState(props.term);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [searchResult, setSearchResult] = useState<ConsumerApi.FindSearchCompletionEntry[]>();

  useEffect(() => {
    if (debouncedSearchTerm) {
      apiService.post(new ConsumerApi.FindSearchCompletionRequest({
        location: new ConsumerApi.QueryLocationDto({
          longitude: props.location.lng,
          latitude: props.location.lat,
          searchRadiusInKm: props.searchRadiusInKm / 5
        }),
        filter: new ConsumerApi.QueryFilterDto({
          term: debouncedSearchTerm
        })
      })).then(response => {
        setSearchResult(response.suggestions ? response.suggestions : []);
      })
    } else {
      setSearchResult([]);
    }
  }, [apiService, debouncedSearchTerm, props.location.lat, props.location.lng, props.searchRadiusInKm])

  const apply = useCallback((entry: ConsumerApi.FindSearchCompletionEntry) => {
    if (paramLocation) {
      if (entry.brand) {
        const filters = convertDataToFilters(ConsumerApi.FeatureType.SubCategoriesAndBrands, entry.brand.name);
        navigate(buildUrl("finder",
          "places",
          paramLocation,
          entry.brand.categoryAbsoluteSlug,
          undefined,
          undefined,
          filters));
      } else if (entry.category) {
        const categorySlugArray = entry.category.absoluteSlug.split(".");

        let categoryAbsoluteSlug = entry.category.absoluteSlug;
        let filters: {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined} | undefined;

        // In case the category is a Subcategory with 3 levels, then only use the first two levels
        // as a the main category selected, and the rest as SubCategory filter
        if (categorySlugArray.length === 0) {
          throw `The absoluteSlug of the category ${entry.category.name} is empty`;
        } else if (categorySlugArray.length > 2) {
          filters = convertDataToFilters(
            ConsumerApi.FeatureType.SubCategories,
            categoryAbsoluteSlug);

          categorySlugArray.splice(2, categorySlugArray.length - 2).join(".");
          categoryAbsoluteSlug = categorySlugArray.join(".");
        }

        navigate(buildUrl("finder",
          "places",
          paramLocation,
          categoryAbsoluteSlug,
          undefined,
          undefined,
          filters))
      } else if (entry.place) {
        navigate(buildUrl("finder",
          "places",
          paramLocation,
          undefined,
          entry.place.guid))
      }
      // TODO: Disabled code until decided how to handle free text search
      // else if (entry.freeText) {
      //   const filters = convertDataToFilters("Term", entry.freeText.term);

      //   // If the user does not enter anything.
      //   if (!filters || filters.Term === undefined || filters.Term.length === 0 || !filters.Term[0].data || (filters.Term[0].data as string).trim().length === 0) {
      //     navigate(buildUrl("finder",
      //       "places",
      //       paramLocation))
      //   } else {
      //     navigate(buildUrl(props.callerIsMap ? "finder-map" : "finder",
      //       "places",
      //       paramLocation,
      //       "search",
      //       undefined,
      //       undefined,
      //       filters));
      //   }
      // }
      props.onApply();
    }
  }, [history, paramLocation])

  // TODO: Disabled code until decided how to handle free text search
  // const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   apply(new ConsumerApi.FindSearchCompletionEntry({
  //     freeText: new ConsumerApi.FindSearchCompletionFreeText({term: searchTerm})
  //   }));
  // }, [props, searchTerm]);

  // const onContinue = useCallback(() => apply(new ConsumerApi.FindSearchCompletionEntry({
  //   freeText: new ConsumerApi.FindSearchCompletionFreeText({term: searchTerm})
  // })), [searchTerm])

  return (
    <div className="fixed inset-0 z-999999 bg-white overflow-y-auto mx-auto max-w-md">
      <div className="fixed top-0 left-0 right-0 z-30 bg-white">
        <div className="w-full mx-auto max-w-md border-b border-gray-lighther">
          <div className="flex items-center h-[56px] px-[12px] w-full bg-white">
            <div onClick={props.onBack} className="flex-none w-[80px] cursor-pointer">
              <ArrowBackIcon />
            </div>
            <div className="flex-auto text-center style-heading pr-[80px]">
              BROVS {t("Search")}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-14 flex py-3 px-3 relative flex-wrap">
        <div className="w-full mb-[10px]">
          <InputSearch
            autoFocus={true}
            value={searchTerm || ""}
            placeholder={t("Search Places, Categories, Brands, etc.")}
            onChange={(value) => setSearchTerm(value)}
            onClear={() => setSearchTerm("") }
          />
        </div>
        {searchResult?.map((r, i) =>
          <div
            key={i}
            onClick={() => apply(r)}
            className="text-body py-[10px] px-[10px] flex items-center w-full cursor-pointer">
            <span className="text-gray">{
              r.brand ? r.brand.name
              : r.category ? r.category.name
              : r.place ? r.place.name
              : r.freeText?.term
            }</span>
          </div>
        )}
      </div>
    </div>
  )
}

export function convertDataToFilters(
  type: ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term", data?: string):
  {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined} {

  const filter = [new Filter({data: data, label: "", featureType: type, selected: true})]

  const filters: {[key in ConsumerApi.FeatureType | "Information" | "OpeningHours" | "Term"]: Filter[] | undefined} = {
    [ConsumerApi.FeatureType.BarSpeciality]: type === ConsumerApi.FeatureType.BarSpeciality ? filter : undefined,
    [ConsumerApi.FeatureType.BarType]: type === ConsumerApi.FeatureType.BarType ? filter : undefined,
    [ConsumerApi.FeatureType.Cuisine]: type === ConsumerApi.FeatureType.Cuisine ? filter : undefined,
    [ConsumerApi.FeatureType.SaveFoodAndMoney]: type === ConsumerApi.FeatureType.SaveFoodAndMoney ? filter : undefined,
    [ConsumerApi.FeatureType.MealType]: type === ConsumerApi.FeatureType.MealType ? filter : undefined,
    [ConsumerApi.FeatureType.Menu]: type === ConsumerApi.FeatureType.Menu ? filter : undefined,
    [ConsumerApi.FeatureType.OutdoorSeating]: type === ConsumerApi.FeatureType.OutdoorSeating ? filter : undefined,
    [ConsumerApi.FeatureType.Parking]: type === ConsumerApi.FeatureType.Parking ? filter : undefined,
    [ConsumerApi.FeatureType.PaymentOptions]: type === ConsumerApi.FeatureType.PaymentOptions ? filter : undefined,
    [ConsumerApi.FeatureType.PriceMainDish]: type === ConsumerApi.FeatureType.PriceMainDish ? filter : undefined,
    [ConsumerApi.FeatureType.SubCategories]: type === ConsumerApi.FeatureType.SubCategories ? filter : undefined,
    [ConsumerApi.FeatureType.SubCategoriesAndBrands]: type === ConsumerApi.FeatureType.SubCategoriesAndBrands ? filter : undefined,
    [ConsumerApi.FeatureType.EatingSuitabilities]: type === ConsumerApi.FeatureType.EatingSuitabilities ? filter : undefined,
    [ConsumerApi.FeatureType.TakeAway]: type === ConsumerApi.FeatureType.TakeAway ? filter : undefined,
    [ConsumerApi.FeatureType.WearPriceProfile]: type === ConsumerApi.FeatureType.WearPriceProfile ? filter : undefined,
    [ConsumerApi.FeatureType.WheelChairAccessible]: type === ConsumerApi.FeatureType.WheelChairAccessible ? filter : undefined,
    [ConsumerApi.FeatureType.Wifi]: type === ConsumerApi.FeatureType.Wifi ? filter : undefined,
    [ConsumerApi.FeatureType.WebShop]: type === ConsumerApi.FeatureType.WebShop ? filter : undefined,
    [ConsumerApi.FeatureType.Filtering]: type === ConsumerApi.FeatureType.Filtering ? filter : undefined,
    Information: undefined,
    OpeningHours: undefined,
    Term: type === "Term" ? filter : undefined
  }

  return filters;
}

export default TextSearch;