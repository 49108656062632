import { ConsumerApi } from "../ConsumerApi.dto";
import { DateTime } from "luxon";

export const toTitleCase = (title: string) => {
  var sentence = title.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

export const localeConstantToJS = (locale: ConsumerApi.Locale): string => {
  switch (locale) {
    case ConsumerApi.Locale.EnUs:
      return "en-US";
    case ConsumerApi.Locale.NbNo:
      return "nb-NO";
    default:
      throw `The locale "${locale}" is not supported`;
  }
}

export const formatNumberWithSuffix = (num: number, locale: ConsumerApi.Locale): string => {
  const formatter = new Intl.NumberFormat(localeConstantToJS(locale), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
  });

  if (num < 1000) {
      return formatter.format(num);
  }
  else if (num < 1000000) {
      return formatter.format(num / 1000) + 'K';
  }
  else {
      return formatter.format(num / 1000000) + 'M';
  }
}

export const ISODateTimeToLocalDateTime = (dateIso: string, locale: ConsumerApi.Locale): string => {
  switch (locale) {
    case ConsumerApi.Locale.EnUs:
      return DateTime.fromISO(dateIso).toFormat("MMM d h:mm a");
    case ConsumerApi.Locale.NbNo:
      return DateTime.fromISO(dateIso).toFormat("d MMM HH:mm");
    default:
      throw `The locale "${locale}" is not supported`;
  }
}

export const ISOTimeToLocalTime = (timeISO: string, locale: ConsumerApi.Locale): string => {
  switch (locale) {
    case ConsumerApi.Locale.EnUs:
      return DateTime.fromISO(timeISO).toFormat("h:mm a");
    case ConsumerApi.Locale.NbNo:
      return DateTime.fromISO(timeISO).toFormat("HH:mm");
    default:
      throw `The locale "${locale}" is not supported`;
  }
}