import { useCallback, useRef, useState } from "react";
import { TabType } from "../finder-tab";
import { initialPageSize, pageSize } from "../Constants";

export const useFinderScroll = (selectedTab: TabType | undefined, target: React.RefObject<HTMLDivElement>) => {

  const [offsetPlaces, setOffsetPlaces] = useState(0);
  const [offsetAdMessages, setOffsetAdMessages] = useState(0);

  // These values save the start scroll position of the last page fetched
  const topPlaces = useRef(0);
  const topAdMessages = useRef(0);

  const heightPlaces = useRef(0);
  const heightAdMessages = useRef(0);

  const resetScroll = useCallback(() => {
    setOffsetPlaces(0);
    setOffsetAdMessages(0);
    topPlaces.current = 0;
    heightPlaces.current = 0;
    topAdMessages.current  = 0;
    heightAdMessages.current = 0;
  }, [topPlaces, heightPlaces, topAdMessages, heightAdMessages]);

  const onScroll = useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {

    if (target.current && selectedTab) {
      if (selectedTab == TabType.places) {
        heightPlaces.current = target.current.scrollHeight - target.current.offsetHeight;
      } else {
        heightAdMessages.current = target.current.scrollHeight - target.current.offsetHeight;
      }

      const top = selectedTab === TabType.places ? topPlaces.current : topAdMessages.current;
      const height = selectedTab === TabType.places ? heightPlaces.current : heightAdMessages.current;

      // height - top is the scroll area of the last page fetched
      // 0.5 represent the half of the scroll area
      if (target.current.scrollTop > top && height - top !== 0 && (target.current.scrollTop - top) / (height - top) > 0.5) {
        if (selectedTab === TabType.places) {
          topPlaces.current = heightPlaces.current;
          setOffsetPlaces(offsetPlaces === 0 ? initialPageSize : offsetPlaces + pageSize);
        } else {
          topAdMessages.current = heightAdMessages.current;
          setOffsetAdMessages(offsetAdMessages === 0 ? initialPageSize : offsetAdMessages + pageSize);
        }
      }
    }

  }, [offsetAdMessages, offsetPlaces, selectedTab, target, topPlaces, heightPlaces, topAdMessages, heightAdMessages]);

  return {
    offsetPlaces,
    offsetAdMessages,
    onScroll,
    resetScroll
  };
}